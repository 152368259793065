import React from 'react';

function UpShowConnectLogo({ className = '', fill = '#FFFFFF' }) {
    return (
        <svg width="140" className={className} height="48" viewBox="0 0 140 48" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M39.7091 22.3312C39.4604 21.7316 39.1743 21.5885 38.3907 21.5885H25.3974C23.7928 21.5885 22.7572 20.9889 22.2223 19.7489L21.2582 17.5549C20.7949 16.492 19.9398 15.9981 18.7236 15.9981H8.9769L7.90719 13.5929H18.543C20.5769 13.5929 21.575 14.1584 22.2564 15.6472L23.1489 17.6265C23.6838 18.7916 24.3277 19.1834 25.6836 19.1834H37.321C37.8933 19.1834 38.142 18.7575 37.8933 18.2261L36.1797 14.3322C35.931 13.7666 35.6108 13.5895 34.8238 13.5895H22.58C20.9754 13.5895 19.9398 12.9899 19.4049 11.7498L18.4408 9.55591C17.9775 8.49641 17.119 7.99903 15.9062 7.99903H5.48159L4.40846 5.59387H15.7222C17.7561 5.59387 18.7542 6.16279 19.4356 7.64813L20.3282 9.62745C20.863 10.7926 21.5069 11.1843 22.8628 11.1843H33.8223C34.3571 11.1843 34.6092 10.7585 34.3571 10.227L32.6435 6.33313C32.3948 5.73354 32.1087 5.59046 31.3251 5.59046H19.7592C18.1547 5.59046 17.119 4.99087 16.5842 3.75082L15.62 1.55688C15.1567 0.493977 14.3016 0 13.0854 0H0.582695C0.081904 0.00681348 -0.129314 0.395182 0.081904 0.926633L13.4704 31.417C13.685 31.9416 13.9712 32.1562 14.4345 32.1562C14.8978 32.1562 15.2215 31.945 15.4327 31.4136L18.1104 25.295C18.4681 24.4808 18.1819 23.9869 17.3268 23.9869H13.5419C12.6834 23.9869 12.0804 23.561 12.0804 22.7843C12.0804 22.0075 12.6868 21.5817 13.5419 21.5817H21.3604C23.3942 21.5817 24.3924 22.1472 25.0738 23.636L25.9663 25.6153C26.5012 26.7804 27.1451 27.1722 28.5009 27.1722H40.8878C41.4227 27.1722 41.6407 26.7463 41.4227 26.2523L39.7091 22.3244V22.3312Z" fill={fill} />
            <path d="M55.0497 11.1128C51.4215 11.1128 49.248 13.3953 49.248 17.2517H49.2549C49.2549 20.948 51.459 23.1658 55.0565 23.1658C57.615 23.1658 59.3013 22.137 60.0133 20.1441C60.2416 19.5649 60.1087 19.3401 59.5262 19.3401H58.1976C57.8058 19.3401 57.615 19.4695 57.4208 19.7897C56.903 20.6244 56.283 20.914 55.0872 20.914C53.0432 20.914 52.1063 19.9805 51.9087 17.7627H59.8158C60.2995 17.7627 60.463 17.6367 60.463 17.2483C60.463 13.2624 58.6779 11.1094 55.0497 11.1094V11.1128ZM52.0348 15.8379C52.1983 14.2299 53.2646 13.3306 55.0497 13.3306C56.702 13.3306 57.707 14.2606 57.707 15.8379H52.0348Z" fill={fill} />
            <path d="M72.3628 11.2766H70.8707C70.4789 11.2766 70.2881 11.4061 70.1246 11.7604L67.0142 18.2877C66.9154 18.5125 66.6565 18.5125 66.5611 18.2877L63.4167 11.7604C63.2532 11.4061 63.0624 11.2766 62.6706 11.2766H61.1478C60.5312 11.2766 60.4017 11.4708 60.6947 12.0499L66.0126 22.6585C66.1762 23.0128 66.4351 23.1729 66.7587 23.1729C67.0824 23.1729 67.3413 23.0128 67.5048 22.6585L72.8227 12.0499C73.1157 11.4708 72.9828 11.2766 72.3696 11.2766H72.3628Z" fill={fill} />
            <path d="M135.04 23.1658C138.181 23.1658 139.997 21.752 139.997 19.3401C139.997 17.3812 139.251 16.6726 136.593 16.1241L134.031 15.6097C132.962 15.3848 132.669 15.1259 132.669 14.4548C132.669 13.6167 133.347 13.2318 134.839 13.2318C135.977 13.2318 136.818 13.6167 137.142 14.3253C137.336 14.741 137.499 14.8704 137.888 14.8704H139.216C139.799 14.8704 139.959 14.7069 139.833 14.1925C139.281 12.1689 137.629 11.1094 135.036 11.1094C131.96 11.1094 130.141 12.3971 130.141 14.5808C130.141 16.4784 130.986 17.2824 133.514 17.7968L136.075 18.3112C137.145 18.5361 137.469 18.8256 137.469 19.6296C137.469 20.5597 136.723 21.0128 135.166 21.0128C133.837 21.0128 132.931 20.5631 132.703 19.7591C132.573 19.3401 132.383 19.214 131.991 19.214H130.662C130.08 19.214 129.95 19.4082 130.08 20.0487C130.468 22.0416 132.25 23.1692 135.036 23.1692L135.04 23.1658Z" fill={fill} />
            <path d="M78.6445 11.1128C75.0163 11.1128 72.8428 13.3953 72.8428 17.2517H72.8496C72.8496 20.948 75.0537 23.1658 78.6513 23.1658C81.2097 23.1658 82.8995 22.137 83.6081 20.1441C83.8363 19.5649 83.7035 19.3401 83.1209 19.3401H81.7923C81.4039 19.3401 81.2097 19.4695 81.0155 19.7897C80.4977 20.6244 79.8777 20.914 78.6819 20.914C76.6379 20.914 75.701 19.9805 75.5034 17.7627H83.4105C83.8942 17.7627 84.0578 17.6367 84.0578 17.2483C84.0578 13.2624 82.2726 11.1094 78.6445 11.1094V11.1128ZM75.6295 15.8379C75.793 14.2299 76.8593 13.3306 78.6445 13.3306C80.2967 13.3306 81.3017 14.2606 81.3017 15.8379H75.6295Z" fill={fill} />
            <path d="M114.62 21.5647C114.715 21.4046 114.879 21.4046 114.944 21.5647L115.336 22.4334C115.564 22.9172 115.659 23.0126 116.051 23.0126H116.991C117.475 23.0126 117.638 22.8524 117.638 22.3687V15.746C117.638 12.5982 116.054 11.1196 112.648 11.1196C109.762 11.1196 107.916 12.2745 107.527 14.3663C107.428 14.9113 107.558 15.0749 108.11 15.0749H109.633C110.024 15.0749 110.249 14.9147 110.379 14.5298C110.638 13.7564 111.448 13.3068 112.648 13.3068C114.334 13.3068 114.981 13.8518 114.981 15.2656C114.981 15.7494 114.658 16.039 114.106 16.039H111.643C108.628 16.039 107.204 17.1938 107.204 19.6365V19.6433C107.204 21.9224 108.566 23.1454 111.06 23.1454C112.842 23.1454 113.942 22.6617 114.623 21.5715L114.62 21.5647ZM109.919 19.4696C109.919 18.4101 110.437 17.9263 111.636 17.9263H114.974V18.4714C114.974 20.0794 113.874 21.0128 111.959 21.0128C110.498 21.0128 109.915 20.5631 109.915 19.4696H109.919Z" fill={fill} />
            <path d="M125.607 16.1275L123.045 15.6131C121.975 15.3883 121.682 15.1293 121.682 14.4582C121.682 13.6202 122.36 13.2352 123.852 13.2352C124.987 13.2352 125.828 13.6202 126.152 14.3288C126.346 14.7444 126.51 14.8738 126.898 14.8738H128.227C128.809 14.8738 128.969 14.7103 128.843 14.1959C128.291 12.1723 126.639 11.1128 124.047 11.1128C120.97 11.1128 119.151 12.4005 119.151 14.5843C119.151 16.4818 119.996 17.2858 122.524 17.8002L125.086 18.3146C126.155 18.5395 126.479 18.8291 126.479 19.6331C126.479 20.5631 125.736 21.0162 124.179 21.0162C122.851 21.0162 121.945 20.5665 121.716 19.7625C121.584 19.3435 121.393 19.2174 121.001 19.2174H119.672C119.09 19.2174 118.96 19.4116 119.09 20.0521C119.478 22.045 121.26 23.1727 124.047 23.1727C127.188 23.1727 129.003 21.7589 129.003 19.3469C129.003 17.388 128.257 16.6794 125.6 16.1309L125.607 16.1275Z" fill={fill} />
            <path d="M100.942 11.1162C99.4187 11.1162 98.0253 11.7567 97.4394 12.7242C97.344 12.8877 97.1805 12.8877 97.1157 12.7242L96.724 11.8555C96.4957 11.3717 96.4003 11.2763 96.0086 11.2763H95.1024C94.6186 11.2763 94.4551 11.4364 94.4551 11.9168V26.5419C94.4551 27.0257 94.7787 27.1858 95.2659 27.1858H96.6592C97.1464 27.1858 97.1464 27.0257 97.1464 26.5419V21.8815C97.1464 21.6226 97.3065 21.5919 97.5007 21.8168C98.2161 22.6514 99.5107 23.1658 100.904 23.1658C104.144 23.1658 106.058 20.914 106.058 17.1223C106.058 13.3306 104.178 11.1128 100.938 11.1128L100.942 11.1162ZM100.229 20.9174C98.2195 20.9174 97.1498 19.599 97.1498 17.1257C97.1498 14.6524 98.1889 13.3646 100.229 13.3646C102.27 13.3646 103.34 14.6524 103.34 17.1257C103.34 19.599 102.239 20.9174 100.229 20.9174Z" fill={fill} />
            <path d="M86.5242 23.0026H87.9176C88.4013 23.0026 88.5649 22.8425 88.5649 22.3621V15.3885C88.5649 14.1655 89.018 13.7499 90.2512 13.7499H92.36C92.8437 13.7499 93.0073 13.5898 93.0073 13.1094V11.9205C93.0073 11.4401 92.8471 11.28 92.36 11.28H91.355C90.2546 11.28 89.7334 11.5662 88.9226 12.6904C88.7931 12.8505 88.6296 12.8846 88.5683 12.7211L88.1765 11.8523C87.9482 11.3686 87.8529 11.2732 87.4611 11.2732H86.5208C86.0371 11.2732 85.8735 11.4333 85.8735 11.9137V22.3621C85.8735 22.8425 86.0336 23.0026 86.5208 23.0026H86.5242Z" fill={fill} />
            <path d="M104.954 37.2908C103.974 37.2908 103.129 37.6571 102.447 38.384C101.771 39.1053 101.427 39.9788 101.427 40.9818C101.427 41.9849 101.771 42.8584 102.447 43.5797C103.129 44.3066 103.98 44.6729 104.971 44.6729C105.439 44.6729 105.907 44.5715 106.335 44.3799C106.758 44.1939 107.135 43.9178 107.445 43.5741C108.133 42.8527 108.482 41.9793 108.482 40.9931C108.482 40.007 108.138 39.1279 107.462 38.4009C106.78 37.6627 105.935 37.2908 104.954 37.2908ZM106.189 42.3456C105.845 42.7288 105.445 42.9091 104.96 42.9091C104.475 42.9091 104.07 42.7288 103.72 42.3512C103.365 41.968 103.191 41.5228 103.191 40.9818C103.191 40.4409 103.36 39.9957 103.715 39.6125C104.058 39.2349 104.464 39.0546 104.954 39.0546C105.445 39.0546 105.85 39.2406 106.189 39.6181C106.887 40.3958 106.882 41.5679 106.189 42.3456Z" fill={fill} />
            <path d="M98.8229 37.7306C98.6595 37.894 98.5693 38.1138 98.5693 38.3448V40.2157H96.8731V38.3448C96.8731 38.1138 96.783 37.8884 96.6139 37.7306C96.2758 37.4037 95.7179 37.4037 95.3854 37.7306C95.222 37.894 95.1318 38.1138 95.1318 38.3448V43.8166C95.1318 44.0477 95.222 44.2675 95.3854 44.4309C95.7123 44.7577 96.2701 44.7577 96.6083 44.4309C96.7773 44.2731 96.8731 44.0477 96.8675 43.8166V41.9457H98.5637V43.8166C98.5637 44.29 98.9469 44.6788 99.4202 44.6788C99.6513 44.6788 99.8711 44.5943 100.034 44.4309C100.204 44.2731 100.299 44.0477 100.294 43.811V38.3392C100.294 38.1082 100.204 37.8827 100.034 37.725C99.702 37.3981 99.1385 37.4037 98.806 37.725L98.8229 37.7306Z" fill={fill} />
            <path d="M79.5226 44.7122H79.5338C79.7649 44.7122 79.9847 44.622 80.1481 44.4642C80.3171 44.3008 80.4073 44.081 80.4073 43.8444V42.1876H81.1962C81.8217 42.1989 82.4191 41.9509 82.8586 41.5058C83.3151 41.0549 83.5461 40.497 83.5461 39.8546C83.5461 39.2122 83.3151 38.6543 82.8586 38.1979C82.4247 37.7527 81.8217 37.4991 81.1962 37.5104H79.5338C79.0605 37.5104 78.6717 37.8936 78.666 38.3669V43.8444C78.666 44.3177 79.0436 44.7066 79.5226 44.7122ZM80.4017 39.2517H81.1906C81.3484 39.2517 81.5062 39.308 81.6189 39.4263C81.7372 39.5391 81.7992 39.6912 81.8048 39.8546C81.8048 40.018 81.7372 40.1702 81.6189 40.2773C81.5062 40.3956 81.354 40.4576 81.1906 40.452H80.4017V39.2517Z" fill={fill} />
            <path d="M92.6927 40.5534C92.332 40.39 91.8756 40.2491 91.3121 40.1364C90.4217 39.9673 90.2245 39.9166 90.2245 39.556C90.2245 39.077 90.8838 38.8628 91.7178 39.139C91.8981 39.2009 92.1855 39.3756 92.4617 39.5616C92.8448 39.8152 93.3576 39.7138 93.6169 39.3306C93.7408 39.1446 93.7972 38.9192 93.7352 38.705C93.6338 38.3613 92.9575 37.9048 92.811 37.8203C92.6476 37.7245 92.4109 37.6287 92.1123 37.5329C91.0754 37.1948 90.0892 37.4033 89.3172 37.9668C88.748 38.3838 88.4606 38.9248 88.4606 39.5785C88.4606 39.9335 88.5677 40.2773 88.7593 40.5759C88.9565 40.8971 89.2721 41.1564 89.6835 41.3536C90.061 41.5339 90.5119 41.6748 91.0416 41.7706C91.8812 41.9284 92.2024 42.058 92.1968 42.4243C92.1855 42.954 91.5656 43.0893 91.1599 43.0893C90.664 43.0893 90.1512 42.8751 89.644 42.4637C89.4975 42.3341 89.3115 42.2665 89.1199 42.2665H89.0185C88.7987 42.2947 88.6015 42.4018 88.4606 42.5708C88.3198 42.7455 88.2578 42.9653 88.2859 43.1851C88.3085 43.4048 88.4155 43.6077 88.5902 43.7486C89.4242 44.4079 90.2977 44.746 91.1824 44.7516C91.9207 44.7516 92.58 44.5488 93.1435 44.1374C93.7465 43.7035 94.0508 43.1287 94.0508 42.4356C94.0508 41.6297 93.6 40.9986 92.6927 40.5534Z" fill={fill} />
            <path d="M74.1585 44.7122C74.9925 44.7122 75.7194 44.4079 76.3055 43.8162C76.8972 43.2245 77.1959 42.5032 77.1959 41.6748V38.3726C77.1959 38.1415 77.1057 37.9217 76.9423 37.7583C76.6155 37.4315 76.0463 37.4315 75.7194 37.7583C75.5504 37.9161 75.4546 38.1415 75.4546 38.3782V41.6804C75.4602 42.0242 75.325 42.3567 75.077 42.599C74.5755 43.1061 73.764 43.1118 73.2569 42.6102C73.2569 42.6102 73.2456 42.599 73.2399 42.5933C72.992 42.3567 72.8567 42.0242 72.8624 41.6804V38.3782C72.8624 38.1471 72.7722 37.9217 72.6032 37.764C72.2707 37.4371 71.7015 37.4427 71.3747 37.764C71.2113 37.9274 71.1211 38.1471 71.1211 38.3782V41.6804C71.1211 42.5145 71.4198 43.2414 72.0115 43.8275C72.5975 44.4192 73.3245 44.7178 74.1585 44.7178V44.7122Z" fill={fill} />
            <path d="M86.0536 34H68.3533C67.8123 34 67.3784 34.4339 67.3784 34.9749V47.0061C67.3784 47.5471 67.8123 47.981 68.3533 47.981H86.0536C86.5945 47.981 87.0285 47.5415 87.0285 47.0061V34.9749C87.0285 34.4339 86.5945 34 86.0536 34ZM85.0787 46.0312H69.3338V35.9498H85.0787V46.0312Z" fill={fill} />
            <path d="M118.023 37.6064C117.921 37.567 117.809 37.5445 117.696 37.5445C117.583 37.5445 117.476 37.567 117.369 37.6064C117.155 37.691 116.986 37.86 116.896 38.0685L115.414 41.5849L114.54 39.5111C114.388 39.173 114.101 38.9871 113.729 38.9871C113.357 38.9871 113.064 39.1787 112.923 39.5168L112.049 41.5905L110.567 38.0854C110.488 37.8657 110.314 37.691 110.094 37.6064C109.874 37.5163 109.626 37.5275 109.451 37.6064C109.232 37.6853 109.063 37.8544 108.978 38.0742C108.894 38.2883 108.888 38.525 108.978 38.7335L111.283 44.1771C111.283 44.1771 111.497 44.6617 111.925 44.6561C111.925 44.6561 112.523 44.7575 112.85 44.132L113.74 42.0301L114.608 44.0757C114.608 44.0757 114.867 44.6787 115.419 44.6617C115.769 44.6617 116.084 44.4532 116.214 44.132L118.485 38.7391C118.485 38.7391 118.485 38.7278 118.49 38.7222C118.671 38.2827 118.462 37.7811 118.023 37.6008V37.6064Z" fill={fill} />
            <path d="M29.7357 39.2688C29.2341 39.2688 28.834 39.5111 28.6199 39.8605V39.3364H28V44.1714H28.6199V42.4133C28.834 42.7683 29.2341 43.0106 29.7357 43.0106C30.7331 43.0106 31.3022 42.1597 31.3022 41.1397C31.3022 40.1197 30.7331 39.2688 29.7357 39.2688ZM29.6229 42.464C29.1158 42.464 28.603 42.092 28.603 41.2693V41.0157C28.603 40.1761 29.1101 39.8098 29.6229 39.8098C30.2992 39.8098 30.6767 40.362 30.6767 41.1341C30.6767 41.9061 30.2992 42.4583 29.6229 42.4583V42.464Z" fill={fill} />
            <path d="M33.5101 39.2688C32.5296 39.2688 31.8364 40.0239 31.8364 41.1397C31.8364 42.2555 32.5239 43.0106 33.5101 43.0106C34.4963 43.0106 35.1781 42.2555 35.1781 41.1397C35.1781 40.0239 34.4906 39.2688 33.5101 39.2688ZM33.5101 42.4752C32.8789 42.4752 32.4676 41.9512 32.4676 41.1341C32.4676 40.317 32.8789 39.7872 33.5101 39.7872C34.1412 39.7872 34.5526 40.3113 34.5526 41.1341C34.5526 41.9568 34.1412 42.4752 33.5101 42.4752Z" fill={fill} />
            <path d="M38.87 42.0188L38.1318 39.3364H37.6077L36.8639 42.0132L36.1087 39.3364H35.4663L36.5595 42.9317H37.1062L37.8556 40.2662L38.6051 42.9317H39.1461L40.2393 39.3364H39.6195L38.87 42.0188Z" fill={fill} />
            <path d="M42.1836 39.2688C41.2087 39.2688 40.5269 40.069 40.5269 41.1397C40.5269 42.2104 41.2143 43.0106 42.2118 43.0106C42.9275 43.0106 43.4684 42.6161 43.6882 41.9793L43.1472 41.7877C43.0345 42.216 42.6908 42.4752 42.2118 42.4752C41.5863 42.4752 41.1524 41.9962 41.1185 41.2355H43.7164V41.027C43.7164 40.0296 43.1867 39.2688 42.1836 39.2688ZM41.1524 40.7565C41.2651 40.1423 41.6539 39.7929 42.178 39.7929C42.8035 39.7929 43.0796 40.2493 43.0909 40.7565H41.1524Z" fill={fill} />
            <path d="M45.1077 39.9454V39.3311H44.4878V42.9264H45.1077V40.9597C45.1077 40.3286 45.5247 39.9172 46.0487 39.9172C46.1671 39.9172 46.246 39.9228 46.3531 39.9454V39.3199C46.2911 39.3142 46.2009 39.3086 46.1051 39.3086C45.6148 39.3086 45.2654 39.5847 45.1077 39.9454Z" fill={fill} />
            <path d="M48.2979 39.2688C47.323 39.2688 46.6411 40.069 46.6411 41.1397C46.6411 42.2104 47.3286 43.0106 48.3261 43.0106C49.0417 43.0106 49.5827 42.6161 49.8025 41.9793L49.2615 41.7877C49.1488 42.216 48.8051 42.4752 48.3261 42.4752C47.7005 42.4752 47.2666 41.9962 47.2328 41.2355H49.8307V41.027C49.8307 40.0296 49.3009 39.2688 48.2979 39.2688ZM47.2666 40.7565C47.3793 40.1423 47.7682 39.7929 48.2922 39.7929C48.9178 39.7929 49.1939 40.2493 49.2052 40.7565H47.2666Z" fill={fill} />
            <path d="M53.0369 39.8606C52.8227 39.5112 52.4226 39.2689 51.9211 39.2689C50.9236 39.2689 50.3545 40.1142 50.3545 41.1398C50.3545 42.1654 50.9236 43.0107 51.9211 43.0107C52.4226 43.0107 52.8227 42.7684 53.0369 42.4134V42.9374H53.6567V38.001H53.0369V39.8662V39.8606ZM53.0481 41.2694C53.0481 42.0922 52.541 42.4641 52.0282 42.4641C51.3519 42.4641 50.9744 41.9062 50.9744 41.1398C50.9744 40.3734 51.3519 39.8155 52.0282 39.8155C52.5353 39.8155 53.0481 40.1818 53.0481 41.0215V41.275V41.2694Z" fill={fill} />
            <path d="M57.8602 39.2689C57.3586 39.2689 56.9585 39.5112 56.7444 39.8606V37.9954H56.1245V42.9318H56.7444V42.4077C56.9585 42.7628 57.3586 43.0051 57.8602 43.0051C58.8576 43.0051 59.4267 42.1542 59.4267 41.1342C59.4267 40.1142 58.8576 39.2633 57.8602 39.2633V39.2689ZM57.7531 42.4641C57.2459 42.4641 56.7331 42.0922 56.7331 41.2694V41.0158C56.7331 40.1762 57.2403 39.8099 57.7531 39.8099C58.4293 39.8099 58.8069 40.3622 58.8069 41.1342C58.8069 41.9062 58.4293 42.4585 57.7531 42.4585V42.4641Z" fill={fill} />
            <path d="M61.3714 42.0864L60.3627 39.3364H59.709L61.0445 42.8021L60.8698 43.2304C60.7233 43.5854 60.5824 43.6868 60.312 43.6868C60.2105 43.6868 60.1316 43.6812 60.0189 43.653V44.1884C60.1316 44.2109 60.2049 44.2165 60.357 44.2165C60.9826 44.2165 61.2643 43.8728 61.4728 43.3318L63.0225 39.3421H62.3745L61.377 42.0921L61.3714 42.0864Z" fill={fill} />
        </svg>
    );
}

export default UpShowConnectLogo;