import { graphqlQuery } from "../axios";

export function getUpshowNowChannels() {
    return graphqlQuery({
        query: `query ecp_getUpshowNowChannels{
                upshownow_channels(sorted_by: POPULARITY) {
                    id
                    title
                    feedType
                    thumbnail
                    metadata_json
                    preview_asset_url
                    category
                    featured
                    subcategory
                    location {
                        city
                    }
                }
            }`, variables: {}
    }).then(response => response?.data?.data?.upshownow_channels ?? []);
}