import React from "react";
import PropTypes from "prop-types";
import { gsap } from '@upshow/gsap';
import CloseIcon from "@mui/icons-material/ClearOutlined";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import "./MessageActionBar.scss";

class MessageActionBar extends React.Component {
    collapseBody(onClose) {
        gsap.to(this.containerRef, { duration: 0, display: "none" });
        if (onClose) onClose();
    }

    render() {
        const { titleText, titleIcon, description, onClose, withoutButton, buttonText, buttonAction, buttonDisable, variant, hideClose } = this.props;

        return (
            <div className="MessageActionBar" ref={(el) => (this.bodyRef = el)}>
                <div className={`container ${variant ?? ""}`} ref={(el) => (this.containerRef = el)}>
                    <div className="content">
                        <div className="content-icon">{titleIcon}</div>
                        <div className="title-container">
                            <div className="title-text">{titleText}</div>
                            <div className="description">{description}</div>
                        </div>
                    </div>

                    {!withoutButton && (
                        <Button className={buttonDisable ? "button-disable" : "button"} color="primary" disabled={buttonDisable} variant="contained" onClick={() => buttonAction()}>
                            {buttonText}
                        </Button>
                    )}

                    {!hideClose && (
                        <div className={`close ${withoutButton ? "withoutButton" : ""}`}>
                            <IconButton aria-label="Close" onClick={() => this.collapseBody(onClose)}>
                                <CloseIcon classes={{ root: "close-color" }} />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

MessageActionBar.propTypes = {
    titleText: PropTypes.node.isRequired,
    titleIcon: PropTypes.node,
    description: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    hideClose: PropTypes.bool,
    buttonText: PropTypes.node,
    buttonAction: PropTypes.func,
    withoutButton: PropTypes.bool,
    buttonDisable: PropTypes.bool,
    variant: PropTypes.string
};

export default MessageActionBar;
