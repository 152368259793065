import Entity from "../Entity";
import Organization from "../organizations/Organization";

import { getAppliedSchedulesWD } from "../../../../helpers/sliceSchedules";


class Schedule extends Entity {

    constructor() {
        super('schedules', {
            organization: Organization,
        });
    }


    reducer(state = this.initialState, action) {
        return super.reducer(state, action);
    }

    getActiveSchedulesByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getSchedulesIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getActiveSchedulesByOrganizationAndSpotlight(state, organizationId, spotlightId, denormalized) {
        const activeSchedules = this.getActiveSchedulesByOrganization(state, organizationId, denormalized);
        const spotlightSchedules = activeSchedules.filter(s => s.org_spotlight_id === spotlightId);
        return getAppliedSchedulesWD(spotlightSchedules);
        
        // const allMySchedules = schedules.filter(s => s.org_spotlight_id === spotlightId && s.organization_id === organizationId);
        // const myNegativeSchedules = getOnlyNegative(allMySchedules);
        // if(myNegativeSchedules.length > 0){
        //     return activeSchedules.filter(s => s.org_spotlight_id === spotlightId && s.organization_id === organizationId);
        // }
        // return activeSchedules.filter(s => s.org_spotlight_id === spotlightId);
    }

}

export default new Schedule();