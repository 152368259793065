import { graphqlQuery } from "../axios";
import _get from "lodash/get";

export async function getTriviaSchedules(organizationId) {
  const response = await graphqlQuery({
    query: `query ecp_getTriviaSchedules($org_id: Int!){
          Organization(id: $org_id) {
            TriviaSchedules {
                  id
                  mondays
                  tuesdays
                  wednesdays
                  thursdays
                  fridays
                  saturdays
                  sundays
                  validFrom:valid_from
                  validThrough:valid_through
                  validFromTime:valid_from_time
                  validThroughTime:valid_through_time
                  behavior
                  tz
                  organization: public_organization {
                    id
                    name
                  }
                }
            }
          }`, variables: { org_id: organizationId }
  });

  const schedules = _get(response, 'data.data.Organization.TriviaSchedules', []);
  return schedules.map( sch => { sch.type = 'regular'; return sch});
}

export function createTriviaSchedule(organizationId, schedule) {
  const payload = {
    mondays: schedule.mondays,
    tuesdays: schedule.tuesdays,
    wednesdays: schedule.wednesdays,
    thursdays: schedule.thursdays,
    fridays: schedule.fridays,
    saturdays: schedule.saturdays,
    sundays: schedule.sundays,
    validFrom: schedule.validFrom,
    validThrough: schedule.validThrough,
    validFromTime: schedule.validFromTime,
    validThroughTime: schedule.validThroughTime,
    type: schedule.type,
    behavior: schedule.behavior,
  };

  return graphqlQuery({
    query: `mutation createTriviaSchedule($org_id: Int!, $input: ScheduleInput!) {
              orgMutation (organization_id: $org_id){
                create_trivia_schedule(input: $input) {
                  successful
                  message
                }
              }
    }`, variables: { org_id: organizationId, input: payload }
  }).then(response => _get(response, 'data.data.orgMutation.create_trivia_schedule', {}));
}


export function deleteTriviaSchedule(organizationId, scheduleId) {
  return graphqlQuery({
    query: `mutation deleteTriviaSchedule($org_id: Int!, $id: Int!) {
              orgMutation(organization_id: $org_id) {
                delete_trivia_schedule(id: $id) {
                  successful
                  message
                }
              }
    }`, variables: { org_id: organizationId, id: scheduleId }
  }).then(response => _get(response, 'data.data.orgMutation.delete_trivia_schedule', {}));
}

export function deleteAllTriviaSchedules(org_id, scheduleIds = []) {

  const mutations = scheduleIds.map((id) => `
          ${"org_" + org_id + "_" + id}: orgMutation (organization_id: ${org_id}){
              delete_trivia_schedule(id: ${id}) {
                successful
                message
              }
          }
          `);

  return graphqlQuery( {
    query: `mutation deleteAllTriviaSchedules{
          ${mutations}
        }`
  }).then(response => _get(response, 'data.data', []));
}