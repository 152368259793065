import React, { useState } from "react";

import { Link } from "react-router-dom";
import { MenuList, MenuItem, MenuButton, Dropdown, SubMenuItem } from '@upshow/react-menu-list';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BusinessIcon from "@mui/icons-material/Business";

import './BreadcrumbDropdown.scss';

const BreadcrumbDropdown = (props) => {
    const { leaf, path, orgList, isPathLeaf } = props;
    const [isOpen, setIsOpen] = useState(false);

    const isRootDropdown = path === "root";
    const rootOrgs = orgList;
    const hasRootOrgs = rootOrgs.length > 0;

    if (!leaf) {
        let btnClassName = isOpen ? "ddClose" : "ddOpen";
        if (isPathLeaf) btnClassName += " blue";

        return (
            <div className="BreadcrumbDropdown">
                <MenuButton
                    disabled={!hasRootOrgs}
                    className={btnClassName}
                    onWillOpen={() => setIsOpen(true)}
                    onWillClose={() => setIsOpen(false)}
                    menuZIndex={100}
                    menu={
                        <div className="DropdownMenu">
                            <Dropdown>
                                <MenuList classMenu="BreadcrumbMenuList">
                                    {isRootDropdown && hasRootOrgs && <span className="BreadcrumbDropdown-SubHeader">ORGANIZATIONS</span>}
                                    {hasRootOrgs && rootOrgs.map((org, key) =>
                                        <DropDownMenuItem
                                            key={key}
                                            org={org}
                                            prependIcon={isRootDropdown}
                                            {...props}
                                        />
                                    )}
                                </MenuList>
                            </Dropdown>
                        </div>
                    }>
                    {isOpen ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                </MenuButton>
            </div>
        );
    }
    return null;
}

const DropDownMenuItem = (props) => {
    const { org, prependIcon } = props;

    if (!org.children || (Array.isArray(org.children) && org.children.length === 0)) {
        return (
            <Link to={org.url} className="DropdownLink">
                <MenuItem
                    className={org.isNext ? "DropdownMenuItem ddSelected" : "DropdownMenuItem"}
                    highlightedClassName="DropdownMenuItem-highlighted"
                    {...props}
                >
                    {prependIcon && <BusinessIcon className="list-icon" />}
                    {org.name}
                </MenuItem>
            </Link>
        );
    }

    return (
        <SubMenuItem
            className={org.isNext ? "DropdownSubmenuItem ddSelected" : "DropdownSubmenuItem"}
            highlightedClassName="DropdownMenuItem-highlighted"
            positionOptions={{position:'right', vAlign:'top', forceVAlign: false, hAlign: 'left'}}
            menuZIndex={1000}
            menu={
                <Dropdown>
                    <MenuList classMenu="BreadcrumbMenuList">
                        {org.children.map((orgChild, key) =>
                            <DropDownMenuItem {...props} key={key} org={orgChild} prependIcon={false} />
                        )}
                    </MenuList>
                </Dropdown>
            }>
            {prependIcon && <BusinessIcon className="list-icon" />}
                <Link to={org.url}>
                    {org.name}
                </Link>
            <KeyboardArrowRightIcon className="arrow-right" />
        </SubMenuItem>
    );
}

export default BreadcrumbDropdown;