import {denormalize, normalize, schema} from "normalizr";
import {REHYDRATE} from "redux-persist/constants"

export default class Entity extends schema.Entity {

    constructor(key, definition, options) {
        super(key, definition, {idAttribute: 'id', ...options});

        this._initialState = {
            byId: {},
            metadataById: {},
            metadata: {},
        };

        this._rehydrateInstanceOverrides = {};
    }

    get initialState() {
        return this._initialState;
    }

    set initialState(value) {
        this._initialState = value;
    }

    get rehydrateInstanceOverrides() {
        return this._rehydrateInstanceOverrides;
    }

    set rehydrateInstanceOverrides(value) {
        this._rehydrateInstanceOverrides = value;
    }

    parse(nestedData) {
        return normalize(nestedData, this);
    }

    parseArray(arrayOfNestedData) {
        return normalize(arrayOfNestedData, [this]);
    }

    unparse(state, normalizedData) {
        // Extract entities to match the shape 'denormalize()' expects.
        const entities = {};

        for (let key of Object.keys(state.entities)) {
            entities[key] = state.entities[key].byId;
        }

        return denormalize(normalizedData, this, entities);
    }

    shallowMergeReducer(state, action) {
        if (action.entities && action.entities[this.key]) {

            const itemsById = action.entities[this.key];
            let newState = {...state};

            for (let id of Object.keys(itemsById)) {
                newState = {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [id]: {
                            ...newState.byId[id],
                            ...itemsById[id]
                        }
                    }
                };
            }

            return newState;
        }

        return state;
    }

    rehydrateReducer(state, action) {
        const {payload} = action;

        if (payload && payload.entities && payload.entities[this.key]) {

            const itemsById = payload.entities[this.key].byId;
            let newState = {...state};

            for (let id of Object.keys(itemsById)) {
                newState = {
                    ...newState,
                    byId: {
                        ...newState.byId,
                        [id]: {
                            ...newState.byId[id],
                            ...itemsById[id],
                            ...this.rehydrateInstanceOverrides,
                        }
                    }
                };
            }

            return newState;

        }

        return state;
    }

    reducer(state = this.initialState, action) {
        switch (action.type) {
            case REHYDRATE:
                return this.rehydrateReducer(state, action);
            default:
                return this.shallowMergeReducer(state, action);
        }
    }

    getState(state) {
        return state.entities[this.key];
    }

    getMetadata(state, id) {
        const metadata = this.getState(state).metadataById[id];
        return metadata || {};
    }

    getGeneralMetadata(state) {
        const metadata = this.getState(state).metadata;
        return metadata || {};
    }

    findById(state, id, denormalized = false) {
        let item = this.getState(state) && this.getState(state).byId[id];
        
        if (item !== undefined && denormalized) {
            item = this.unparse(state, item);
        }

        return item;
    }

    findByIds(state, ids = [], denormalized = false) {
        return ids
            .map(id => this.findById(state, id, denormalized))
            .filter(i => !!i);
    }

    all(state, denormalized = false) {
        const container = this.getState(state).byId;
        return Object.keys(container)
            .map(k => {
                let item = container[k];

                if (item !== undefined && denormalized) {
                    item = this.unparse(state, item);
                }

                return item;
            });
    }

    count(state) {
        return Object.keys(this.getState(state).byId).length;
    }

};