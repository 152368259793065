import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import history from '../../services/HistoryService';
import { Home } from '../../views';

import GA from '../../services/GoogleAnalytics';

import './App.scss';

import { Router } from 'react-router-dom';

import store from '../../redux/store';
import { UPShowRedirectSecurity } from '@upshow/auth';
import UPshowManagerLogo from '../../assets/Manager_NewLogo.svg';

const App = () => {
    window.store = store;
    
    return (
        <Router history={history}>
            <>
                <div className="App">
                    {GA.init() && <GA.RouteTracker/>}
                    <UPShowRedirectSecurity
                        oktaBaseURL={process.env.REACT_APP_OKTA_URL_BASE}
                        issuer={process.env.REACT_APP_OKTA_ISSUER_URL}
                        clientId={process.env.REACT_APP_OKTA_CLIENT_ID}
                        baseURL={window.location.origin}
                        login={{
                            className: 'WidgetLogin',
                            logo: UPshowManagerLogo
                        }}
                        startingPath={window.location.pathname}
                    >
                        <Home/>
                    </UPShowRedirectSecurity>

                    <ToastContainer
                        position="bottom-center"
                        autoClose={7000}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick={true}
                        pauseOnHover={true}
                        style={{ zIndex: 3000 }}
                        limit={2}
                    />

                </div>
            </>

        </Router>
    );
};

App.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loadApp: PropTypes.func.isRequired,
};

export default App;
