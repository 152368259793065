"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_LOCATION = void 0;
exports.detectQRPlaceholder = detectQRPlaceholder;
var _QRPlaceholderDetector = require("./QRPlaceholderDetector");
const VERTICAL_PLACEHOLDER = {
  offsetX: -148,
  offsetY: -148,
  width: 636,
  height: 832
};
const HORIZONTAL_PLACEHOLDER = {
  offsetX: -148,
  offsetY: -148,
  width: 770,
  height: 584
};
const DEFAULT_LOCATION = exports.DEFAULT_LOCATION = {
  x: 1168,
  y: 130,
  width: 636,
  height: 832
};
async function detectQRPlaceholder(data) {
  let result;
  try {
    result = await (0, _QRPlaceholderDetector.decodeImageFromBuffer)(data);
  } catch (e) {
    return {
      error: e.message
    };
  }
  if (!result || !result.locations) {
    return {
      error: 'QR Placeholder not found.'
    };
  }
  const {
    offsetX,
    offsetY,
    width,
    height
  } = result.isVertical ? VERTICAL_PLACEHOLDER : HORIZONTAL_PLACEHOLDER;
  let scale;
  if (result.image.width > result.image.height) {
    // Horizontal Image
    scale = 1920 / result.image.width;
  } else {
    // Vertical Image
    scale = 1920 / result.image.height;
  }
  const x = parseFloat(result.locations[0].topLeft.x).toFixed(0);
  const y = parseFloat(result.locations[0].topLeft.y).toFixed(0);
  const fx = x * scale + offsetX;
  const fy = y * scale + offsetY;
  if (fx < 0 || fy < 0 || fx + width > result.image.width || fy + height > result.image.height) {
    return {
      error: 'QR Placeholder not found.'
    };
  }
  return {
    position: {
      x: fx,
      y: fy,
      width,
      height
    },
    orientation: result.isVertical ? 'vertical' : 'horizontal' // QR Vertical or Horizontal
  };
}