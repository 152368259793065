import React from 'react';

function UpBadge({ className = '', fill = '#FFFFFF' }) {
    return (
        <svg className={className} viewBox="0 0 37 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>Created with sketchtool.</desc>
            <g id="Create-Spotlights" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Spotlight---Create-spotlight---uploading-image" transform="translate(-1116.000000, -560.000000)" fill={fill}>
                    <g id="UPshow---Small-White-Logo" transform="translate(1116.500000, 560.000000)">
                        <g id="Group">
                            <g id="UPshow_Logo_PRIMARY">
                                <path d="M12.2515966,18.9393939 C10.7774698,18.9393939 9.50187851,18.4062619 8.45956659,17.3500568 C7.41973637,16.2963666 6.89361702,15.0037728 6.89361702,13.5074823 L6.89361702,7.59776363 C6.89361702,7.17276684 7.04748211,6.79555076 7.34032213,6.50132221 C7.91359368,5.91537989 8.91619848,5.91286512 9.5068419,6.49880744 C9.8046453,6.78800644 9.96595548,7.17025207 9.96595548,7.60027841 L9.96595548,13.5099971 C9.96595548,14.1487497 10.1843446,14.6843965 10.6360131,15.1471149 C11.5393501,16.0700369 12.9812149,16.0650074 13.8746252,15.1521444 C14.3262937,14.6944556 14.5446828,14.1588087 14.5446828,13.5099971 L14.5446828,7.60027841 C14.5446828,7.17025207 14.705993,6.79052121 15.0087598,6.49629266 C15.5894764,5.91789467 16.5945629,5.91789467 17.1727979,6.50635176 C17.4631562,6.80058031 17.6170213,7.18031116 17.6170213,7.60027841 L17.6170213,13.5099971 C17.6170213,14.9937137 17.0909019,16.2837927 16.0510717,17.3450273 C15.0037964,18.4012323 13.7282052,18.9393939 12.2515966,18.9393939 Z" id="Path"></path>
                                <path d="M34.2128755,0 L1.78712446,0 C0.798283262,0 0,0.781485576 0,1.74389386 L0,23.2561061 C0,24.2210272 0.800858369,25 1.78712446,25 L34.2128755,25 C35.2017167,25 36,24.2185144 36,23.2561061 L36,1.74389386 C36,0.778972761 35.1991416,0 34.2128755,0 Z M32.4257519,21.5122122 L3.57424882,21.5122122 L3.57424882,3.48778782 L32.4231768,3.48778782 L32.4231768,21.5122122 L32.4257519,21.5122122 Z" id="Shape"></path>
                                <path d="M22.3134801,18.9393939 C21.870868,18.9393939 21.4706619,18.7835616 21.157918,18.4894909 C20.8451741,18.18788 20.6808511,17.8083528 20.6808511,17.3911244 L20.6808511,7.60636222 C20.6808511,7.18410688 20.8451741,6.80709318 21.1552676,6.51050907 C21.4627107,6.21643839 21.8629169,6.06060606 22.3108297,6.06060606 L25.4409188,6.06060606 C26.6574394,6.06060606 27.7122874,6.47532113 28.5736582,7.28967071 C29.4350291,8.1040203 29.8723404,9.10184988 29.8723404,10.2504849 C29.8723404,11.39912 29.4350291,12.3944362 28.5710079,13.2062723 C27.709637,14.0155951 26.6574394,14.4277967 25.4409188,14.4277967 L23.9567106,14.4277967 L23.9567106,17.3911244 C23.9567106,17.8184065 23.7870868,18.1979337 23.4663918,18.4945178 C23.1509976,18.786075 22.7534418,18.9393939 22.3134801,18.9393939 Z M23.9620115,11.3186903 L25.4462197,11.3186903 C25.769565,11.3186903 26.0266511,11.2206667 26.2519327,11.0070256 C26.4798647,10.7883577 26.5911803,10.5470689 26.5991314,10.245458 C26.5911803,9.95138728 26.4825151,9.69753139 26.2519327,9.47886345 C26.0293015,9.2702492 25.7722154,9.16971221 25.4488701,9.16971221 L23.9646618,9.16971221 L23.9646618,11.3186903 L23.9620115,11.3186903 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default UpBadge;