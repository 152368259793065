const NAMESPACE = 'entities/plutoChannels/';

export const GET_PLUTO_CHANNELS = `${NAMESPACE}GET_PLUTO_CHANNELS`;

export function getPlutoChannels(organizationId) {
    return {
        type: GET_PLUTO_CHANNELS,
        payload: {
            organizationId,
        }
    };
}


export const GET_PLUTO_CHANNELS_SUCCESS = `${NAMESPACE}GET_PLUTO_CHANNELS_SUCCESS`;

export function getPlutoChannelsSuccess(organizationId, plutoChannelsIds, entities) {
    return {
        type: GET_PLUTO_CHANNELS_SUCCESS,
        payload: {
            organizationId,
            plutoChannelsIds,
        },
        entities,
    };
}


export const GET_PLUTO_CHANNELS_FAILURE = `${NAMESPACE}GET_PLUTO_CHANNELS_FAILURE`;

export function getPlutoChannelsFailure(organizationId, error) {
    return {
        type: GET_PLUTO_CHANNELS_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}