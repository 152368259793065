const NAMESPACE = 'entities/upshowNowChannels/';

export const GET_UPSHOW_NOW_CHANNELS = `${NAMESPACE}GET_UPSHOW_NOW_CHANNELS`;

export function getUpshowNowChannels(organizationId) {
    return {
        type: GET_UPSHOW_NOW_CHANNELS,
        payload: {
            organizationId,
        }
    };
}


export const GET_UPSHOW_NOW_CHANNELS_SUCCESS = `${NAMESPACE}GET_UPSHOW_NOW_CHANNELS_SUCCESS`;

export function getUpshowNowChannelsSuccess(organizationId, upshowNowChannelsIds, entities) {
    return {
        type: GET_UPSHOW_NOW_CHANNELS_SUCCESS,
        payload: {
            organizationId,
            upshowNowChannelsIds,
        },
        entities,
    };
}


export const GET_UPSHOW_NOW_CHANNELS_FAILURE = `${NAMESPACE}GET_UPSHOW_NOW_CHANNELS_FAILURE`;

export function getUpshowNowChannelsFailure(organizationId, error) {
    return {
        type: GET_UPSHOW_NOW_CHANNELS_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}