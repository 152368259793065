import {combineReducers} from "redux";

import content from "./content";
import organizations from "./organizations";
import schedules from "./schedules";
import spotlights from "./spotlights";
import spotlightAssets from "./spotlightAssets";
import spotlightBackgrounds from "./spotlightBackgrounds";
import upshowNowChannels from "./upshowNowChannels";
import upshowNowSchedules from "./upshowNowSchedules";
import triviaSchedules from "./triviaSchedules";
import plutoChannels from "./plutoChannels";
import plutoSchedules from "./plutoSchedules";
import jukinFeeds from "./jukinFeeds";
import jukinSchedules from "./jukinSchedules";
import organizationsSettings from './organizationsSettings';
import uiScripts from './uiScripts';
import scriptSchedules from './scriptSchedules'

export default combineReducers({
    content,
    organizations,
    schedules,
    spotlights,
    spotlightAssets,
    spotlightBackgrounds,
    upshowNowChannels,
    upshowNowSchedules,
    plutoChannels,
    plutoSchedules,
    jukinFeeds,
    jukinSchedules,
    organizationsSettings,
    triviaSchedules,
    uiScripts,
    scriptSchedules
});
