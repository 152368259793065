import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {createPersistor} from "redux-persist";

import persistConfig from "./config/persist";
import rootReducer from "./modules";
import rootSaga from "./modules/sagas";

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
    applyMiddleware(sagaMiddleware),
);

const store = createStore(rootReducer, {}, enhancer);
const persistor = createPersistor(store, persistConfig);
persistor.pause(); // Pause persistor until rehydration

sagaMiddleware.run(rootSaga);

export default store;
export {persistor};
