export function getCanvasFromVideoUrl (url, width = 1920, height = 1080) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    const video = document.createElement("video");
    video.crossOrigin='anonymous';
    video.src = url;
    video.volume = 0;

    return new Promise((resolve, reject) => {
        video.oncanplaythrough = () => video.play().catch(reject); // Try to play, if it isn't possible throw a Exception
        video.onplay = () => { // We fire the play action to be able to get the frame on the 3th second
            video.currentTime = 3;
            video.pause(); // The frame is "displayed", we pause the video
            video.oncanplaythrough = null; // Remove the canplaythrough event listener to avoid multiple calls
        }
        video.onpause = () => {
            setTimeout(() => {
                ctx.drawImage(video, 0, 0, width, height);
                resolve(canvas); // return the canva with the video frame
            }, 100); // The canva needs a few milliseconds to draw the frame
        }
    });
}

export function getCanvasFromImageUrl (url, width = 1920, height = 1080) {
    return new Promise((resolve, reject) => {
        const image = new Image(width, height)
        image.src = url;
        image.crossOrigin = "anonymous";
        image.onload = function() {
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            resolve(canvas);
        };
        image.onerror = reject;
    });
}

export async function getThumbnail (url, type) {
    let canvas;

    if (type === 'video') {
        canvas = await getCanvasFromVideoUrl(url);
    } else {
        canvas = await getCanvasFromImageUrl(url);
    }

    return canvas.toDataURL('image/png');
}

export function getBufferFromBase64Image (image) {
    return Buffer.from(image.replace(/^data:image\/png;base64,/, ''), 'base64');
}

export async function getVideoThumbnail (url) {
    const canvas = await getCanvasFromVideoUrl(url);
    return canvas.toDataURL('image/png');
}
