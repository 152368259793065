import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {createPersistor} from "redux-persist";
import createSagaMiddleware from "redux-saga";

import persistConfig from "./config/persist";
import rootReducer from "./modules";
import rootSaga from "./modules/sagas";

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeWithDevTools(
    applyMiddleware(sagaMiddleware)
);


const store = createStore(rootReducer, {}, enhancer);
const persistor = createPersistor(store, persistConfig);
persistor.pause(); // Pause persistor until rehydration

if (module.hot) {
    module.hot.accept(() => {
        store.replaceReducer(require('./modules').default);
    });
}

sagaMiddleware.run(rootSaga);

export default store;
export {persistor};
