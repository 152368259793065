import {all, call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {content as contentApi} from "../../../../api/modules";
import Content from "./Content";
import {
    BAN_CONTENT,
    banContentFailure,
    banContentSuccess,
    GET_CONTENT,
    getContent as getContentAction,
    getContentFailure,
    getContentSuccess,
    BAN_CONTENTS,
    banContentsFailure,
    banContentsSuccess
} from "./actions";


export function* getContent(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(contentApi.getContent, organizationId);
        const {result, entities} = Content.parseArray(response);
        yield put(getContentSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch content';
        yield call(toast.error, message);
        yield put(getContentFailure(organizationId, {message, error}));
    }
}

export function* watchGetContent() {
    yield takeEvery(GET_CONTENT, getContent);
}

export function* banContent(action) {
    const {organizationId, contentId} = action.payload;

    try {
        yield call(contentApi.banContent, organizationId, contentId);
        yield put(banContentSuccess(organizationId, contentId));
        yield call(toast.success, 'Content deleted!');
        yield put(getContentAction(organizationId));
    } catch (error) {
        const message = 'Couldn\'t delete content';
        yield call(toast.error, message);
        yield put(banContentFailure(organizationId, contentId, {message, error}));

    }
}

export function* watchBanContents() {
    yield takeEvery(BAN_CONTENTS, banContents);
}

export function* banContents(action) {
    const {organizationId, contentIds} = action.payload;

    try {
        yield call(contentApi.banContents, organizationId, contentIds);
        yield put(banContentsSuccess(organizationId, contentIds));
        yield call(toast.success, 'Content deleted!');
        yield put(getContentAction(organizationId));
    } catch (error) {
        const message = 'Couldn\'t delete content';
        yield call(toast.error, message);
        yield put(banContentsFailure(organizationId, contentIds, {message, error}));

    }
}

export function* watchBanContent() {
    yield takeEvery(BAN_CONTENT, banContent);
}

export default function* rootSaga() {
    yield all([
        watchGetContent(),
        watchBanContent(),
        watchBanContents(),
    ]);
};