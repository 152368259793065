import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import JukinSchedule from "./JukinSchedule";
import {jukinSchedules as jukinSchedulesApi} from "../../../../api/modules";
import * as actions from "./actions";
import scheduleAppliesToNow from "../../../../helpers/scheduleAppliesToNow";
import { scheduleAppliesToFuture } from "@upshow/sched-utils";
import Organization from '../organizations/Organization';

export function* getSchedules(action) {
    const {organizationId, includePastSchedules = false} = action.payload;

    try {
        const schedules = yield call(jukinSchedulesApi.getJukinSchedules, organizationId);
        const defaultTz = yield select(state => Organization.getCurrentTimezoneName(state));
        const activeSchedules = includePastSchedules ? schedules : schedules.filter((schedule) => scheduleAppliesToNow(schedule, defaultTz) || scheduleAppliesToFuture(schedule, defaultTz));
        const {result, entities} = JukinSchedule.parseArray(activeSchedules);
        yield put(actions.getSchedulesSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch organization schedules';
        yield call(toast.error, message);
        yield put(actions.getSchedulesFailure(organizationId, {message, error}));
    }
}

export function* watchGetSchedules() {
    yield takeEvery(actions.GET_SCHEDULES, getSchedules);
}


export function* createSchedule(action) {
    const {organizationId, feedId, schedule} = action.payload;

    try {
        const createdSchedule = yield call(jukinSchedulesApi.createJukinSchedule, organizationId, schedule);

        if(!!schedule.organizationIds){
            if(!!createdSchedule.error){
                throw new Error(createdSchedule);
            }
            yield put(actions.getSchedules(organizationId));
        } else {
            if(!createdSchedule.successful){
                throw new Error(createdSchedule);
            }
            yield put(actions.getSchedules(organizationId));
        }
    } catch (error) {
        const message = 'Couldn\'t create schedule to channel';
        yield call(toast.error, message);
        yield put(actions.createScheduleFailure(organizationId, feedId, {message, error}));
    }
}

export function* watchCreateSchedule() {
    yield takeEvery(actions.CREATE_SCHEDULE, createSchedule);
}


export function* deleteSchedule(action) {
    const {organizationId, feedId, scheduleId} = action.payload;

    try {
        const deleteResponse = yield call(jukinSchedulesApi.deleteJukinSchedule, organizationId, scheduleId);
        if(!deleteResponse.successful){
            throw new Error(deleteResponse);
        }
        yield put(actions.deleteScheduleSuccess(organizationId, feedId, scheduleId));
    } catch (error) {
        const message = 'Couldn\'t delete schedule to channel';
        yield call(toast.error, message);
        yield put(actions.deleteScheduleFailure(organizationId, feedId, scheduleId, {message, error}));
    }
}

export function* watchDeleteSchedule() {
    yield takeEvery(actions.DELETE_SCHEDULE, deleteSchedule);
}


export function* deleteAllSchedules(action) {
    const {organizationId, feedId} = action.payload;

    try {

        const deleteResponse = yield call(jukinSchedulesApi.deleteAllJukinSchedules, organizationId, feedId);

        if(!deleteResponse.successful){
            throw new Error(deleteResponse);
        }

        yield put(actions.deleteAllSchedulesSuccess(organizationId, feedId));
        yield put(actions.getSchedules(organizationId));
    } catch (error) {
        const message = 'Couldn\'t delete schedules';
        yield call(toast.error, message);
        yield put(actions.deleteAllSchedulesFailure(organizationId, feedId, {message, error}));
    }
}

export function* watchDeleteAllSchedules() {
    yield takeEvery(actions.DELETE_ALL_SCHEDULES, deleteAllSchedules);
}


export default function* rootSaga() {
    yield all([
        watchGetSchedules(),
        watchCreateSchedule(),
        watchDeleteSchedule(),
        watchDeleteAllSchedules(),
    ]);
};