import Entity from "../Entity";
import Organization from "../organizations/Organization";
import * as actions from "./actions";

import _get from 'lodash/get';

class Content extends Entity {

    constructor() {
        super('content');
    }


    reducer(state = this.initialState, action) {

        switch (action.type) {

            case actions.BAN_CONTENT: {
                const {contentId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [contentId]: {
                            ...state.metadataById[contentId],
                            isBanning: true,
                        }
                    },
                };
            }

            case actions.BAN_CONTENT_SUCCESS: {
                const {contentId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [contentId]: {
                            ...state.metadataById[contentId],
                            isBanning: false,
                        }
                    },
                };
            }

            case actions.BAN_CONTENT_FAILURE: {
                const {contentId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [contentId]: {
                            ...state.metadataById[contentId],
                            isBanning: false,
                        }
                    },
                };
            }

            case actions.BAN_CONTENTS: {
                const {contentIds} = action.payload;
                let contents = contentIds.map( id => {
                    return {
                        ...state.metadataById[id],
                        isBanning: true,
                    }
                })
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        ...contents
                    },
                };
            }

            case actions.BAN_CONTENTS_SUCCESS: {
                const {contentIds} = action.payload;
                let contents = contentIds.map( id => {
                    return {
                        ...state.metadataById[id],
                        isBanning: false,
                    }
                })
                return {
                    ...state,
                    selectedContents : [],
                    metadataById: {
                        ...state.metadataById,
                        ...contents
                    },
                };
            }

            case actions.BAN_CONTENTS_FAILURE: {
                const {contentIds} = action.payload;
                let contents = contentIds.map( id => {
                    return {
                        ...state.metadataById[id],
                        isBanning: false,
                    }
                })
                return {
                    ...state,
                    selectedContents : [],
                    metadataById: {
                        ...state.metadataById,
                        ...contents
                    },
                };
            }

            case actions.SELECT_CONTENT: {
                const {contentId} = action.payload;
                const selectedContents = _get(state, 'selectedContents', []).concat([contentId]);
                return {
                    ...state,
                    selectedContents,
                };
            }


            case actions.DESELECT_CONTENT: {
                const {contentId} = action.payload;
                const selectedContents = _get(state, 'selectedContents', []);
                return {
                    ...state,
                    selectedContents : selectedContents.filter( cont => cont !== contentId ),
                };
            }

            case actions.CLEAR_SELECT_CONTENT: {
                return {
                    ...state,
                    selectedContents : [],
                };
            }


            default: {
                return super.reducer(state, action);
            }
        }

    }

    getSelectedContent(state){
        return _get(this.getState(state), 'selectedContents', []);
    }

    isContentSelected(state, id){
        const selectedContents = _get(this.getState(state), 'selectedContents', []);
        return !!selectedContents.find( contentId => contentId === id );
    }

    getContentByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getContentIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    isBanning(state, id) {
        return !!this.getMetadata(state, id).isBanning;
    }

}

export default new Content();