const NAMESPACE = 'entities/organizations/';

export const REDIRECT_TO_RELATIVE_URL = `${NAMESPACE}REDIRECT_TO_RELATIVE_URL`;

export function redirectToRelativeUrl(path) {
    return {
        type: REDIRECT_TO_RELATIVE_URL,
        payload: {redirectToRelativeUrl: path}
    };
}

export const REDIRECT_TO_RELATIVE_URL_SUCCESS = `${NAMESPACE}REDIRECT_TO_RELATIVE_URL_SUCCESS`;

export function redirectToRelativeUrlSuccess() {
    return {
        type: REDIRECT_TO_RELATIVE_URL_SUCCESS,
        payload: {}
    };
}

export const GET_ROOT_ORGANIZATIONS = `${NAMESPACE}GET_ROOT_ORGANIZATIONS`;

export function getRootOrganizations() {
    return {
        type: GET_ROOT_ORGANIZATIONS,
    };
}


export const GET_ROOT_ORGANIZATIONS_SUCCESS = `${NAMESPACE}GET_ROOT_ORGANIZATIONS_SUCCESS`;

export function getRootOrganizationsSuccess(rootOrganizationsIds, entities) {
    return {
        type: GET_ROOT_ORGANIZATIONS_SUCCESS,
        payload: {
            rootOrganizationsIds,
        },
        entities,
    };
}


export const GET_ROOT_ORGANIZATIONS_FAILURE = `${NAMESPACE}GET_ROOT_ORGANIZATIONS_FAILURE`;

export function getRootOrganizationsFailure(error) {
    return {
        type: GET_ROOT_ORGANIZATIONS_FAILURE,
        payload: {
            error,
        }
    };
}


export const GET_ORGANIZATION_TIMEZONES_SUCCESS = `${NAMESPACE}GET_ORGANIZATION_TIMEZONES_SUCCESS`;

export function getOrganizationTimezonesSuccess(timezones) {
    return {
        type: GET_ORGANIZATION_TIMEZONES_SUCCESS,
        payload: {
            timezones,
        }
    };
}


export const GET_ORGANIZATION_TIMEZONES_FAILURE = `${NAMESPACE}GET_ORGANIZATION_TIMEZONES_FAILURE`;

export function getOrganizationTimezonesFailure(error) {
    return {
        type: GET_ORGANIZATION_TIMEZONES_FAILURE,
        payload: {
            error,
        }
    };
}







export const SWITCH_ORGANIZATION = `${NAMESPACE}SWITCH_ORGANIZATION`;

export function switchOrganization(organizationPath) {
    return {
        type: SWITCH_ORGANIZATION,
        payload: {
            organizationPath,
        }
    };
}


export const SWITCH_ORGANIZATION_SUCCESS = `${NAMESPACE}SWITCH_ORGANIZATION_SUCCESS`;

export function switchOrganizationSuccess(organizationPath, org_id) {
    return {
        type: SWITCH_ORGANIZATION_SUCCESS,
        payload: {
            organizationPath,
            org_id
        }
    };
}


export const SWITCH_ORGANIZATION_FAILURE = `${NAMESPACE}SWITCH_ORGANIZATION_FAILURE`;

export function switchOrganizationFailure(organizationPath, error) {
    return {
        type: SWITCH_ORGANIZATION_FAILURE,
        payload: {
            organizationPath,
            error,
        }
    };
}

export const GET_ORGANIZATION = `${NAMESPACE}GET_ORGANIZATION`;

export function getOrganization(organizationId) {
    return {
        type: GET_ORGANIZATION,
        payload: {
            organizationId,
        }
    };
}


export const GET_ORGANIZATION_SUCCESS = `${NAMESPACE}GET_ORGANIZATION_SUCCESS`;

export function getOrganizationSuccess(organizationId, entities) {
    return {
        type: GET_ORGANIZATION_SUCCESS,
        payload: {
            organizationId,
        },
        entities
    };
}


export const GET_ORGANIZATION_FAILURE = `${NAMESPACE}GET_ORGANIZATION_FAILURE`;

export function getOrganizationFailure(organizationId, error) {
    return {
        type: GET_ORGANIZATION_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}
