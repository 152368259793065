import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import PlutoSchedule from "./PlutoSchedule";
import {plutoSchedules as plutoSchedulesApi} from "../../../../api/modules";
import * as actions from "./actions";
import scheduleAppliesToNow from "../../../../helpers/scheduleAppliesToNow";
import { scheduleAppliesToFuture } from "@upshow/sched-utils";
import Organization from '../organizations/Organization';

export function* getSchedules(action) {
    const {organizationId, includePastSchedules = false} = action.payload;

    try {
        const schedules = yield call(plutoSchedulesApi.getPlutoSchedules, organizationId);
        const defaultTz = yield select(state => Organization.getCurrentTimezoneName(state));
        const activeSchedules = includePastSchedules ? schedules : schedules.filter((schedule) => scheduleAppliesToNow(schedule, defaultTz) || scheduleAppliesToFuture(schedule, defaultTz));
        const {result, entities} = PlutoSchedule.parseArray(activeSchedules);
        yield put(actions.getSchedulesSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch organization schedules';
        yield call(toast.error, message);
        yield put(actions.getSchedulesFailure(organizationId, {message, error}));
    }
}

export function* watchGetSchedules() {
    yield takeEvery(actions.GET_SCHEDULES, getSchedules);
}


export function* createSchedule(action) {
    const {organizationId, channelId, schedule} = action.payload;

    try {
        yield call(plutoSchedulesApi.createPlutoSchedule, organizationId, schedule);

        yield put(actions.getSchedules(organizationId));
    } catch (error) {
        const message = 'Couldn\'t subscribe to channel';
        yield call(toast.error, message);
        yield put(actions.createScheduleFailure(organizationId, channelId, {message, error}));
    }
}

export function* watchCreateSchedule() {
    yield takeEvery(actions.CREATE_SCHEDULE, createSchedule);
}


export function* deleteSchedule(action) {
    const {organizationId, channelId, scheduleId} = action.payload;

    try {
        yield call(plutoSchedulesApi.deletePlutoSchedule, organizationId, scheduleId);
        yield put(actions.deleteScheduleSuccess(organizationId, channelId, scheduleId));
    } catch (error) {
        const message = 'Couldn\'t unsubscribe to channel';
        yield call(toast.error, message);
        yield put(actions.deleteScheduleFailure(organizationId, channelId, scheduleId, {message, error}));
    }
}

export function* watchDeleteSchedule() {
    yield takeEvery(actions.DELETE_SCHEDULE, deleteSchedule);
}


export function* deleteAllSchedules(action) {
    const {organizationId, channelId} = action.payload;

    try {

        const deleteResponse = yield call(plutoSchedulesApi.deleteAllPlutoSchedules, organizationId, channelId);

        if(!deleteResponse.successful){
            throw new Error(deleteResponse);
        }

        yield put(actions.deleteAllSchedulesSuccess(organizationId, channelId));
        yield put(actions.getSchedules(organizationId));
    } catch (error) {
        const message = 'Couldn\'t unsubscribe';
        yield call(toast.error, message);
        yield put(actions.deleteAllSchedulesFailure(organizationId, channelId, {message, error}));
    }
}

export function* watchDeleteAllSchedules() {
    yield takeEvery(actions.DELETE_ALL_SCHEDULES, deleteAllSchedules);
}


export default function* rootSaga() {
    yield all([
        watchGetSchedules(),
        watchCreateSchedule(),
        watchDeleteSchedule(),
        watchDeleteAllSchedules(),
    ]);
};