import { gql } from '@apollo/client';

export const MANAGE_ENTERTAINMENT_DISPLAY_RULES = gql`
    mutation manageEntertainmentDisplayRules(
        $org_id: Int!,
        $object_id: Int!,
        $object_type: DISPLAY_RULES_OBJECT_TYPES!,
        $labelDisplayRules: [DisplayRuleInput!],
        $dateRangeDisplayRule: InputMetadataDateRangeDisplayRule,
        $weekDaysDisplayRule: InputMetadataWeekDaysDisplayRule,
        $timeRangeDisplayRule: InputMetadataTimeRangeDisplayRule,
        $removedDisplayRules: [Int!],
        $removedTimeBasedDRs: [Int!],
        $manageLabelDisplayRule: Boolean!,
        $manageDateRangeDisplayRule: Boolean!,
        $manageTimeRangeDisplayRule: Boolean!,
        $manageWeekDaysDisplayRule: Boolean!,
        $shouldDeleteTimeBasedDisplayRules: Boolean!,
        $shouldDeleteDisplayRules: Boolean!)
    {
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules {
                upsertDisplayRule(object_id: $object_id object_type: $object_type display_rules: $labelDisplayRules) @include(if: $manageLabelDisplayRule) {
                    successful
                    message
                    status
                }
                upsertDateRangeDisplayRule(object_id: $object_id object_type: $object_type metadata: $dateRangeDisplayRule) @include(if: $manageDateRangeDisplayRule) {
                    successful
                    message
                    status
                }
                upsertTimeRangeDisplayRule(object_id: $object_id object_type: $object_type metadata: $timeRangeDisplayRule) @include(if: $manageTimeRangeDisplayRule) {
                    successful
                    message
                    status
                }
                upsertWeekDaysDisplayRule(object_id: $object_id object_type: $object_type metadata: $weekDaysDisplayRule) @include(if: $manageWeekDaysDisplayRule) {
                    successful
                    message
                    status
                }
                deleteTimeBasedDisplayRules(time_based_display_rules_ids: $removedTimeBasedDRs) @include(if: $shouldDeleteTimeBasedDisplayRules) {
                    successful
                    message
                    status
                }
                deleteDisplayRules(display_rules_ids: $removedDisplayRules) @include(if: $shouldDeleteDisplayRules) {
                    successful
                    message
                    status
                }
            }
        }
    }
`;

export const UPSERT_DISPLAY_RULE = gql`
    mutation upsertDisplayRule($org_id: Int!, $object_id: Int!, $object_type: DISPLAY_RULES_OBJECT_TYPES!, $labelDisplayRules: [DisplayRuleInput!]){
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules{
                upsertDisplayRule(object_id: $object_id object_type: $object_type display_rules: $labelDisplayRules){
                    successful
                    message
                    status
                }
            }
        }
    }
`;

export const UPSERT_DATE_RANGE_DISPLAY_RULE = gql`
    mutation upsertDateRangeDisplayRule($org_id: Int!, $object_id: Int!, $object_type: DISPLAY_RULES_OBJECT_TYPES!, $dateRangeDisplayRule: InputMetadataDateRangeDisplayRule){
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules{
                upsertDateRangeDisplayRule(object_id: $object_id object_type: $object_type metadata: $dateRangeDisplayRule) {
                    successful
                    message
                    status
                }
            }
        }
    }
`;

export const UPSERT_WEEK_DAYS_DISPLAY_RULE = gql`
    mutation upsertWeekDaysDisplayRule($org_id: Int!, $object_id: Int!, $object_type: DISPLAY_RULES_OBJECT_TYPES!, $weekDaysDisplayRule: InputMetadataWeekDaysDisplayRule){
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules{
                upsertWeekDaysDisplayRule(object_id: $object_id object_type: $object_type metadata: $weekDaysDisplayRule) {
                    successful
                    message
                    status
                }
            }
        }
    }
`;

export const UPSERT_TIME_RANGE_DISPLAY_RULE = gql`
    mutation upsertTimeRangeDisplayRule($org_id: Int!, $object_id: Int!, $object_type: DISPLAY_RULES_OBJECT_TYPES!, $timeRangeDisplayRule: InputMetadataTimeRangeDisplayRule){
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules{
                upsertTimeRangeDisplayRule(object_id: $object_id object_type: $object_type metadata: $timeRangeDisplayRule) {
                    successful
                    message
                    status
                }
            }
        }
    }
`;

export const DELETE_DISPLAY_RULE = gql`
    mutation deleteDisplayRule($org_id: Int!, $removedDisplayRules: [Int!]){
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules {
                deleteDisplayRules(display_rules_ids: $removedDisplayRules) {
                    successful
                    message
                    status
                }
            }
        }
    }
`;

export const DELETE_TIME_BASED_DISPLAY_RULE = gql`
    mutation deleteTimeBasedDisplayRule($org_id: Int!, $removedTimeBasedDRs: [Int!]){
        orgMutation(organization_id: $org_id) {
            manageTimeBasedDisplayRules {
                deleteTimeBasedDisplayRules(time_based_display_rules_ids: $removedTimeBasedDRs){
                    successful
                    message
                    status
                }
            }
        }
    }
`;