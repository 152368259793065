import _uniq from 'lodash/uniq';

export function parse(pathString) {
    return pathString.split('-').map(orgId => Number.parseInt(orgId, 10));
}

export function stringify(path) {
    return path.join('-');
}

export function buildUrl( prefix, organizationPath) {
    const newPath = [
        prefix,
        stringify(organizationPath)
    ].join('/');

    return `/${newPath}`;
}

const findPath = (fromOrg, toOrgId) => {
    const currentOrg = fromOrg.children.find( cOrg => cOrg.id === toOrgId);
    if(!!currentOrg || fromOrg.id === toOrgId){
        return [fromOrg.id, toOrgId];
    }
    if(fromOrg && fromOrg.children && fromOrg.children.length === 0){
        return [];
    }
    let carry = [];
    fromOrg.children.forEach( (org) => {
        const redOrg = findPath(org, toOrgId);
        if(redOrg.length > 0){
            carry = [fromOrg.id].concat(redOrg);
        }
    });
    return carry;
};

export function getValidPathInsideCurrentOrganization( rootOrg, rootOrgPath, orgId) {
    const path = _uniq(rootOrgPath.concat(findPath(rootOrg, orgId)));
    return path;
}

export function getSubPathInsideCurrentOrganization( rootOrg, rootOrgPath, orgId) {
    const subPath = findPath(rootOrg, orgId);
    if (subPath && subPath.length){
        return _uniq(rootOrgPath.concat(subPath));
    } else {
        return [];
    }
}

export const orgPathFromCurrentOrgAndPath = (newOrgId, currentOrgId, currentOrgPath) => {
    let orgPath = [];
    const newOrgPathIdx = currentOrgPath ? currentOrgPath.findIndex( d=> d === newOrgId) : -1;
    if (newOrgPathIdx >= 0 ){
        orgPath = currentOrgPath.slice(0, newOrgPathIdx+1);
    } else {
        orgPath = getSubPathInsideCurrentOrganization(currentOrgId, currentOrgPath, newOrgId) ;
    }
    return orgPath;
};

export const buildOrgUrlFromCurrentOrgAndPath = (newOrgId, currentOrgId, currentOrgPath, defaultToCurrent = false ) => {
    let orgPath = orgPathFromCurrentOrgAndPath(newOrgId, currentOrgId, currentOrgPath);
    if (defaultToCurrent && (!orgPath || !orgPath.length) ) {
        orgPath = currentOrgPath;
    }
    return buildUrl('organizations', orgPath );
}

export const canReachOrgFromCurrentOrgAndPath = (newOrgId, currentOrgId, currentOrgPath) => {
    const orgPath = orgPathFromCurrentOrgAndPath(newOrgId, currentOrgId, currentOrgPath);
    return !!orgPath.length;
}