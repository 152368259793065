import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { organizations as OrganizationsApi } from '../../../../api/modules/index';
import { getUiScripts } from '../uiScripts/actions';
import OrganizationSetting from './OrganizationSetting';

import {
    SET_DEFAULT_MIX,
    SET_LOCK_MIX,
    setDefaultMixFailure,
    setDefaultMixSuccess,
    setMixLockingFailure,
    setMixLockingSuccess,
} from './actions';

export function* setDefaultMix(action) {
    const { organizationId, script_id, script_name, callback } = action.payload;

    try {
        const response = yield call(OrganizationsApi.setDefaultMix, organizationId, script_id, script_name);
        
        if(!response.successful) {
            throw new Error(response);
        }
        const {result, entities} = OrganizationSetting.parseArray({id: 'ui_script', script_name});
        yield put(setDefaultMixSuccess(organizationId, result, entities));
        yield call(callback);
        
        const message = 'Default Playlist was updated';
        yield call(toast.success, message);
    } catch (error) {
        const message = 'Couldn\'t set Default Playlist';
        yield call(toast.error, message);
        yield put(setDefaultMixFailure(organizationId, {message, error}));
    }
}

export function* setMixLocking(action) {
    const { organizationId, script_id, locking, callback } = action.payload;

    try {
        const response = yield call(OrganizationsApi.setMixLocking, organizationId, script_id, locking);

        if (!response.successful) {
            throw new Error(response);
        }
        yield put(setMixLockingSuccess(organizationId));
        yield put(getUiScripts(organizationId));
        yield call(callback);
        const message = `The Playlist was ${locking ? "locked" : "unlocked"}`;
        yield call(toast.success, message);
    } catch (error) {
        const message = `Couldn't ${locking ? "lock" : "unlock"} the Playlist`;
        yield call(toast.error, message);
        yield put(setMixLockingFailure(organizationId, { message, error }));
    }
}

export function* watchSetDefaultMix() {
    yield takeEvery(SET_DEFAULT_MIX, setDefaultMix);
}

export function* watchLockMix() {
    yield takeEvery(SET_LOCK_MIX, setMixLocking);
}

export default function* rootSaga() {
    yield all([
        watchSetDefaultMix(),
        watchLockMix(),
    ]);
};