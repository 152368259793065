import { all, call, put, takeEvery } from 'redux-saga/effects';

import { BOOKMARK_ORG, bookmarkOrgFailure, bookmarkOrgSuccess, GET_USER_INFO, GET_USER_SETTINGS, getUserInfoFailure, getUserInfoSuccess, getUserSettings as getUserSettingsAction, getUserSettingsFailure, getUserSettingsSuccess, SET_USER_SETTINGS, setUserSettingsFailure, setUserSettingsSuccess } from './actions';
import { session as sessionApi } from '../../../api/modules';
import Organization from '../entities/organizations/Organization';
import { getRootOrganizationsFailure, getRootOrganizationsSuccess, SWITCH_ORGANIZATION_SUCCESS } from '../entities/organizations/actions';

export function * getUserInfo () {
    try {
        const { error, response: { user } } = yield call(sessionApi.userInfo);

        if (error) throw error;

        const sortedOrganizations = deepSortByName(user.organizations);
        const { result: rootOrganizationsIds, entities } = Organization.parseArray(sortedOrganizations);

        yield put(getRootOrganizationsSuccess(rootOrganizationsIds, entities));
        yield put(getUserInfoSuccess(user));
    } catch (error) {
        const message = 'Something Went wrong';
        yield put(getUserInfoFailure({ message, error, }));
        yield put(getRootOrganizationsFailure("Error fetching organizations"));
    }
}

export function* watchGetUserInfo() {
    yield takeEvery(GET_USER_INFO, getUserInfo);
}

export function* getUserSettings() {
    try {
        const userSettings = yield call(sessionApi.getUserSettings);

        yield put(getUserSettingsSuccess(userSettings));
    } catch (error) {
        const message = 'Something Went wrong';
        yield put(getUserSettingsFailure({
            message,
            error,
        }));

    }
}

export function* watchGetUserSettings() {
    yield takeEvery(GET_USER_SETTINGS, getUserSettings);
}

export function* setUserSettings(action) {
    try {
        const {key, value} = action.payload;
        const response = yield call(sessionApi.setUserSettings, key, value);

        if(response){
            yield put(getUserSettingsAction());
            yield put(setUserSettingsSuccess());
        } else {
            yield put(setUserSettingsFailure({
                message: 'Something Went wrong',
                error: {}
            }));
        }
    } catch (error) {
        const message = 'Something Went wrong';
        yield put(setUserSettingsFailure({
            message,
            error,
        }));

    }
}

export function* watchSetUserSettings() {
    yield takeEvery(SET_USER_SETTINGS, setUserSettings);
}


export function* bookmarkOrg(action) {
    try {
        const {org_id, isBookmarked} = action.payload;
        const bookmarks = yield call(sessionApi.bookmarkOrganization, org_id, isBookmarked);
        yield put(bookmarkOrgSuccess(bookmarks));
    } catch (error) {
        const message = 'Something Went wrong';
        yield put(bookmarkOrgFailure({
            message,
            error,
        }));

    }
}

export function* watchBookmarkOrg() {
    yield takeEvery(BOOKMARK_ORG, bookmarkOrg);
}

export function* pendoBoot(action) {
    const { org_id } = action.payload;
    const pendo = window.pendo;

    try {
        const pendoSettings = yield call(sessionApi.pendoSettings, org_id);

        if (pendo.isReady()) {
            pendo.identify(pendoSettings);
        } else {
            pendo.initialize(pendoSettings);
        }
    } catch (error) {
        console.error(error);
    }
}


export function* watchPendoBoot() {
    yield takeEvery([SWITCH_ORGANIZATION_SUCCESS], pendoBoot);
}

function deepSortByName(organizations) {
    return organizations?.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        })
        .map(o => ({ ...o, children: deepSortByName(o.child_tree), child_tree: undefined })) ?? [];
}

export default function* rootSaga() {
    yield all([
        watchGetUserInfo(),
        watchBookmarkOrg(),
        watchPendoBoot(),
        watchGetUserSettings(),
        watchSetUserSettings(),
    ]);
}