const NAMESPACE = 'entities/spotlightAssets/';


export const SET_DISPLAY_FILTER = `${NAMESPACE}SET_DISPLAY_FILTER`;

export function setDisplayFilter(displayFilter) {
    return {
        type: SET_DISPLAY_FILTER,
        payload: {
            displayFilter,
        },
    };
}


export const GET_SPOTLIGHT_ASSETS = `${NAMESPACE}GET_SPOTLIGHT_ASSETS`;

export function getSpotlightAssets(organizationId) {
    return {
        type: GET_SPOTLIGHT_ASSETS,
        payload: {
            organizationId,
        }
    };
}


export const GET_SPOTLIGHT_ASSETS_SUCCESS = `${NAMESPACE}GET_SPOTLIGHT_ASSETS_SUCCESS`;

export function getSpotlightAssetsSuccess(organizationId, spotlightAssetsIds, entities) {
    return {
        type: GET_SPOTLIGHT_ASSETS_SUCCESS,
        payload: {
            organizationId,
            spotlightAssetsIds,
        },
        entities,
    };
}


export const GET_SPOTLIGHT_ASSETS_FAILURE = `${NAMESPACE}GET_SPOTLIGHT_ASSETS_FAILURE`;

export function getSpotlightAssetsFailure(organizationId, error) {
    return {
        type: GET_SPOTLIGHT_ASSETS_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}


export const GET_SPOTLIGHT_ASSET = `${NAMESPACE}GET_SPOTLIGHT_ASSET`;

export function getSpotlightAsset(organizationId, spotlightAssetId) {
    return {
        type: GET_SPOTLIGHT_ASSET,
        payload: {
            organizationId,
            spotlightAssetId,
        }
    };
}


export const GET_SPOTLIGHT_ASSET_SUCCESS = `${NAMESPACE}GET_SPOTLIGHT_ASSET_SUCCESS`;

export function getSpotlightAssetSuccess(organizationId, spotlightAssetId, entities) {
    return {
        type: GET_SPOTLIGHT_ASSET_SUCCESS,
        payload: {
            organizationId,
            spotlightAssetId,
        },
        entities
    };
}


export const GET_SPOTLIGHT_ASSET_FAILURE = `${NAMESPACE}GET_SPOTLIGHT_ASSET_FAILURE`;

export function getSpotlightAssetFailure(organizationId, spotlightAssetId, error) {
    return {
        type: GET_SPOTLIGHT_ASSET_FAILURE,
        payload: {
            organizationId,
            spotlightAssetId,
            error,
        },
    };
}

export const CREATE_SPOTLIGHT_ASSET = `${NAMESPACE}CREATE_SPOTLIGHT_ASSET`;

export function createSpotlightAsset(organizationId, spotlightAsset, noPreprocess, doNotRedirectCallback = false) {
    return {
        type: CREATE_SPOTLIGHT_ASSET,
        payload: {
            organizationId,
            spotlightAsset,
            noPreprocess,
            doNotRedirectCallback
        }
    };
}


export const CREATE_SPOTLIGHT_ASSET_SUCCESS = `${NAMESPACE}CREATE_SPOTLIGHT_ASSET_SUCCESS`;

export function createSpotlightAssetSuccess(organizationId, spotlightAssetId, entities) {
    return {
        type: CREATE_SPOTLIGHT_ASSET_SUCCESS,
        payload: {
            organizationId,
            spotlightAssetId,
        },
        entities
    };
}


export const CREATE_SPOTLIGHT_ASSET_FAILURE = `${NAMESPACE}CREATE_SPOTLIGHT_ASSET_FAILURE`;

export function createSpotlightAssetFailure(organizationId, error) {
    return {
        type: CREATE_SPOTLIGHT_ASSET_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}