import packageInfo from '../../../package.json';
const { version } = packageInfo;

export default {
    baseURL: process.env.REACT_APP_GRAPHQL_URL,
    headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-App-Version': version
    },
};
