import { all, call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import { takeLeading } from "../../../../helpers/sagas";
import {spotlights as spotlightsApi} from "../../../../api/modules";
import { trackingCodes as trackingCodesApi } from "../../../../api/modules";
import Spotlight from "./Spotlight";
import * as actions from "./actions";
import { redirectToRelativeUrl } from "../organizations/actions";
import cloneDeep from "lodash/cloneDeep";


export function* getSpotlights(action) {
    const { organizationId } = action.payload;

    try {
        const response = yield call(spotlightsApi.getSpotlights, organizationId);
        const { result, entities } = Spotlight.parseArray(response);
        yield put(actions.getSpotlightsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch spotlights';
        yield call(toast.error, message);
        yield put(actions.getSpotlightsFailure(organizationId, { message, error }));
    }
}

export function* watchGetSpotlights() {
    yield takeEvery(actions.GET_SPOTLIGHTS, getSpotlights);
}


export function* getSpotlight(action) {
    const { organizationId, spotlightId } = action.payload;

    try {
        const response = yield call(spotlightsApi.getSpotlight, organizationId, spotlightId);
        const { result, entities } = Spotlight.parse(response);
        yield put(actions.getSpotlightSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch spotlight';
        yield call(toast.error, message);
        yield put(redirectToRelativeUrl('/spotlights'));
        yield put(actions.getSpotlightFailure(organizationId, spotlightId, { message, error }));
    }
}

export function* watchGetSpotlight() {
    yield takeEvery(actions.GET_SPOTLIGHT, getSpotlight);
}


export function* deleteSpotlight(action) {
    const { organizationId, spotlightId, redirect } = action.payload;

    try {
        yield call(spotlightsApi.deleteSpotlight, organizationId, spotlightId);
        yield put(actions.deleteSpotlightSuccess(organizationId, spotlightId));
        yield call(toast.success, 'Spotlight deleted!');
        if (!!redirect) {
            yield put(redirectToRelativeUrl(redirect));
        }
    } catch (error) {
        const message = 'Couldn\'t delete spotlight!';
        yield call(toast.error, message);
        yield put(actions.deleteSpotlightFailure(organizationId, spotlightId, { error, message }));
    }
}

export function* watchDeleteSpotlight() {
    yield takeEvery(actions.DELETE_SPOTLIGHT, deleteSpotlight);
}


export function* createSpotlight(action) {
    const { organizationId, spotlight, redirect, type, addSchedules, destinationUrl, logo, engagement_value, displayRules, addTags, scriptSchedules } = action.payload;

    try {
        const totalSchedules = [];

        addSchedules.forEach(schedule => {
            if(schedule.action) {
                delete schedule.id;
                delete schedule.organization;
            }

            delete schedule.action;

            if(Array.isArray(schedule.organizationIds)) {
                schedule.organizationIds.forEach(id => {
                    const scheduleToCreate = cloneDeep(schedule);
                    scheduleToCreate.organization_id = id;

                    delete scheduleToCreate.organizationIds;

                    totalSchedules.push(scheduleToCreate);
                })
            } else {
                delete schedule.organizationIds;

                totalSchedules.push(schedule);
            }
        });

        delete spotlight.background;
        delete spotlight.organization;

        if (destinationUrl) {
            spotlight.tracking = {destination: destinationUrl, logo, engagement_value};
        }

        const createdSpotlight = yield call(spotlightsApi.createSpotlight, organizationId, spotlight, totalSchedules, displayRules, addTags, scriptSchedules);

        if(!createdSpotlight.successful){
            throw new Error(createdSpotlight);
        }

        yield put(actions.createSpotlightSuccess(organizationId));
        yield call(toast.success, 'Spotlight created!');
        yield put(actions.getSpotlights(organizationId));

        if(redirect) {
            yield put(redirectToRelativeUrl(redirect));
        } else {
            yield put(redirectToRelativeUrl(`/spotlights/${createdSpotlight.spotlight.id}?toolbox=${type?.toUpperCase()}`));
        }
    } catch (error) {
        const message = 'Couldn\'t create spotlight';
        yield call(toast.error, message);
        yield put(actions.createSpotlightFailure(organizationId, { message, error }));

    }
}

export function* watchCreateSpotlight() {
    yield takeLeading(actions.CREATE_SPOTLIGHT, createSpotlight);
}

export function* updateSpotlight(action) {
    const { organizationId, spotlightId, spotlight, redirect, addSchedules, deleteSchedules, spt_org_id, displayRules, addTags, deleteTags, scriptSchedules, delete_script_ids } = action.payload;
    try {
        let updatedSpotlight = null;

        const totalSchedulesToAdd = [];

        addSchedules.forEach(schedule => {
            if(schedule.action) {
                delete schedule.id;
                delete schedule.organization;
            }

            delete schedule.action;

            if(Array.isArray(schedule.organizationIds)) {
                schedule.organizationIds.forEach(id => {
                    const scheduleToCreate = cloneDeep(schedule);
                    scheduleToCreate.organization_id = id;

                    delete scheduleToCreate.organizationIds;

                    totalSchedulesToAdd.push(scheduleToCreate);
                })
            } else {
                delete schedule.organizationIds;

                totalSchedulesToAdd.push(schedule);
            }
        });
        if (spt_org_id === organizationId){
            if (spotlight.unsafe_update) {
                delete spotlight.unsafe_update;
                updatedSpotlight = yield call(spotlightsApi.updateSpotlight, organizationId, spotlightId, spotlight, true,
                    totalSchedulesToAdd, deleteSchedules, displayRules, addTags, deleteTags,  scriptSchedules, delete_script_ids);
            } else {
                updatedSpotlight = yield call(spotlightsApi.updateSpotlight, organizationId, spotlightId, spotlight, false,
                    totalSchedulesToAdd, deleteSchedules, displayRules, addTags, deleteTags,  scriptSchedules, delete_script_ids);
            }
        }else{
            updatedSpotlight = yield call(spotlightsApi.updateParentSpotlight, organizationId, spotlightId, totalSchedulesToAdd, deleteSchedules, scriptSchedules, delete_script_ids);
        }
        

        if(!updatedSpotlight.successful){
            throw new Error(updatedSpotlight)
        }

        const { result, entities } = Spotlight.parse(updatedSpotlight.spotlight);

        yield put(actions.updateSpotlightSuccess(organizationId, result, entities));

        yield call(toast.success, 'Spotlight updated!');

        if (redirect) {
            yield put(redirectToRelativeUrl(redirect));
        } else {
            yield put(actions.getSpotlight(organizationId, spotlightId));
        }

    } catch (error) {
        const message = 'Couldn\'t update spotlight';
        yield call(toast.error, message);
        yield put(actions.updateSpotlightFailure(organizationId, spotlightId, { message, error }));

    }
}

export function* watchUpdateSpotlight() {
    yield takeLeading(actions.UPDATE_SPOTLIGHT, updateSpotlight);
}


export function* disableSelectedSpotlights(action) {
    const { spotlightsIds, organizationId } = action.payload;

    try {
        yield call(spotlightsApi.disableSpotlights, spotlightsIds);
        yield put(actions.clearSelectedSpotlights());
        yield put(actions.disableSelectedSpotlightsSuccess(spotlightsIds));
        yield call(toast.success, 'Spotlights Disabled!');
        yield put(actions.getSpotlights(organizationId));
    } catch (error) {
        const message = 'Couldn\'t disable spotlight!';
        yield call(toast.error, message);
        yield put(actions.disableSelectedSpotlightsFailure(spotlightsIds, { error, message }));
    }
}

export function* watchDisableSelectedSpotlights() {
    yield takeEvery(actions.DISABLE_SELECTED_SPOTLIGHTS, disableSelectedSpotlights);
}

export function* deleteSelectedSpotlights(action) {
    const { spotlightsIds, organizationId } = action.payload;

    try {
        yield call(spotlightsApi.deleteSpotlights, spotlightsIds, organizationId);
        yield put(actions.clearSelectedSpotlights());
        yield put (actions.deleteSelectedSpotlightsSuccess(spotlightsIds));
        yield call(toast.success, 'Spotlights deleted!');
        yield put(actions.getSpotlights(organizationId));
    } catch (error) {
        const message = 'Couldn\'t delete selected spotlights!';
        yield call(toast.error, message);
    }
}

export function* watchDeleteSpotlights() {
    yield takeEvery(actions.DELETE_SELECTED_SPOTLIGHTS, deleteSelectedSpotlights);
}


export function* createTrackingCode(action) {
    const { organizationId, destination, spotlightId, logo, engagement_value } = action.payload;

    try {
        yield call(trackingCodesApi.createTrackingCode, organizationId, destination, spotlightId, logo, engagement_value);
        yield put(actions.getSpotlight(organizationId, spotlightId));
        yield put(actions.createTrackingCodeSuccess());
        yield call(toast.success, 'Tracking code generated!');
    } catch (error) {
        const message = 'Couldn\'t generate Tracking code!';
        yield call(toast.error, message);
        yield put(actions.updateTrackingCodeFailure({ message, error }));
    }
}

export function* watchCreateTrackingCode() {
    yield takeEvery(actions.CREATE_TRACKING_CODE, createTrackingCode);
}

export function* updateTrackingCode(action) {
    const { organizationId, codeId, destination, spotlightId, logo, engagement_value } = action.payload;

    try {
        yield call(trackingCodesApi.updateTrackingCode, organizationId, codeId, destination, spotlightId, logo, engagement_value);
        yield put(actions.getSpotlight(organizationId, spotlightId));
        yield put(actions.updateTrackingCodeSuccess());
    } catch (error) {
        const message = 'Couldn\'t update Tracking code!';
        yield call(toast.error, message);
        yield put(actions.updateTrackingCodeFailure({ message, error }));
    }
}

export function* watchUpdateTrackingCode() {
    yield takeEvery(actions.UPDATE_TRACKING_CODE, updateTrackingCode);
}


export default function* rootSaga() {
    yield all([
        watchGetSpotlights(),
        watchGetSpotlight(),
        watchDeleteSpotlight(),
        watchCreateSpotlight(),
        watchUpdateSpotlight(),
        watchDisableSelectedSpotlights(),
        watchDeleteSpotlights(),
        watchCreateTrackingCode(),
        watchUpdateTrackingCode(),
    ]);
}
