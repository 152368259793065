import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { FaRegFaceFrownOpen as SadFace } from "react-icons/fa6";


import "./ErrorView.scss";

function ErrorView({ title, description, icon, buttonText, onButtonClick, isButtonDisabled }) {
    return (
        <div className="ErrorView">
            {icon}
            <h2>{title}</h2>
            {description && <p>{description}</p>}
            {buttonText && onButtonClick && <Button
                variant="contained"
                color="primary"
                className="button"
                disabled={isButtonDisabled}
                onClick={onButtonClick}>
                {buttonText}
            </Button>}
        </div>
    );
}

ErrorView.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node,
    icon: PropTypes.node,
    buttonText: PropTypes.string,
    onButtonClick: PropTypes.func,
    isButtonDisabled: PropTypes.bool,
};

ErrorView.defaultProps = {
    icon: <SadFace size={72} color="#999"/>
};

export default ErrorView;