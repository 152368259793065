import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import BreadcrumbDropdown from './BreadcrumbDropdown';

const LINKS_MAX_WIDTH = {
    5: '85px',
    6: '38px',
    7: '18px',
    8: '8px',
};

const getLinkMaxWidth = (isEllipsis, itemCount) => {
    if (!isEllipsis || itemCount < 5) {
        return '100px';
    }

    if (isEllipsis && LINKS_MAX_WIDTH[itemCount]) {
        return LINKS_MAX_WIDTH[itemCount]
    }

    return '5px';
}

function BreadcrumbItem(props) {
    const { step, currentOrg, leaf, isEllipsis, itemCount } = props;

    let itemClassName = 'breadcrumbItem';
    if (step.id === currentOrg.id) itemClassName += ' selected';
    if (!step.leaf) itemClassName += ' removePadd';

    const linkStyle = { maxWidth: getLinkMaxWidth(isEllipsis, itemCount) };

    return (
        <Fragment>
            <div className="tail">
                <div className={step.id === currentOrg.id ? "tailUpSelected" : "tailUp"}></div>
                <div className={step.id === currentOrg.id ? "tailDownSelected" : "tailDown"}></div>
            </div>
            <div key={step.id} className={itemClassName}>
                <Tooltip title={step.name} placement="bottom" enterDelay={400} enterNextDelay={400} arrow>
                    {step.id === currentOrg.id ? (
                        <span className="current-org-name">{step.name}</span>
                    ) : (
                        <Link to={step.url} className={step.id === currentOrg.id ? 'whiteLink' : ''}>
                            <div style={linkStyle}>
                                {step.name}
                            </div>
                        </Link>
                    )}
                </Tooltip>
                <BreadcrumbDropdown
                    leaf={leaf}
                    orgList={step.children}
                    isPathLeaf={step.id === currentOrg.id}
                    path={step.path} />
            </div>
            <div className={step.id === currentOrg.id ? "triangleSelected" : "triangle"}></div>
        </Fragment>
    );
}

const BreadcrumbPath = (props) => {
    const { path, currentOrg } = props;
    const clonePath = [...path];

    return (
        clonePath.map((step, index) => {
            return (
                <BreadcrumbItem
                    key={step.id}
                    step={step}
                    currentOrg={currentOrg}
                    leaf={step.leaf}
                    isEllipsis={index > 0 && index < clonePath.length - 1}
                    itemCount={clonePath.length}
                />
            );
        })
    )
};

export default BreadcrumbPath;