import React from "react";
import {CircularProgress} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import PropTypes from "prop-types";

import "./LoadingOverlay.scss";

class LoadingOverlay extends React.Component {
    getClassName() {
        let className = 'LoadingOverlay';
        className += this.props.page ? ' page' : '';
        className += this.props.component ? ' component' : '';
        return className;
    }

    render() {
        const {label, isThereAnError} = this.props;
        return (
            <div className={this.getClassName()}>
                <div className='loader-background'>
                    {isThereAnError ?
                        <CloseRounded color="secondary" />
                        :
                        <CircularProgress className='circle' size={40} thickness={6} />
                    }
                    {label && <p className='label'>{label}</p>}
                </div>
            </div>
        );

    }
}

LoadingOverlay.propTypes = {
    page: PropTypes.bool,
    component: PropTypes.bool,
    label: PropTypes.string,
    isThereAnError: PropTypes.bool
};

export default LoadingOverlay;