import { gql } from '@apollo/client';

const schedulingFragment = gql`
    fragment genericSchedule on GenericScheduling {
        mondays tuesdays wednesdays thursdays fridays saturdays sundays
        validFrom validFromTime validThrough validThroughTime tz
    } `;

const spotlightFragment = gql`
    ${schedulingFragment}
    fragment spotlight on Spotlight {
        id
        title
        active
        properties
        text: textRaw
        metadata: metadataRaw
        muted
        background {
            id
            thumbnail
            type
            url
            metadata
            audio
        }
        created_at
        organization { id }
        organization_id
        is_deleted
        approval_status
        scheduling(from_org_id: $organizationId, include_parents: true, applies_from_now: true)  {
            ...genericSchedule
        }
        org_time_based_display_rules @include(if: $orgUsesTimeBasedDisplayRules) {
            id
            type
            metadata
            organization_id
        }
        is_prohibited(organization_id: $organizationId)
    }
`;

const campaignFragment = gql`
    ${spotlightFragment}
    fragment campaignDetails on Campaign {
        id
        name
        type
        is_single_url
        created_at
        organization_id
        archived
        metadata {
            usages
            campaignHub {
                icon
                cta
            }
            fixedPanel {
                cta
            }
        }
        TrackingCode {
            id
            destination
            tracking_code
            domain
            logo
            engagement_value
            metadata {
                androidUrl
                iosUrl
                smsTelephone
                smsMessage
            }
            tracking_destination(organization_id: $organizationId){
                destination
            }
            tracking_destinations{
                organization_id
                destination
                created_at
                updated_at
            }
        }
        CampaignAnalytics(org_id: $organizationId) {
            total_scans
            assets(org_id: $organizationId) {
                scans
                asset {
                    ... on Spotlight {
                        ...spotlight
                    }
                    ... on PrintAsset {
                        id
                        name
                        PrintAssetTrackingCode
                        created_at
                    }
                    ... on CampaignHubAsset {
                        campaign_id
                        enabled
                    }
                    ... on FixedPanelAsset {
                        campaign_id
                        enabled
                    }
                }
            }
        }
    }
`;

const GET_CAMPAIGNS = gql`
    query get_campaigns($organizationId: Int! $limit: Int) {
        Organization(id: $organizationId) {
            id
            campaigns(limit: $limit) {
                id
            }
        }
    }
`;

const GET_CAMPAIGN = gql`
    ${campaignFragment}
    query get_campaign_details($organizationId: Int! $campaignId: Int! $orgUsesTimeBasedDisplayRules: Boolean!) {
        Organization(id: $organizationId) {
            id
            campaign(id: $campaignId) {
                ...campaignDetails
            }
        }
    }
`;

const UPDATE_CAMPAIGN = gql`
    mutation update_campaign($organizationId: Int! $campaignId: Int! $name: String! $destination: String! $engagement_value: Float $androidUrl: String $iosUrl: String, $smsTelephone: String, $smsMessage: String) {
        orgMutation(organization_id: $organizationId) {
            updateCampaign(campaign_id: $campaignId name: $name destination: $destination engagement_value: $engagement_value tracking_code_metadata: {
                androidUrl: $androidUrl
                iosUrl: $iosUrl
                smsTelephone: $smsTelephone
                smsMessage: $smsMessage
            }) {
                status
                successful
                message
            }
        }
    }
`;

const UPDATE_CAMPAIGN_METADATA = gql`
    mutation update_campaign_metadata($organizationId: Int! $campaignId: Int! $metadata: JSON!) {
        orgMutation(organization_id: $organizationId) {
            updateCampaignMetadata(campaign_id: $campaignId metadata: $metadata) {
                status
                successful
                message
                campaign {
                    id
                    metadata {
                        usages
                        campaignHub {
                            icon
                            cta
                        }
                        fixedPanel {
                            cta
                        }
                    }
                }
            }
        }
    }
`;

const EDIT_TRACKING_DESTINATION = gql`
    mutation upsert_tracking_destination($organizationId: Int!, $destination: String!, $tracking_code_id: Int!) {
        orgMutation(organization_id: $organizationId) {
            upsert_tracking_destination(destination: $destination, tracking_code_id: $tracking_code_id) {
                status
                successful
                message
                TrackingDestination {
                    organization_id
                    tracking_code_id
                    destination
                }
            }
        }
    }
`;

const GET_CAMPAIGNS_BRANDKIT = gql`
    query getCampaigns($organization_id: Int!) {
        Organization(id: $organization_id) {
            brandkitSettings:settings(prefix:"brandkit."){
                key
                value
            }
            id
            campaigns {
                type
                name
                TrackingCode {
                    domain
                    tracking_code
                }
                metadata {
                    usages
                    campaignHub {
                        icon
                        cta
                    }
                }
            }
        }
    }
`;

const ARCHIVE_CAMPAIGN = gql`
    mutation archiveCampaign($organizationId: Int!, $campaign_id: Int!, $archive: Boolean!){
        orgMutation(organization_id: $organizationId){
            archiveCampaign(campaign_id: $campaign_id, archive: $archive){
                status
                successful
                message
            }
        }
    }
`;

const GET_ORG_CHILDREN = gql `query getOrgChildren ($organizationId: Int!) {
    Organization(id:$organizationId) {
        id
        name
        child_organizations(include_descendants: true) {
            id
            name
        }
    }
}`;

const UPDATE_MULTIPLE_URL_DESTINATIONS = gql`
    mutation update_multiple_url_campaign($organizationId: Int! $campaignId: Int! $orgs_destinations: [TrackingDestinationInput]) {
        orgMutation(organization_id: $organizationId) {
            updateCampaignMultipleUrl(campaign_id: $campaignId orgs_destinations: $orgs_destinations) {
                status
                successful
                message
            }
        }
    }
`;

export {
    GET_CAMPAIGN,
    GET_CAMPAIGNS,
    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_METADATA,
    EDIT_TRACKING_DESTINATION,
    GET_CAMPAIGNS_BRANDKIT,
    ARCHIVE_CAMPAIGN,
    GET_ORG_CHILDREN,
    UPDATE_MULTIPLE_URL_DESTINATIONS
};
