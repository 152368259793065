import moment from "moment-timezone";

function containsCurrentDay(schedule, tz) {
    const day = moment.tz(tz).format('dddd').toLowerCase() + "s";
    return schedule[day];
}

function containsCurrentDate(schedule, tz) {
    if (!schedule.validFrom && !schedule.validThrough) {
        return true;
    }

    const validFrom = schedule.validFrom ? schedule.validFrom : "2018-01-01";
    const validThrough = schedule.validThrough ? schedule.validThrough : "2099-01-01";

    const formatDate = 'YYYY-MM-DD';
    const nowDate = moment().tz(tz);
    const beforeDate = moment.tz(validThrough, formatDate, tz);
    const afterDate = moment.tz(validFrom, formatDate, tz);

    return nowDate.isBetween(afterDate, beforeDate, 'day')
        || (nowDate.isSame(afterDate, 'day') || nowDate.isSame(beforeDate, 'day'));
}



function containsCurrentTime(schedule, tz) {
    if (!schedule.validFromTime && !schedule.validThroughTime) {
        return true;
    }

    const timeToMinutes = function (m, overlap) {
        if (typeof m === 'string') {
            const times = m.split(':');
            const minutes = parseInt(times[1], 10) + parseInt(times[0], 10) * 60;
            if(overlap){
                return minutes + 24 * 60;
            }
            return minutes;
        }
        const minutes = m.minutes() + m.hours() * 60;
        if(overlap){
            return minutes + 24 * 60;
        }
        return minutes;
    }

    let validThroughTime = timeToMinutes(schedule.validThroughTime ? schedule.validThroughTime : "23:59:59");
    const validFromTime = timeToMinutes(schedule.validFromTime ? schedule.validFromTime : "00:00:00");
    const isUntilNextDay = validThroughTime <= validFromTime;
    if(isUntilNextDay){
        validThroughTime = timeToMinutes(schedule.validThroughTime ? schedule.validThroughTime : "23:59:59", true);
    }

    const nowTime = timeToMinutes(moment().tz(tz));

    const isCurrentTimeBetweenScheduleTimes = validFromTime <= nowTime && nowTime <= validThroughTime;

    return isCurrentTimeBetweenScheduleTimes;
}

export default function scheduleAppliesToNow(schedule, defaultTz = 'America/Chicago') {
    const tz = schedule.tz ? schedule.tz : defaultTz;
    return containsCurrentDay(schedule, tz) && containsCurrentDate(schedule, tz) && containsCurrentTime(schedule, tz);
}