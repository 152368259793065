const NAMESPACE = 'entities/uiScripts/';

//UI SCRIPTS
export const GET_UI_SCRIPTS = `${NAMESPACE}GET_UI_SCRIPTS`;

export function getUiScripts(organizationId) {
    return {
        type: GET_UI_SCRIPTS,
        payload: {
            organizationId
        }
    };
}

export const GET_UI_SCRIPTS_SUCCESS = `${NAMESPACE}GET_UI_SCRIPTS_SUCCESS`;

export function getUiScriptsSuccess(organizationId, uiScripts, entities) {
    return {
        type: GET_UI_SCRIPTS_SUCCESS,
        payload: {
            organizationId,
            uiScripts,
        },
        entities
    };
}

export const GET_UI_SCRIPTS_FAILURE = `${NAMESPACE}GET_UI_SCRIPTS_FAILURE`;

export function getUiScriptsFailure(organizationId, error) {
    return {
        type: GET_UI_SCRIPTS_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}

//ACCOUNTS SCRIPT
export const GET_ACCOUNTS_SCRIPT = `${NAMESPACE}GET_ACCOUNTS_SCRIPT`;

export function getAccountsScript(organizationId) {
    return {
        type: GET_ACCOUNTS_SCRIPT,
        payload: {
            organizationId
        }
    };
}

export const GET_ACCOUNTS_SCRIPT_SUCCESS = `${NAMESPACE}GET_ACCOUNTS_SCRIPT_SUCCESS`;

export function getAccountsScriptSuccess(organizationId, accountsScript, entities) {
    return {
        type: GET_ACCOUNTS_SCRIPT_SUCCESS,
        payload: {
            organizationId,
            accountsScript,
        },
        entities
    };
}

export const GET_ACCOUNTS_SCRIPT_FAILURE = `${NAMESPACE}GET_ACCOUNTS_SCRIPT_FAILURE`;

export function getAccountsScriptFailure(organizationId, error) {
    return {
        type: GET_ACCOUNTS_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}

//DEVICE SCRIPTS
export const GET_DEVICES_SCRIPT = `${NAMESPACE}GET_DEVICES_SCRIPT`;

export function getDevicesScript(organizationId) {
    return {
        type: GET_DEVICES_SCRIPT,
        payload: {
            organizationId
        }
    };
}

export const GET_DEVICES_SCRIPT_SUCCESS = `${NAMESPACE}GET_DEVICES_SCRIPT_SUCCESS`;

export function getDevicesScriptSuccess(organizationId, devicesScript, entities) {
    return {
        type: GET_DEVICES_SCRIPT_SUCCESS,
        payload: {
            organizationId,
            devicesScript,
        },
        entities
    };
}


export const GET_DEVICES_SCRIPT_FAILURE = `${NAMESPACE}GET_DEVICES_SCRIPT_FAILURE`;

export function getDevicesScriptFailure(organizationId, error) {
    return {
        type: GET_DEVICES_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}

//DEFAULT ORG SCRIPT
export const GET_DEFAULT_ORG_SCRIPT = `${NAMESPACE}GET_DEFAULT_ORG_SCRIPT`;

export function getDefaultOrgScript(organizationId) {
    return {
        type: GET_DEFAULT_ORG_SCRIPT,
        payload: {
            organizationId
        }
    };
}

export const GET_DEFAULT_ORG_SCRIPT_SUCCESS = `${NAMESPACE}GET_DEFAULT_ORG_SCRIPT_SUCCESS`;

export function getDefaultOrgScriptSuccess(organizationId, defaultOrgScript, entities) {
    return {
        type: GET_DEFAULT_ORG_SCRIPT_SUCCESS,
        payload: {
            organizationId,
            defaultOrgScript,
        },
        entities
    };
}

export const GET_DEFAULT_ORG_SCRIPT_FAILURE = `${NAMESPACE}GET_DEFAULT_ORG_SCRIPT_FAILURE`;

export function getDefaultOrgScriptFailure(organizationId, error) {
    return {
        type: GET_DEFAULT_ORG_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}

export const CREATE_SCRIPT = `${NAMESPACE}CREATE_SCRIPT`;

export function createScript(name, sequence, active, isPublic, description, title, organizationId, exit, callback) {
    return {
        type: CREATE_SCRIPT,
        payload: {
            name,
            sequence,
            active,
            isPublic,
            description,
            title,
            organizationId,
            exit,
            callback
        },
    };
}

export const CREATE_SCRIPT_SUCCESS = `${NAMESPACE}CREATE_SCRIPT_SUCCESS`;

export function createScriptSuccess(organizationId, scriptId, entities) {
    return {
        type: CREATE_SCRIPT_SUCCESS,
        payload: {
            organizationId,
            scriptId,
        },
        entities,
    };
}

export const CREATE_SCRIPT_FAILURE = `${NAMESPACE}CREATE_SCRIPT_FAILURE`;

export function createScriptFailure(organizationId, error) {
    return {
        type: CREATE_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}


export const UPDATE_SCRIPT = `${NAMESPACE}UPDATE_SCRIPT`;

export function updateScript(id, title, sequence, description, active, organizationId, exit, callback) {
    return {
        type: UPDATE_SCRIPT,
        payload: {
            id,
            title,
            sequence,
            description,
            active,
            organizationId,
            exit,
            callback
        },
    };
}

export const UPDATE_SCRIPT_SUCCESS = `${NAMESPACE}UPDATE_SCRIPT_SUCCESS`;

export function updateScriptSuccess(organizationId, scriptId, entities) {
    return {
        type: UPDATE_SCRIPT_SUCCESS,
        payload: {
            organizationId,
            scriptId,
        },
        entities,
    };
}

export const UPDATE_SCRIPT_FAILURE = `${NAMESPACE}UPDATE_SCRIPT_FAILURE`;

export function updateScriptFailure(organizationId, error) {
    return {
        type: UPDATE_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}


export const DELETE_SCRIPT = `${NAMESPACE}DELETE_SCRIPT`;

export function deleteScript(id, organizationId, callback) {
    return {
        type: DELETE_SCRIPT,
        payload: {
            id,
            organizationId,
            callback
        },
    };
}

export const DELETE_SCRIPT_SUCCESS = `${NAMESPACE}DELETE_SCRIPT_SUCCESS`;

export function deleteScriptSuccess(organizationId) {
    return {
        type: DELETE_SCRIPT_SUCCESS,
        payload: {
            organizationId,
        }
    };
}

export const DELETE_SCRIPT_FAILURE = `${NAMESPACE}DELETE_SCRIPT_FAILURE`;

export function deleteScriptFailure(organizationId, error) {
    return {
        type: DELETE_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}


export const DUPLICATE_SCRIPT = `${NAMESPACE}DUPLICATE_SCRIPT`;

export function duplicateScript(id, organizationId) {
    return {
        type: DUPLICATE_SCRIPT,
        payload: {
            id,
            organizationId,
        },
    };
}

export const DUPLICATE_SCRIPT_SUCCESS = `${NAMESPACE}DUPLICATE_SCRIPT_SUCCESS`;

export function duplicateScriptSuccess(organizationId, scriptId, entities) {
    return {
        type: DUPLICATE_SCRIPT_SUCCESS,
        payload: {
            organizationId,
            scriptId,
        },
        entities,
    };
}

export const DUPLICATE_SCRIPT_FAILURE = `${NAMESPACE}DUPLICATE_SCRIPT_FAILURE`;

export function duplicateScriptFailure(organizationId, error) {
    return {
        type: DUPLICATE_SCRIPT_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}