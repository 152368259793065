"use strict";

var _jimp = _interopRequireDefault(require("jimp"));
var _index = _interopRequireDefault(require("./core/index"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const png = require('upng-js');
const decodeImageFromBuffer = async buffer => {
  const image = await _jimp.default.read(buffer);
  image.contrast(1);
  image.greyscale();
  image.blur(1);
  const fileBuffer = await image.getBufferAsync(_jimp.default.MIME_PNG);
  const data = png.decode(fileBuffer);
  const result = (0, _index.default)(png.toRGBA8(data), data.width, data.height);
  // TODO: Implement the vertical placeholder detection.
  result.isVertical = true; // For now we consider all the placeholder as vertical.
  result.image = {
    width: data.width,
    height: data.height
  };
  return result;
};
module.exports = {
  decodeImageFromBuffer
};