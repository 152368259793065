import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import TriviaSchedule from "./TriviaSchedule";
import { triviaSchedules as triviaSchedulesApi } from "../../../../api/modules";
import * as actions from "./actions";
import scheduleAppliesToNow from "../../../../helpers/scheduleAppliesToNow";
import { scheduleAppliesToFuture } from "@upshow/sched-utils";
import Organization from '../organizations/Organization';

export function* getSchedules(action) {
    const { organizationId, includePastSchedules = false } = action.payload;

    try {
        const schedules = yield call(triviaSchedulesApi.getTriviaSchedules, organizationId);

        const defaultTz = yield select(state => Organization.getCurrentTimezoneName(state));
        const activeSchedules = includePastSchedules ? schedules : schedules.filter((schedule) => scheduleAppliesToNow(schedule, defaultTz) || scheduleAppliesToFuture(schedule, defaultTz));
        const { result, entities } = TriviaSchedule.parseArray(activeSchedules);
        yield put(actions.getSchedulesSuccess(organizationId, result, entities));
    } catch (error) {
        console.error(error);
        const message = 'Couldn\'t fetch organization schedules';
        yield call(toast.error, message);
        yield put(actions.getSchedulesFailure(organizationId, { message, error }));
    }
}

export function* watchGetSchedules() {
    yield takeEvery(actions.GET_SCHEDULES, getSchedules);
}


export function* createSchedule(action) {
    const { organizationId, schedule } = action.payload;

    try {
        yield call(triviaSchedulesApi.createTriviaSchedule, organizationId, schedule);
        yield put(actions.getSchedules(organizationId));
    } catch (error) {
        const message = 'Couldn\'t subscribe to trivia';
        yield call(toast.error, message);
        yield put(actions.createScheduleFailure(organizationId, { message, error }));
    }
}

export function* watchCreateSchedule() {
    yield takeEvery(actions.CREATE_SCHEDULE, createSchedule);
}


export function* deleteSchedule(action) {
    const { organizationId, scheduleId } = action.payload;

    try {
        yield call(triviaSchedulesApi.deleteTriviaSchedule, organizationId, scheduleId);
        yield put(actions.deleteScheduleSuccess(organizationId, scheduleId));
        yield put(actions.getSchedules(organizationId));
    } catch (error) {
        const message = 'Couldn\'t unsubscribe to trivia';
        yield call(toast.error, message);
        yield put(actions.deleteScheduleFailure(organizationId, scheduleId, { message, error }));
    }
}

export function* watchDeleteSchedule() {
    yield takeEvery(actions.DELETE_SCHEDULE, deleteSchedule);
}


export function* deleteAllSchedules(action) {
    const { organizationId } = action.payload;

    try {
        const schedules = yield select(state => TriviaSchedule.getTriviaSchedulesByOrganization(state, organizationId));
        const schedulesIds = schedules.map( sch => sch.id);
        yield call(triviaSchedulesApi.deleteAllTriviaSchedules, organizationId, schedulesIds);
        yield put(actions.deleteAllSchedulesSuccess(organizationId));
        yield put(actions.getSchedules(organizationId));
    } catch (error) {
        const message = 'Couldn\'t unsubscribe';
        yield call(toast.error, message);
        yield put(actions.deleteAllSchedulesFailure(organizationId, { message, error }));
    }
}

export function* watchDeleteAllSchedules() {
    yield takeEvery(actions.DELETE_ALL_SCHEDULES, deleteAllSchedules);
}


export default function* rootSaga() {
    yield all([
        watchGetSchedules(),
        watchCreateSchedule(),
        watchDeleteSchedule(),
        watchDeleteAllSchedules(),
    ]);
};