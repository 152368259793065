import { graphqlQuery } from "../axios";

export function getJukinFeeds(org_id) {
    return graphqlQuery({
        query: `query ecp_getJukinFeeds($org_id: Int!){
                    MediaChannels {
                        id
                        title
                        description
                        tags
                        feedType
                        rating
                        thumbnail
                        preview_asset_url
                        is_premium

                        org_include_parent_is_subscribed : org_is_subscribed(id: $org_id, include_parents: true)
                        subscribed: org_is_subscribed(id: $org_id, include_parents: false)
                        org_has_access(id: $org_id, include_parents: false)
                        org_has_requested(id: $org_id, include_parents: false)
                        org_include_parent_has_access : org_has_access(id: $org_id, include_parents: true)
                        org_include_parent_has_requested : org_has_requested(id: $org_id, include_parents: true)
                    }
            }`, variables: {org_id}
    })
        .then(response => response?.data?.data?.MediaChannels ?? []);
}
