const NAMESPACE = `entities/plutoSchedules/`;



export const GET_SCHEDULES = `${NAMESPACE}GET_SCHEDULES`;

export function getSchedules(organizationId, includePastSchedules) {
    return {
        type: GET_SCHEDULES,
        payload: {
            organizationId,
            includePastSchedules,
        },
    };
}


export const GET_SCHEDULES_SUCCESS = `${NAMESPACE}GET_SCHEDULES_SUCCESS`;

export function getSchedulesSuccess(organizationId, scheduleIds, entities) {
    return {
        type: GET_SCHEDULES_SUCCESS,
        payload: {
            organizationId,
            scheduleIds,
        },
        entities,
    };
}


export const GET_SCHEDULES_FAILURE = `${NAMESPACE}GET_SCHEDULES_FAILURE`;

export function getSchedulesFailure(organizationId, error) {
    return {
        type: GET_SCHEDULES_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}


export const CREATE_SCHEDULE = `${NAMESPACE}CREATE_SCHEDULE`;

export function createSchedule(organizationId, channelId, schedule) {
    return {
        type: CREATE_SCHEDULE,
        payload: {
            organizationId,
            channelId,
            schedule
        },
    };
}


export const CREATE_SCHEDULE_SUCCESS = `${NAMESPACE}CREATE_SCHEDULE_SUCCESS`;

export function createScheduleSuccess(organizationId, channelId, scheduleId, entities) {
    return {
        type: CREATE_SCHEDULE_SUCCESS,
        payload: {
            organizationId,
            channelId,
            scheduleId,
        },
        entities
    };
}


export const CREATE_SCHEDULE_FAILURE = `${NAMESPACE}CREATE_SCHEDULE_FAILURE`;

export function createScheduleFailure(organizationId, channelId, error) {
    return {
        type: CREATE_SCHEDULE_FAILURE,
        payload: {
            organizationId,
            channelId,
            error,
        },
    };
}


export const DELETE_SCHEDULE = `${NAMESPACE}DELETE_SCHEDULE`;

export function deleteSchedule(organizationId, channelId, scheduleId) {
    return {
        type: DELETE_SCHEDULE,
        payload: {
            organizationId,
            channelId,
            scheduleId,
        },
    };
}


export const DELETE_SCHEDULE_SUCCESS = `${NAMESPACE}DELETE_SCHEDULE_SUCCESS`;

export function deleteScheduleSuccess(organizationId, channelId, scheduleId) {
    return {
        type: DELETE_SCHEDULE_SUCCESS,
        payload: {
            organizationId,
            channelId,
            scheduleId,
        },
    };
}


export const DELETE_SCHEDULE_FAILURE = `${NAMESPACE}DELETE_SCHEDULE_FAILURE`;

export function deleteScheduleFailure(organizationId, channelId, scheduleId, error) {
    return {
        type: DELETE_SCHEDULE_FAILURE,
        payload: {
            organizationId,
            channelId,
            scheduleId,
            error,
        },
    };
}


export const DELETE_ALL_SCHEDULES = `${NAMESPACE}DELETE_ALL_SCHEDULES`;

export function deleteAllSchedules(organizationId, channelId) {
    return {
        type: DELETE_ALL_SCHEDULES,
        payload: {
            organizationId,
            channelId,
        },
    };
}


export const DELETE_ALL_SCHEDULES_SUCCESS = `${NAMESPACE}DELETE_ALL_SCHEDULES_SUCCESS`;

export function deleteAllSchedulesSuccess(organizationId, channelId) {
    return {
        type: DELETE_ALL_SCHEDULES_SUCCESS,
        payload: {
            organizationId,
            channelId,
        },
    };
}


export const DELETE_ALL_SCHEDULES_FAILURE = `${NAMESPACE}DELETE_ALL_SCHEDULES_FAILURE`;

export function deleteAllSchedulesFailure(organizationId, channelId, error) {
    return {
        type: DELETE_ALL_SCHEDULES_FAILURE,
        payload: {
            organizationId,
            channelId,
            error,
        },
    };
}