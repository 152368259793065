import {all, call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {spotlightBackgrounds as spotlightBackgroundsApi} from "../../../../api/modules";
import SpotlightBackground from "./SpotlightBackground";
import * as actions from "./actions";


export function* getSpotlightBackgrounds(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(spotlightBackgroundsApi.getSpotlightBackgrounds, organizationId);
        const {result, entities} = SpotlightBackground.parseArray(response);
        yield put(actions.getSpotlightBackgroundsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch spotlight backgrounds';
        yield call(toast.error, message);
        yield put(actions.getSpotlightBackgroundsFailure(organizationId, {message, error}));
    }
}

export function* watchGetSpotlightBackgrounds() {
    yield takeEvery(actions.GET_SPOTLIGHT_BACKGROUNDS, getSpotlightBackgrounds);
}

export default function* rootSaga() {
    yield all([
        watchGetSpotlightBackgrounds(),
    ]);
};