import Entity from "../Entity";
import Organization from "../organizations/Organization";
import { scheduleisActive } from "@upshow/sched-utils";
import JukinFeed from "../jukinFeeds/JukinFeed";
import * as actions from "./actions";

class JukinSchedule extends Entity {

    constructor() {
        super('jukinSchedules', {
            organization: Organization,
            feed: JukinFeed,
        });
    }

    reducer(state = this.initialState, action) {

        switch (action.type) {

            case actions.DELETE_SCHEDULE: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: true,
                            errorDeleting: null,
                        }
                    },
                };
            }

            case actions.DELETE_SCHEDULE_SUCCESS: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: false,
                            errorDeleting: false,
                        }
                    },
                };
            }

            case actions.DELETE_SCHEDULE_FAILURE: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: false,
                            errorDeleting: false,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES: {
                const {feedId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isDeletingSchedule: true,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES_SUCCESS: {
                const {feedId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES_FAILURE: {
                const {feedId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: error,
                        }
                    },
                };
            }


            default: {
                return super.reducer(state, action);
            }

        }
    }

    getJukinSchedulesByOrganization(state, organizationId, denormalized = true) {
        const ids = Organization.getJukinSchedulesIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getJukinSchedulesByOrganizationAndChannel(state, organizationId, feedId) {
        const schedules = this.getJukinSchedulesByOrganization(state, organizationId);
        return schedules.filter(s => s.feed.id === feedId);
    }

    getActiveJukinFeedsIdsByOrganization(state, organizationId) {
        const schedules = this.getJukinSchedulesByOrganization(state, organizationId);

        const byChannel = schedules.reduce((acc, schedule) => {
            const feedId = schedule.feed.id;
            return {
                ...acc,
                [feedId]: Array.isArray(acc[feedId]) ? [...acc[feedId], schedule] : [schedule],
            };
        }, {});


        const activeChannelsIds = [];

        for (let feedId of Object.keys(byChannel)) {
            const lastSchedule = byChannel[feedId];
            if(lastSchedule.length > 0 && !!lastSchedule.find(sch => sch.type !== "negative")){
                activeChannelsIds.push(feedId);
            }
        }

        return activeChannelsIds;
    }

    getOnScreenJukinFeedsIdsByOrganization(state, organizationId) {
        const schedules = this.getJukinSchedulesByOrganization(state, organizationId);
        const currentIsoDate = (new Date()).toISOString();
        const timezone = Organization.getDefaultTimezoneName(state);
        return schedules.filter( schedule => {
            return scheduleisActive(currentIsoDate, schedule, timezone)
        });
    }

    getActiveJukinFeedsByOrganization(state, organizationId, denormalized = false) {
        const ids = this.getActiveJukinFeedsIdsByOrganization(state, organizationId);
        return JukinFeed.findByIds(state, ids, denormalized);
    }

}

export default new JukinSchedule();