import {toast} from "react-toastify";

export function GenericMutationErrorHandler(error, toastOptions) {
    toast.error(error.toString(), toastOptions);
}

export function GenericMutationCompletedHelper(genericMutationResult, newMessage, toastOptions) {
    const { successful, message } = genericMutationResult;
    if (!successful) {
        toast.error(message);
    } else {
        toast.success(newMessage ? newMessage : message, toastOptions);
    }

    return successful;
}