import { graphqlQuery } from '../axios';
import _get from 'lodash/get';

export async function getScriptSchedules (organizationId) {
    const response = await graphqlQuery({
        query: `query ecp_getScriptSchedules($org_id: Int!, $include_parents: Boolean = false){
        Organization(id: $org_id) {
            schedules_scripts(includeParents: $include_parents){
              id
              organization: public_organization {
                id
                name
              }
              mondays
              tuesdays
              wednesdays
              thursdays
              fridays
              saturdays
              sundays
              validFrom
              validThrough
              validFromTime
              validThroughTime
              org_distance
              tz
              lock
              script{
                id
                name
                title
                sequence
              }
              display_rules {
                id
                filter
                value
              }
            }
          }
        }`, variables: { org_id: organizationId, include_parents: true }
    });

    const schedules = _get(response, 'data.data.Organization.schedules_scripts', []);
    const error = _get(response, 'data.error', null);

    return { schedules, error };
}

export function createScriptSchedule (organizationId, scriptId, schedule) {
    const payload = {
        mondays: schedule.mondays,
        tuesdays: schedule.tuesdays,
        wednesdays: schedule.wednesdays,
        thursdays: schedule.thursdays,
        fridays: schedule.fridays,
        saturdays: schedule.saturdays,
        sundays: schedule.sundays,
        validFrom: schedule.validFrom,
        validThrough: schedule.validThrough,
        validFromTime: schedule.validFromTime,
        validThroughTime: schedule.validThroughTime,
        lock: schedule.lock,
        tz: schedule.tz,
    };

    if (Array.isArray(schedule.organizationIds)) {
        const queries = schedule.organizationIds.reduce((acc, org_id) => {
            return acc + `${'org_' + org_id}: orgMutation (organization_id: ${org_id}){
                    create_script_schedule(id: $id, input: $input) {
                      successful
                      message
                      schedule {
                        id
                    }
                    }
                 }`;
        }, '');
        return graphqlQuery({
            query: `mutation create_script_schedule($id: Int!, $input: ScriptScheduleInput!) {
            ${queries}
          }`, variables: { id: scriptId, input: payload }
        }).then(response => {
            const results = Object.values(response.data.data);
            const allSuccess = results.every(r => r.create_script_schedule.successful);
            return {
                successful: allSuccess,
                message: allSuccess ? 'Playlist script schedules created on each organization' : 'Couldn\'t schedule playlist',
                data: results
            };
        });
    }

    return graphqlQuery({
        query: `mutation create_script_schedule($org_id: Int!, $id: Int!, $input: ScriptScheduleInput!) {
                orgMutation (organization_id: $org_id){
                  create_script_schedule(id: $id, input: $input) {
                    successful
                    message
                    schedule {
                      id
                    }
                  }
                }
      }`, variables: { org_id: organizationId, id: scriptId, input: payload }
    }).then(response => _get(response, 'data.data.orgMutation.create_script_schedule', {}));
}

export function deleteScriptSchedule (organizationId, scheduleId) {
    return graphqlQuery({
        query: `mutation deleteScriptSchedule($org_id: Int!, $id: Int!) {
              orgMutation(organization_id: $org_id) {
                delete_script_schedule(id: $id) {
                  successful
                  message
                }
              }
    }`, variables: { org_id: organizationId, id: scheduleId }
    }).then(response => _get(response, 'data.data.orgMutation.delete_script_schedule', {}));
}

export function deleteAllScriptSchedules (organizationId, channel_id) {
    return graphqlQuery({
        query: `mutation deleteAllScriptSchedules($org_id: Int!, $id: Int!) {
                orgMutation(organization_id: $org_id) {
                  clear_script_schedules(id: $id) {
                    successful
                    message
                  }
                }
      }`, variables: { org_id: organizationId, id: channel_id }
    }).then(response => _get(response, 'data.data.orgMutation.clear_script_schedules', {}));
}
