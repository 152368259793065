import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuButton, MenuItem, MenuList, SubMenuItem } from '@upshow/react-menu-list';

import IconArrowDown from '@mui/icons-material/ArrowDropDownOutlined';
import IconArrowUp from '@mui/icons-material/ArrowDropUpOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';

import { spotlightTemplate } from '../../../helpers/normalizeSpotlight';

import './ButtonCreateNew.scss';

const ButtonCreateNew = (props) => {
    const { isSideBarOpen, baseUrl, orgHasCampaigns, orgHasBOHSpotlights, organizationId, brandkit, defaultBackgrounds, getDefaultSpotlightAssets } = props;
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const horizontalSpotlight = spotlightTemplate(organizationId, defaultBackgrounds, { type: 'image', brandkit });
    const verticalSpotlight = spotlightTemplate(organizationId, defaultBackgrounds, { type: 'image', brandkit, isVertical: true });
    const bohSpotlight = spotlightTemplate(organizationId, defaultBackgrounds, { type: 'boh', brandkit });
    const menuId = isOpen ? "create-menu" : undefined;

    const handleGoToHorizontalAnnouncement = () => {
        history.push({
            pathname: `${baseUrl}/spotlights/draft?toolbox=IMAGE`,
            state: { spotlight: horizontalSpotlight, type: 'image', initialToolbox: 'BACKGROUND' }
        });
    }

    const handleGoToVerticalAnnouncement = () => {
        history.push({
            pathname: `${baseUrl}/spotlights/draft?toolbox=IMAGE`,
            state: { spotlight: verticalSpotlight, type: 'image', initialToolbox: 'BACKGROUND' }
        });
    }

    const handleGoToEmployeeScreen = () => {
        history.push({
            pathname: `${baseUrl}/spotlights/draft`,
            search: '?toolbox=TEMPLATES',
            state: { spotlight: bohSpotlight, type: 'boh', initialToolbox: 'TEMPLATES' }
        });
    }

    const handleGoToCreateCampaign = () => {
        history.push({ pathname: `${baseUrl}/campaigns/new` });
    }

    useEffect(() => {
        getDefaultSpotlightAssets(organizationId);
    }, [getDefaultSpotlightAssets, organizationId]);

    return (
        <div className='ButtonCreateNew'>
            <MenuButton
                className={`button ${!isSideBarOpen && 'round-button'} ${isOpen && 'button-menu-open'}`}
                menuZIndex={1200}
                onWillOpen={() => setIsOpen(true)}
                onWillClose={() => setIsOpen(false)}
                menu={
                    <MenuList id={menuId} classMenu='CreateNewMenuList'>
                        {orgHasCampaigns && <MenuItem className="MenuItem" onItemChosen={handleGoToCreateCampaign}>QR Campaign</MenuItem>}
                        <SubMenuItem
                            className='SubMenuItem new-announcement-dropdown'
                            menuZIndex={1200}
                            menu={
                                <div className="lift-menu">
                                    <MenuList classMenu="CreateNewMenuList">
                                        <MenuItem className="MenuItem new-horizontal-announcement-dropdown" onItemChosen={handleGoToHorizontalAnnouncement}>
                                            Horizontal Screen
                                        </MenuItem>
                                        <MenuItem className="MenuItem new-vertical-announcement-dropdown" onItemChosen={handleGoToVerticalAnnouncement}>
                                            Vertical Screen
                                        </MenuItem>
                                    </MenuList>
                                </div>
                            }>
                            Spotlight <KeyboardArrowRightIcon className="arrow-right"/>
                        </SubMenuItem>
                        {orgHasBOHSpotlights && <MenuItem className="MenuItem" onItemChosen={handleGoToEmployeeScreen}>Back of House</MenuItem>}
                    </MenuList>
                }
            >
                {isSideBarOpen
                    ? <>
                        {isOpen
                            ? <IconArrowUp fontSize="small" style={{ marginRight: '8px' }}/>
                            : <IconArrowDown fontSize="small" style={{ marginRight: '8px' }}/>
                        }
                        Create New
                    </>
                    : <AddIcon/>
                }
            </MenuButton>
        </div>
    );
};

export default ButtonCreateNew;