const NAMESPACE = 'entities/organizationsSettings';

export const SET_DEFAULT_MIX = `${NAMESPACE}SET_DEFAULT_MIX`;

export function setDefaultMix (organizationId, script_id, script_name, callback) {
    return {
        type: SET_DEFAULT_MIX,
        payload: {
            organizationId,
            script_id,
            script_name,
            callback
        }
    };
}


export const SET_DEFAULT_MIX_SUCCESS = `${NAMESPACE}SET_DEFAULT_MIX_SUCCESS`;

export function setDefaultMixSuccess(organizationId, orgSettingsId, entities) {
    return {
        type: SET_DEFAULT_MIX_SUCCESS,
        payload: {
            organizationId,
            orgSettingsId
        },
        entities,
    };
}


export const SET_DEFAULT_MIX_FAILURE = `${NAMESPACE}SET_DEFAULT_MIX_FAILURE`;

export function setDefaultMixFailure(organizationId, error) {
    return {
        type: SET_DEFAULT_MIX_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}

export const OPEN_MIXES_SIDE_PANEL = `${NAMESPACE}OPEN_MIXES_SIDE_PANEL`;

export function openMixesSidePanel(mixTileSelected) {
    return {
        type: OPEN_MIXES_SIDE_PANEL,
        payload: {
            mixTileSelected,
        }
    };
}

export const CLOSE_MIXES_SIDE_PANEL = `${NAMESPACE}CLOSE_MIXES_SIDE_PANEL`;

export function closeMixesSidePanel() {
    return {
        type: CLOSE_MIXES_SIDE_PANEL,
        payload: {}
    };
}

export const SET_LOCK_MIX = `${NAMESPACE}SET_LOCK_MIX`;

export function setMixLocking(organizationId, script_id, locking, callback) {
    return {
        type: SET_LOCK_MIX,
        payload: {
            organizationId,
            script_id,
            locking,
            callback
        }
    };
}

export const SET_LOCK_MIX_SUCCESS = `${NAMESPACE}SET_LOCK_MIX_SUCCESS`;

export function setMixLockingSuccess(organizationId) {
    return {
        type: SET_LOCK_MIX_SUCCESS,
        payload: {
            organizationId,
        }
    };
}

export const SET_LOCK_MIX_FAILURE = `${NAMESPACE}SET_LOCK_MIX_FAILURE`;

export function setMixLockingFailure(organizationId, error) {
    return {
        type: SET_LOCK_MIX_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}