import React from 'react'
import * as Sentry from "@sentry/react";

import { Button } from "@mui/material";

import EmptyState from '../../components/EmptyState';
import SideBar from '../SideBar/SideBar';
import TopBar from '../TopBar/TopBar';
import { getExperience } from '../SideBar/experienceEntries';
import { Router } from 'react-router-dom';
import history from '../../services/HistoryService';

const ErrorBoundary = ({ children }) => {
    const reloadButton = (
        <Button
            key="reload-button"
            variant="contained"
            color="primary"
            className="button err-boundary-reload-btn"
            onClick={() => window.location.href = '/'}>
            Try Again
        </Button>
    );

    return (
        <Sentry.ErrorBoundary fallback={() => (
            <Router history={history}>
                <div className='App'>
                    <div className='Home'>
                        <SideBar hasPlugins={false} canManageDevices={false} isCanary={false} experience={getExperience()} />
                        <div className='page-container'>
                            <TopBar onErrorScreen />
                            <EmptyState
                                type="ERROR"
                                actions={[reloadButton]}
                            />
                        </div>
                    </div>
                </div>
            </Router>
        )}>
            {children}
        </Sentry.ErrorBoundary>
    )
};

export default ErrorBoundary;