import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

import './SideBar.scss';

import IconImageCollections from '@mui/icons-material/CollectionsOutlined';
import IconHardwareTv from '@mui/icons-material/TvOutlined';
import IconLiveTv from '@mui/icons-material/LiveTvOutlined';
import IconHelp from '@mui/icons-material/HelpOutline';
import IconNewSpotlight from '@mui/icons-material/BrushOutlined';
import IconApps from '@mui/icons-material/AppsOutlined';
import IconDevices from '@mui/icons-material/RouterOutlined';
import IconFeedback from '@mui/icons-material/FeedbackOutlined';
import LiveTv from '@mui/icons-material/SubscriptionsOutlined';
import LocalPlayOutlinedIcon from '@mui/icons-material/LocalPlayOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import IconAssets from '../../assets/assets_icon';
import ApprovalsIcon from '@mui/icons-material/VerifiedOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import STEverpassLogo from '../../assets/sunday-ticket/STEverpass_white-min.png';
import PeacockLogo from '../../assets/sunday-ticket/Peacock.svg';
import TNFLogo from '../../assets/thursday-night-football/TNF_Logo.svg';
import NetflixLogo from '../../assets/netflix/short-logo.png';

import packageInfo from '../../../package.json';

const { version } = packageInfo;

import ButtonCreateNew from './ButtonCreateNew';

const iconColor = { root: 'iconColor' };
const iconText = { primary: 'iconText' };
const disabledClass = { disabled: 'disabled' };

function MenuItem ({
    isOpen = false,
    indicator = 0,
    invisible = true,
    Icon,
    text,
    multirow,
    to = '',
    title,
    disabled = false,
    component = NavLink,
    target, href,
    isActive = undefined
}) {
    return (
        <ListItem
            button
            target={target}
            href={href}
            className={`menu-item ${multirow ? 'two-rows' : 'one-row'}`}
            component={component}
            title={title ? title : text}
            to={to}
            disabled={disabled}
            classes={disabledClass}
            isActive={isActive}
        >
            <ListItemIcon className={`list-item-icon ${isOpen ? 'open' : ''}`}><Box>
                <Badge sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: '#DE425B'
                    }
                }}
                       invisible={invisible}
                       overlap="circular"
                       color="primary"
                       variant="dot"
                       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Icon size={24} classes={iconColor}/>
                </Badge> </Box> </ListItemIcon>
            {isOpen && <ListItemText classes={iconText}> <span>{text}</span> {!!indicator && <span className="indicator">{indicator}</span>}</ListItemText>}
        </ListItem>
    );
}

class SideBar extends React.Component {

    state = {
        open: true,
        orgHas: this.props.orgHasFeature,
        userHasOrgPermission: this.props.userHasOrgPermission,
    };

    handleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    componentDidUpdate (prevProps) {
        if (prevProps.isFetchingCurrentOrganization && !this.props.isFetchingCurrentOrganization) {
            this.setState({ orgHas: this.props.orgHasFeature });
            this.setState({ userHasOrgPermission: this.props.userHasOrgPermission });

        }
    }

    render () {
        const { baseUrl, hasPlugins, spotlightsPendingReview, experience, hasAccessTypeform } = this.props;
        const orgHas = typeof this.state.orgHas === 'function' ? this.state.orgHas : () => false;
        const userHasOrgPermission = typeof this.state.userHasOrgPermission === 'function' ? this.state.userHasOrgPermission : () => false;

        const isOpen = this.state.open;

        const HeaderIconComponent = isOpen ? MenuOpenIcon : MenuIcon;
        const HeaderLogoComponent = experience.logo;
        const HeaderBadgeComponent = experience.badge;

        const headerLogoRender = HeaderLogoComponent ? <HeaderLogoComponent className={`experience-logo open`} fill={'var(--sidebar-header-text-color)'}/> : null;
        const headerBadgeRender = HeaderBadgeComponent ? <HeaderBadgeComponent className={'experience-badge'} fill={'var(--sidebar-header-text-color)'}/> : null;

        return (
            <Drawer
                variant="permanent"
                classes={{ docked: `SideBar ${!this.state.open ? 'SideBarClose' : ''} ${experience.className}`, paper: `drawerPaper ${!this.state.open ? 'drawerPaperClose' : ''}` }}
                open={this.state.open}
                transitionDuration={{ enter: 1000, exit: 1000 }}
            >
                <div className="DrawerHeaderContainer">
                    <div className="DrawerHeader">
                        <Link to={baseUrl ? `${baseUrl}/home` : ''}>
                            {isOpen ? headerLogoRender : headerBadgeRender}
                        </Link>
                        <IconButton className="header-toggle-btn" onClick={this.handleDrawer}>
                            <HeaderIconComponent className="header-toggle-icon"/>
                        </IconButton>
                    </div>
                </div>
                <div className="DrawerContentContainer">
                    <div className="DrawerContent">
                        <List className="DrawerList">
                            {/* If state's broken and there's no baseUrl show static anchor so user can access home */}
                            {orgHas('SPOTLIGHT_APPROVAL') && userHasOrgPermission('spotlight_approver') &&
                                <MenuItem invisible={!spotlightsPendingReview || isOpen}
                                          isOpen={isOpen} Icon={ApprovalsIcon}
                                          indicator={spotlightsPendingReview}
                                          text="Approvals"
                                          title="Approvals"
                                          to={`${baseUrl}/approvals`}/>}

                            {(orgHas('LIVE_EVENTS')) &&
                                <ListSubheader className="subHeader" disableSticky={true}>{this.state.open ? 'LIVE SPORTS' : 'LIVE'}</ListSubheader>
                            }
                            {orgHas('LIVE_EVENTS') && orgHas('SUNDAY_TICKET') && <>
                                <ListItem component={NavLink} to={`${baseUrl}/sunday-ticket`} className="sunday-ticket-item">
                                    <ListItemIcon>
                                        <img id="sunday-ticket" src={STEverpassLogo} className="everpass-logo" alt="Sunday Ticket"/>
                                    </ListItemIcon>
                                </ListItem>
                            </>}
                            {orgHas('LIVE_EVENTS') && orgHas('PEACOCK') && <>
                                <ListItem component={NavLink} to={`${baseUrl}/peacock`} className="sunday-ticket-item">
                                    <ListItemIcon>
                                        <img id="peacock" src={PeacockLogo} className="everpass-logo" alt="Peacock Sports Pass"/>
                                    </ListItemIcon>
                                </ListItem>
                            </>}
                            {orgHas('LIVE_EVENTS') && <>
                                <ListItem component={NavLink} to={`${baseUrl}/thursday-night-football`} className="sunday-ticket-item">
                                    <ListItemIcon style={{ height: '100%' }}>
                                        <img id="tnf" src={TNFLogo} className="everpass-logo" alt="Thursday Night Football"/>
                                    </ListItemIcon>
                                </ListItem>
                            </>}
                            {orgHas('LIVE_EVENTS') && orgHas('NETFLIX') && <>
                                <ListItem component={NavLink} to={`${baseUrl}/netflix`} className='sunday-ticket-item'>
                                    <ListItemIcon>
                                        <img id="netflix" src={NetflixLogo} className="everpass-logo" alt="Netflix" />
                                    </ListItemIcon>
                                </ListItem>
                            </>}
                            {orgHas('LIVE_EVENTS') && <MenuItem isOpen={isOpen} Icon={FolderSpecialOutlinedIcon} text="Marketing Hub" component="a" target="_blank" href="https://info.upshow.tv/everpass-marketing-hub"/>}

                            {orgHas('SPOTLIGHT') && orgHas('CREATE_FROM_SIDEBAR') && <>
                                <ListSubheader className="subHeader" disableSticky={true}>Create</ListSubheader>
                                <ButtonCreateNew
                                    isSideBarOpen={isOpen}
                                    baseUrl={baseUrl}
                                    orgHasCampaigns={userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS')}
                                    orgHasBOHSpotlights={userHasOrgPermission('spotlights') && orgHas('BOH_SPOTLIGHTS')}
                                />
                            </>}

                            {userHasOrgPermission('social') && <ListSubheader className="subHeader" disableSticky={true}>Engage</ListSubheader>}
                            {userHasOrgPermission('playlists') && orgHas('MIXES') &&
                                <MenuItem isOpen={isOpen} Icon={LiveTv} text="Playlists" title="Playlists"
                                          to={orgHas('SMART_PLAYLISTS') && window.location.pathname !== `${baseUrl}/playlists`
                                              ? `${baseUrl}/smart_playlists`
                                              : `${baseUrl}/playlists`}/>}
                            {userHasOrgPermission('social') && orgHas('SOCIAL') && <MenuItem isOpen={isOpen} Icon={IconImageCollections} multirow text="Direct Share" title="Direct Share" to={`${baseUrl}/content`}/>}
                            {userHasOrgPermission('entertainment') && orgHas('ENTERTAINMENT') &&
                                <MenuItem
                                    isOpen={isOpen}
                                    Icon={IconLiveTv}
                                    text="Entertainment"
                                    title="Entertainment"
                                    multirow
                                    to={`${baseUrl}/entertainment`}
                                    isActive={(match, location) => {
                                        return location.pathname === `${baseUrl}/entertainment` && location.search !== '?category=peacock';
                                    }}>
                                </MenuItem>
                            }
                            {userHasOrgPermission('audio') && <MenuItem isOpen={isOpen} Icon={LibraryMusicOutlinedIcon} text="Audio" to={`${baseUrl}/audio`}/>}
                            {userHasOrgPermission('plugins') && hasPlugins && <MenuItem isOpen={isOpen} Icon={IconApps} text="Plugins" title="Plugins" to={`${baseUrl}/plugins`}/>}

                            {userHasOrgPermission('spotlights') && orgHas('SPOTLIGHT') && <>
                                <ListSubheader className="subHeader" disableSticky={true}>Promote</ListSubheader>
                                <MenuItem
                                    isOpen={isOpen}
                                    Icon={IconHardwareTv}
                                    text={<>Promotion<br/> Manager</>}
                                    multirow
                                    title="Promotion Manager"
                                    to={`${baseUrl}/promotions`}
                                />
                                <MenuItem
                                    multirow
                                    isOpen={isOpen}
                                    Icon={IconNewSpotlight}
                                    text={<>Spotlight<br/> Creator</>}
                                    title="Spotlight Creator"
                                    to={`${baseUrl}/spotlights/new`}
                                />
                                {orgHas('SPOTLIGHT') && <MenuItem isOpen={isOpen} Icon={IconAssets} text="Library" title="Library" multirow to={`${baseUrl}/library`}/>}
                            </>}
                            {userHasOrgPermission('boh_contests') && (orgHas('BOH_CONTESTS')) && orgHas('SPOTLIGHT') && <MenuItem isOpen={isOpen} Icon={LocalPlayOutlinedIcon} text="CONTESTS" title="Contests Manager" to={`${baseUrl}/contests`}/>}

                            <ListSubheader className="subHeader" disableSticky={true}>Learn</ListSubheader>
                            {userHasOrgPermission('insights') && orgHas('SPOTLIGHT') && <MenuItem isOpen={isOpen} Icon={AutoGraphIcon} text="New Insights" to={`${baseUrl}/new_insights`}/>}
                            {userHasOrgPermission('campaigns') && orgHas('SPOTLIGHT') && orgHas('CAMPAIGNS') && hasAccessTypeform && <MenuItem isOpen={isOpen} Icon={IconFeedback} text="Surveys" to={`${baseUrl}/surveys`}/>}
                            {userHasOrgPermission('devices') && this.props.canManageDevices && orgHas('DEVICES') && <MenuItem isOpen={isOpen} Icon={IconDevices} text={<>Device<br/> Manager</>} multirow title="Devices" to={`${baseUrl}/devices`}/>}
                            <MenuItem isOpen={isOpen} Icon={IconHelp} text="Help Center" component="a" target="_blank" href="http://help.upshow.tv"/>

                        </List>
                        <div className="manager-version">v{version}</div>
                    </div>
                </div>
            </Drawer>
        );
    }
}

SideBar.propTypes = {
    hasPlugins: PropTypes.bool,
    canManageDevices: PropTypes.bool,
    orgHasFeature: PropTypes.func,
    userHasOrgPermission: PropTypes.func,
    isFetchingCurrentOrganization: PropTypes.bool,
    isFetchingUser: PropTypes.bool,
    baseUrl: PropTypes.string,
    spotlightsPendingReview: PropTypes.number,
    hasAccessTypeform: PropTypes.bool,
    experience: PropTypes.shape({
        key: PropTypes.string,
        className: PropTypes.string,
        badge: PropTypes.func,
        logo: PropTypes.func,
    }),
    isCanary: PropTypes.bool
};

export default SideBar;
