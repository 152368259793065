const NAMESPACE = `entities/upshowNowSchedules/`;



export const GET_SCHEDULES = `${NAMESPACE}GET_SCHEDULES`;

export function getSchedules(organizationId, includePastSchedules) {
    return {
        type: GET_SCHEDULES,
        payload: {
            organizationId,
            includePastSchedules
        },
    };
}


export const GET_SCHEDULES_SUCCESS = `${NAMESPACE}GET_SCHEDULES_SUCCESS`;

export function getSchedulesSuccess(organizationId, scheduleIds, entities) {
    return {
        type: GET_SCHEDULES_SUCCESS,
        payload: {
            organizationId,
            scheduleIds,
        },
        entities,
    };
}


export const GET_SCHEDULES_FAILURE = `${NAMESPACE}GET_SCHEDULES_FAILURE`;

export function getSchedulesFailure(organizationId, error) {
    return {
        type: GET_SCHEDULES_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}