import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import Organization from '../../redux/modules/entities/organizations/Organization';
import { canManageDevices, hasPlugins, isFetchingUserInfo, isCanary } from '../../redux/modules/session/selectors';
import { getExperience } from './experienceEntries';
import SideBar from './SideBar';

const GET_SPOTLIGHTS_PENDING_REVIEW_COUNT = gql`
    query getSpotlightsPendingReviewCount($organization_id: Int!) {
        Organization(id: $organization_id) {
            id
            spotlightsPendingReviewCount
        }
    }
`;

const mapStateToProps = (state) => {
    const currentOrgId = Organization.getCurrentOrganizationId(state);

    return {
        baseUrl: Organization.getCurrentOrganizationUrl(state),
        hasPlugins: hasPlugins(state),
        canManageDevices: canManageDevices(state),
        hasAccessTypeform: Organization.getTypeformAccess(state, currentOrgId),
        isFetchingCurrentOrganization: Organization.isFetchingCurrentOrganization(state),
        isFetchingUser: isFetchingUserInfo(state),
        orgHasFeature: Organization.getFeatureAccesor(state),
        userHasOrgPermission: Organization.getPermissionAccesor(state),
        orgId: Organization.getCurrentOrganizationId(state),
        experienceKey: Organization.getCurrentOrganizationExperience(state),
        isCanary: isCanary(state)
    };
};

const mapDispatchToProps = () => ({});

const useExperience = (key) => {
    const [experience, setExperience] = useState(() => getExperience(key));

    useEffect(() => key && setExperience(getExperience(key)), [key]);

    return experience;
};

const SideBarContainer = (props) => {
    const { orgId, experienceKey, orgHasFeature } = props;

    const experience = useExperience(experienceKey);

    const [getSpotlightsPendingReviewCount, { data }] = useLazyQuery(GET_SPOTLIGHTS_PENDING_REVIEW_COUNT, {
        variables: { organization_id: orgId },
        pollInterval: 2 * 60 * 1000,
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (orgId && orgHasFeature('SPOTLIGHT_APPROVAL')) getSpotlightsPendingReviewCount();
    }, [orgId, getSpotlightsPendingReviewCount, orgHasFeature]);

    const spotlightsPendingReview = data?.Organization.spotlightsPendingReviewCount ?? 0;

    return <SideBar {...props} experience={experience} spotlightsPendingReview={spotlightsPendingReview}/>;

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarContainer));
