import { all, call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { spotlightAssets as spotlightAssetsApi } from '../../../../api/modules';
import SpotlightAsset from './SpotlightAsset';
import * as actions from './actions';

export function * getSpotlightAssets (action) {
    const { organizationId } = action.payload;

    try {
        const response = yield call(spotlightAssetsApi.getSpotlightAssets, organizationId);
        const { result, entities } = SpotlightAsset.parseArray(response);
        yield put(actions.getSpotlightAssetsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch spotlight assets';
        yield call(toast.error, message);
        yield put(actions.getSpotlightAssetsFailure(organizationId, { message, error }));
    }
}

export function * watchGetSpotlightAssets () {
    yield takeEvery(actions.GET_SPOTLIGHT_ASSETS, getSpotlightAssets);
}

export function * getSpotlightAsset (action) {
    const { organizationId, spotlightAssetId } = action.payload;

    try {
        const response = yield call(spotlightAssetsApi.getSpotlightAsset, organizationId, spotlightAssetId);
        const { result, entities } = SpotlightAsset.parse(response);
        yield put(actions.getSpotlightAssetSuccess(organizationId, result, entities));
    } catch (error) {
        console.dir(error);
        yield call(toast.error, 'Couldn\'t fetch spotlight asset');
        yield put(actions.getSpotlightAssetFailure(organizationId, spotlightAssetId, { message, error }));
    }
}

export function * watchGetSpotlightAsset () {
    yield takeEvery(actions.GET_SPOTLIGHT_ASSET, getSpotlightAsset);
}

export function * createSpotlightAsset (action) {

    const { organizationId, spotlightAsset, noPreprocess, doNotRedirectCallback } = action.payload;

    try {
        const response = yield call(spotlightAssetsApi.createSpotlightAsset, organizationId, spotlightAsset, noPreprocess);
        const { result, entities } = SpotlightAsset.parse(response);
        if (typeof doNotRedirectCallback !== 'function') {
            yield put(actions.createSpotlightAssetSuccess(organizationId, result, entities));
            yield call(toast.success, 'Spotlight asset created!');
        } else {
            doNotRedirectCallback({
                id: response.id,
                type: response.type,
                url: response.url,
                media_object: response.media_object,
                metadata: response.metadata,
                assets: entities.spotlightAssets
            });
            yield put(actions.createSpotlightAssetSuccess(organizationId, result, entities));
        }
    } catch (error) {
        console.error(error);
        const message = 'Couldn\'t create spotlight asset';
        yield call(toast.error, message);
        yield put(actions.createSpotlightAssetFailure(organizationId, { message, error }));
    }
}

export function * watchCreateSpotlightAsset () {
    yield takeEvery(actions.CREATE_SPOTLIGHT_ASSET, createSpotlightAsset);
}

export default function * rootSaga () {
    yield all([
        watchGetSpotlightAssets(),
        watchGetSpotlightAsset(),
        watchCreateSpotlightAsset(),
    ]);
}
