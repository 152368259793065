import { graphqlQuery } from "../axios";
import _get from "lodash/get";

export function getUpshowNowSchedules(organizationId) {
    return graphqlQuery({
        query: `query ecp_getUpshowNowSchedules($org_id: Int!, $include_parents: Boolean = false){
              Organization(id: $org_id) {
                upshownow_schedules(includeParents: $include_parents) {
                  id
                  mondays
                  tuesdays
                  wednesdays
                  thursdays
                  fridays
                  saturdays
                  sundays
                  validFrom
                  validThrough
                  validFromTime
                  validThroughTime
                  position
                  behavior
                  tz
                  organization: public_organization {
                    id
                    name
                  }
                  type
                  channel {
                    id
                    title
                    thumbnail
                  }
                }
              }
          }`, variables: {org_id: organizationId, include_parents: true}
    }).then(response => _get(response, 'data.data.Organization.upshownow_schedules', []));
}