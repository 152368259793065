import Entity from "../Entity";
import Organization from "../organizations/Organization";
import * as schedulesActions from "../jukinSchedules/actions";

class JukinFeed extends Entity {

    constructor() {
        super('jukinFeeds');
    }


    reducer(state = this.initialState, action) {
        switch (action.type) {

            case schedulesActions.CREATE_SCHEDULE: {
                const {feedId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isCreatingSchedule: true,
                            errorCreatingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.CREATE_SCHEDULE_SUCCESS: {
                const {feedId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isCreatingSchedule: false,
                            errorCreatingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.CREATE_SCHEDULE_FAILURE: {
                const {feedId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isCreatingSchedule: false,
                            errorCreatingSchedule: error,
                        }
                    },
                };
            }

            case schedulesActions.DELETE_SCHEDULE: {
                const {feedId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isDeletingSchedule: true,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.DELETE_SCHEDULE_SUCCESS: {
                const {feedId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.DELETE_SCHEDULE_FAILURE: {
                const {feedId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [feedId]: {
                            ...state.metadataById[feedId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: error,
                        }
                    },
                };
            }

            default: {
                return super.reducer(state, action);
            }

        }
    }

    isCreatingSchedule(state, id) {
        return !!this.getMetadata(state, id).isCreatingSchedule;
    }

    isDeletingSchedule(state, id) {
        return !!this.getMetadata(state, id).isDeletingSchedule;
    }

    getJukinFeedsByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getJukinFeedsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

}

export default new JukinFeed();