import Entity from "../Entity";
import Schedule from "../schedules/Schedule";
import Organization from "../organizations/Organization";
import * as actions from "./actions";
import { scheduleAppliesToFuture, scheduleisActive } from "@upshow/sched-utils";
import { displayRulesMeetTags } from '@upshow/validators';

import _get from 'lodash/get';

class Spotlight extends Entity {

    constructor() {
        super('spotlights', {
            scheduling: [Schedule],
            organization: Organization,
        });

        this.displayFilterTypes = {
            ALL: {value: 'ALL', label: 'All'},
            CURRENT_ORGANIZATION_ONLY: {value: 'CURRENT_ORGANIZATION_ONLY', label: 'Current Organization Only'},
            PARENT_ORGANIZATIONS_ONLY: {value: 'PARENT_ORGANIZATIONS_ONLY', label: 'Parent Organizations Only'},
        };

        this.initialState = {
            ...this.initialState,
            displayFilter: this.displayFilterTypes.ALL.value,
        }
    }

    reducer(state = this.initialState, action) {
        switch (action.type) {

            case actions.SET_DISPLAY_FILTER: {
                const {displayFilter} = action.payload;
                return {
                    ...state,
                    displayFilter,
                };
            }

            case actions.GET_SPOTLIGHT: {
                const {spotlightId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isFetching: true,
                            error: null,
                        }
                    },
                };
            }

            case actions.GET_SPOTLIGHT_SUCCESS: {
                const {spotlightId} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    metadataById: {
                        ...newState.metadataById,
                        [spotlightId]: {
                            ...newState.metadataById[spotlightId],
                            isFetching: false,
                            error: null,
                        }
                    },
                };
            }

            case actions.GET_SPOTLIGHT_FAILURE: {
                const {spotlightId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isFetching: false,
                            error,
                        }
                    },
                };
            }

            case actions.DELETE_SPOTLIGHT: {
                const {spotlightId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isDeleting: true,
                            errorDeleting: null,
                        }
                    },
                };
            }

            case actions.DELETE_SPOTLIGHT_SUCCESS: {
                const {spotlightId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isDeleting: false,
                            errorDeleting: null,
                        }
                    },
                };
            }

            case actions.DELETE_SPOTLIGHT_FAILURE: {
                const {spotlightId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isDeleting: false,
                            errorDeleting: error,
                        }
                    },
                };
            }

            case actions.UPDATE_SPOTLIGHT: {
                const {spotlightId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isUpdating: true,
                            errorUpdating: null,
                        }
                    },
                };
            }

            case actions.UPDATE_SPOTLIGHT_SUCCESS: {
                const {spotlightId} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    metadataById: {
                        ...newState.metadataById,
                        [spotlightId]: {
                            ...newState.metadataById[spotlightId],
                            isUpdating: false,
                            errorUpdating: null,
                        }
                    }
                };
            }

            case actions.UPDATE_SPOTLIGHT_FAILURE: {
                const {spotlightId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isUpdating: false,
                            errorUpdating: error,
                        }
                    },
                };
            }

            case actions.DUPLICATE_SPOTLIGHT: {
                const {spotlightId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isDuplicatingSpotlight: true,
                            errorDuplicatingSpotlight: null,
                        }
                    },
                };
            }

            case actions.DUPLICATE_SPOTLIGHT_SUCCESS: {
                const {spotlightId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isDuplicatingSpotlight: false,
                            errorDuplicatingSpotlight: null,
                        }
                    },
                };
            }

            case actions.DUPLICATE_SPOTLIGHT_FAILURE: {
                const {spotlightId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightId]: {
                            ...state.metadataById[spotlightId],
                            isDuplicatingSpotlight: false,
                            errorDuplicatingSpotlight: error,
                        }
                    },
                };
            }

            case actions.SELECT_SPOTLIGHT: {
                const {spotlightsId} = action.payload;
                const selectedSpotlights = _get(state, 'selectedSpotlights', []).concat([spotlightsId]);
                return {
                    ...state,
                    selectedSpotlights,
                };
            }

            case actions.DESELECT_SPOTLIGHT: {
                const {spotlightsId} = action.payload;
                const selectedSpotlights = _get(state, 'selectedSpotlights', []);
                return {
                    ...state,
                    selectedSpotlights: selectedSpotlights.filter(spot => spot !== spotlightsId),
                };
            }

            case actions.CLEAR_SELECTED_SPOTLIGHTS: {
                return {
                    ...state,
                    selectedSpotlights: [],
                };
            }

            case actions.DISABLE_SELECTED_SPOTLIGHTS: {
                const {spotlightsIds} = action.payload;

                let spotlights = spotlightsIds.map(id => {
                    return {
                        ...state.metadataById[id],
                        isUpdating: true,
                        errorUpdating: null,
                    }
                })
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        ...spotlights
                    },
                };

            }

            case actions.DISABLE_SELECTED_SPOTLIGHTS_SUCCESS: {
                const {spotlightsIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                let spotlights = spotlightsIds.map(id => {
                    return {
                        ...newState.metadataById[id],
                        isUpdating: false,
                        errorUpdating: null,
                    }
                })
                return {
                    ...newState,
                    metadataById: {
                        ...newState.metadataById,
                        ...spotlights
                    },
                };
            }

            case actions.DISABLE_SELECTED_SPOTLIGHTS_FAILURE: {
                const {spotlightsIds, error} = action.payload;

                let spotlights = spotlightsIds.map(id => {
                    return {
                        ...state.metadataById[id],
                        isUpdating: false,
                        errorUpdating: error,
                    }
                })
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        ...spotlights
                    },
                };
            }

            case actions.DELETE_SELECTED_SPOTLIGHTS: {
                const {spotlightsIds} = action.payload;

                let spotlights = spotlightsIds.map(id => {
                    return {
                        ...state.metadataById[id],
                        isDeleting: true,
                        errorDeleting: null,
                    }
                })

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        ...spotlights
                    },
                };

            }

            case actions.DELETE_SELECTED_SPOTLIGHTS_SUCCESS: {
                const {spotlightsIds} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                let spotlights = spotlightsIds.map(id => {
                    return {
                        ...newState.metadataById[id],
                        isDeleting: false,
                        errorDeleting: null,
                    }
                });

                return {
                    ...newState,
                    metadataById: {
                        ...newState.metadataById,
                        ...spotlights
                    },
                };
            }

            case actions.DELETE_SELECTED_SPOTLIGHTS_FAILURE: {
                const {spotlightsIds, error} = action.payload;

                let spotlights = spotlightsIds.map(id => {
                    return {
                        ...state.metadataById[id],
                        isDeleting: false,
                        errorUpdating: error,
                    }
                });

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        ...spotlights
                    },
                };
            }

            case actions.UPDATE_TRACKING_CODE: {
                return {
                    ...state,
                    metadata: {
                        ...state.metadata,
                        isUpdatingTrackingCode: true,
                        errorUpdatingTrackingCode: null,
                    }
                };
            }

            case actions.UPDATE_TRACKING_CODE_SUCCESS: {
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    metadata: {
                        ...state.metadata,
                        isUpdatingTrackingCode: false,
                        errorUpdatingTrackingCode: null,
                    }
                };
            }

            case actions.UPDATE_TRACKING_CODE_FAILURE: {
                const {error} = action.payload;
                return {
                    ...state,
                    metadata: {
                        ...state.metadata,
                        isUpdatingTrackingCode: false,
                        errorUpdatingTrackingCode: error,
                    }
                };
            }

            case actions.CREATE_TRACKING_CODE: {
                return {
                    ...state,
                    metadata: {
                        ...state.metadata,
                        isUpdatingTrackingCode: true,
                        errorUpdatingTrackingCode: null,
                    }
                };
            }

            case actions.CREATE_TRACKING_CODE_SUCCESS: {
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    metadata: {
                        ...state.metadata,
                        isUpdatingTrackingCode: false,
                        errorUpdatingTrackingCode: null,
                    }
                };
            }

            case actions.CREATE_TRACKING_CODE_FAILURE: {
                const {error} = action.payload;
                return {
                    ...state,
                    metadata: {
                        ...state.metadata,
                        isUpdatingTrackingCode: false,
                        errorUpdatingTrackingCode: error,
                    }
                };
            }

            default: {
                return super.reducer(state, action);
            }

        }
    }

    getSelectedSpotlights(state) {
        const selectedSpotlights = _get(this.getState(state), 'selectedSpotlights', []);
        return selectedSpotlights;
    }

    isSpotlightSelected(state, id) {
        const selectedSpotlights = _get(this.getState(state), 'selectedSpotlights', []);
        return !!selectedSpotlights.find(spotlightId => spotlightId === id);
    }

    getSpotlightsByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getSpotlightsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getEnabledSpotlightsByOrganization(state, organizationId, org_tags, denormalized = false) {
        const spotlights = this.getSpotlightsByOrganization(state, organizationId, denormalized) || [];
        return spotlights.filter(spot => spot.active && displayRulesMeetTags(spot.display_rules ?? [], org_tags));
    }

    getDisabledSpotlightsByOrganization(state, organizationId, org_tags, denormalized = false) {
        const spotlights = this.getSpotlightsByOrganization(state, organizationId, denormalized) || [];
        return spotlights.filter(spot => !spot.active || !displayRulesMeetTags(spot.display_rules ?? [], org_tags));
    }

    getActiveSpotlightsByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getActiveSpotlightsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getFilteredSpotlightsByOrganization(state, organizationId, denormalized = false) {
        const spotlights = this.getSpotlightsByOrganization(state, organizationId, denormalized);
        const displayFilter = this.getDisplayFilter(state);
        let result = [];

        switch (displayFilter) {

            case this.displayFilterTypes.ALL.value: {
                result = spotlights;
                break;
            }

            case this.displayFilterTypes.CURRENT_ORGANIZATION_ONLY.value: {
                result = spotlights.filter(s => s.organization === organizationId);
                break;
            }

            case this.displayFilterTypes.PARENT_ORGANIZATIONS_ONLY.value: {
                result = spotlights.filter(s => s.organization !== organizationId);
                break;
            }

            default: {
                result = spotlights;
                break;
            }

        }

        return result;
    }

    isPersistingOrder(state) {
        return this.getState(state).isPersistingOrder;
    }

    errorPersistingOrder(state) {
        return this.getState(state).errorPersistingOrder;
    }

    getFilteredSpotlightsIdsByOrganization(state, organizationId) {
        return this.getFilteredSpotlightsByOrganization(state, organizationId).map(s => s.id);
    }

    getDisplayFilter(state) {
        return this.getState(state).displayFilter;
    }

    isDeleting(state, id) {
        return !!this.getMetadata(state, id).isDeleting;
    }

    isUpdating(state, id) {
        return !!this.getMetadata(state, id).isUpdating;
    }

    errorUpdating(state, id) {
        return !!this.getMetadata(state, id).errorUpdating;
    }

    isFetching(state, id) {
        return !!this.getMetadata(state, id).isFetching;
    }

    errorFetching(state, id) {
        return this.getMetadata(state, id).errorFetching;
    }

    isCreatingSchedule(state, id) {
        return !!this.getMetadata(state, id).isCreatingSchedule;
    }

    isDuplicatingSpotlight(state, id) {
        return !!this.getMetadata(state, id).isDuplicatingSpotlight;
    }

    isDeletingSchedule(state, id) {
        return !!this.getMetadata(state, id).isDeletingSchedule;
    }

    isShownRightNow(state, id) {
        const currentOrgId = Organization.getCurrentOrganizationId(state);
        const schedules = Schedule.getActiveSchedulesByOrganizationAndSpotlight(state, currentOrgId, id);

        const defaultTz = Organization.getCurrentTimezoneName(state);
        const currentIsoDate = (new Date()).toISOString()
        return schedules.some((schedule) =>
            scheduleisActive(currentIsoDate, schedule, defaultTz));

    }

    wouldBeShown(state, id) {
        const currentOrgId = Organization.getCurrentOrganizationId(state);
        const schedules = Schedule.getActiveSchedulesByOrganizationAndSpotlight(state, currentOrgId, id);

        const defaultTz = Organization.getCurrentTimezoneName(state);

        return schedules.reduce((isShown, schedule) => {
            return scheduleAppliesToFuture(schedule, defaultTz) ? true : isShown;
        }, false);
    }

    getInheritedSchedules(state, spotlightId, denormalized = false) {
        const spotlight = this.findById(state, spotlightId);

        const schedules = spotlight ? Schedule.findByIds(state, spotlight.scheduling, denormalized) : [];

        return schedules.filter(s => s.org_distance < 0);
    }

    getOwnSchedules(state, spotlightId, denormalized = false) {
        const spotlight = this.findById(state, spotlightId);
        const schedules = spotlight ? Schedule.findByIds(state, spotlight.scheduling, denormalized) : [];

        return schedules.filter(s => s.org_distance === 0);
    }

    areInheritedSchedulesBlocked(state, spotlightId) {
        const schedules = this.getOwnSchedules(state, spotlightId);
        return schedules.find(s => s.type === 'negative') !== undefined;
    }

    isUpdatingTrackingCode(state) {
        return !!this.getGeneralMetadata(state).isUpdatingTrackingCode;
    }

    errorUpdatingTrackingCode(state) {
        return this.getGeneralMetadata(state).errorUpdatingTrackingCode;
    }
}

export default new Spotlight();