import { graphqlQuery } from '../axios';
import _get from 'lodash/get';

export const spotlightBackgroundFragment = `
    fragment backgroundFields on SpotlightBackground {
        id
        type
        url
        thumbnail
        small_thumbnail
        metadata
        media_object { id transcoderStatus }
        title
        organization_id
        global
        listed
        orientation
        tags { id tag }
        created_by_id
    }
`;

export function getSpotlightBackgrounds (organizationId) {
    return graphqlQuery({
        query: `query getSpotlightBackgrounds ($org_id: Int! $include_parents: Boolean ) {
            Organization(id: $org_id) { id
                spotlight_backgrounds(includeParents: $include_parents) { ...backgroundFields }
            }
            global_spotlight_backgrounds {
                ...backgroundFields
            }
        }

        ${spotlightBackgroundFragment}
        `,
        variables: { org_id: organizationId, include_parents: true }
    })
        .then(response => {
            const backgrounds = _get(response, 'data.data.Organization.spotlight_backgrounds', []);
            const global_backgrounds = _get(response, 'data.data.global_spotlight_backgrounds', []);
            return backgrounds.concat(global_backgrounds);
        });
}
