import {all, call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {upshowNowChannels as upshowNowChannelsApi} from "../../../../api/modules";
import UpshowNowChannel from "./UpshowNowChannel";
import * as actions from "./actions";


export function* getUpshowNowChannels(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(upshowNowChannelsApi.getUpshowNowChannels, organizationId);
        const {result, entities} = UpshowNowChannel.parseArray(response);
        yield put(actions.getUpshowNowChannelsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch Upshow Now feed';
        yield call(toast.error, message);
        yield put(actions.getUpshowNowChannelsFailure(organizationId, {message, error}));
    }
}

export function* watchGetUpshowNowChannels() {
    yield takeEvery(actions.GET_UPSHOW_NOW_CHANNELS, getUpshowNowChannels);
}

export default function* rootSaga() {
    yield all([
        watchGetUpshowNowChannels(),
    ]);
};