export function getBlockedSchedules(orderedSchedules) {
    const filtered = orderedSchedules.filter(s => s.org_distance !== null);
    const reversed = filtered.slice().reverse();
    const index = reversed.findIndex(s =>  s.type === 'negative' );
    return index !== -1 && reversed.slice(index).length > 1 ? reversed.slice(index).reverse() : [];
}

export function getAppliedSchedules(orderedSchedules) {
    const filtered = orderedSchedules.filter(s => s.org_distance !== null);
    const reversed = filtered.slice().reverse();

    const index = reversed.findIndex(s => s.type === 'negative');
    return index !== -1 ? reversed.slice(0, index).reverse() : filtered;
}

export function getAppliedSchedulesWD(orderedSchedules) {
    const reversed = orderedSchedules.slice().reverse();

    const index = reversed.findIndex(s => s.type === 'negative');
    return index !== -1 ? reversed.slice(0, index).reverse() : orderedSchedules;
}

export function getOnlyNegative(schedules){
    return schedules.filter(s => s.type === 'negative');
}

export function getOnlyRegular(schedules){
    return schedules.filter(s => s.type === 'regular');
}