import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3AACE2',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#DE425B',
        },
        accent: {
            main: '#21435F',
        },
        action: {
            hover: 'rgba(58, 172, 226, 0.1)',
            selected: 'rgba(58, 172, 226, 0.35)',
        },
        buttonAction: {
            main: '#144560'
        },
        white: grey,
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Cabin, sans-serif',

        allVariants: {
            color: 'rgba(33, 68, 94, 1)',
        }
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: '"Cabin", sans-serif',
                    fontSize: '12px',
                    fontWeight: 600,
                    textAlign: 'center',
                    color: 'rgba(33, 68, 94, 0.6)',
                    width: 'auto',
                    minWidth: '30px',
                    '&:hover': {
                        opacity: 1,
                        color: '#3AACE2',
                        boxShadow: 'inset 0 -1px #3AACE2'
                    },
                    '&.Mui-selected': {
                        color: 'rgba(33, 68, 94, 1)',
                        '&:hover': {
                            color: '#3AACE2'
                        }
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiDialog-paper': {
                        minWidth: '440px',
                        width: 'auto',
                        maxWidth: '560px'
                    },
                    '& .MuiDialogTitle-root': {
                        fontWeight: '700',
                        fontSize: '28px',
                        lineHeight: '34px',
                        marginLeft: '42px',
                        marginRight: '42px',
                        marginTop: '42px',
                        marginBottom: 0,
                        padding: 0,

                        '& .title-close': {
                            alignSelf: 'flex-end',
                            position: 'absolute',
                            top: '12px',
                            right: '12px'
                        }
                    },
                    '& .MuiDialogContent-root': {
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginLeft: '42px',
                        marginRight: '42px',
                        marginTop: '32px',
                        padding: 0
                    },
                    '& .MuiDialogContentText-root': {
                        marginTop: 0,
                        color: 'var(--dark-slate-blue)'
                    },
                    '& .MuiDialogActions-root': {
                        justifyContent: 'space-between',
                        padding: 0,
                        marginTop: '32px',
                        marginBottom: '50px',
                        marginRight: '42px',
                        marginLeft: '42px',
                    },
                    '& .MuiButton-root': {
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '19px',
                        textTransform: 'none',
                        borderRadius: '30px',
                        boxShadow: 'none',
                        padding: '15px 32px',
                        marginLeft: '0 !important',

                        '& #text': {
                            margin: '0px 10px'
                        },

                        '&.Mui-disabled': {
                            backgroundColor: 'var(--light-gray-2)',
                            color: 'var(--gray)',
                            border: '2px solid var(--light-gray-2)'
                        },
                    },
                    '& .MuiButton-outlinedPrimary': {
                        border: '3px solid var(--light-cyan)',
                        color: 'var(--dark-slate-blue)',

                        '&:hover': {
                            border: '3px solid var(--anchor-text-color)',
                            background: 'var(--light-sky-blue)',
                            boxShadow: 'none'
                        }
                    },
                    '& .MuiButton-containedPrimary': {
                        color: '#FFFFFF',
                        background: 'var(--anchor-text-color)',
                        border: '3px solid var(--anchor-text-color)',

                        '&:hover': {
                            background: 'var(--light-cyan)',
                            border: '3px solid var(--light-cyan)',
                            boxShadow: 'none'
                        }
                    },
                    '& .MuiButton-containedSecondary': {
                        color: '#FFFFFF',
                        background: 'var(--text-color-red)',
                        border: '3px solid var(--text-color-red)',

                        '&:hover': {
                            background: 'var(--notification-light-red)',
                            border: '3px solid var(--notification-light-red)',
                            boxShadow: 'none'
                        }
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&$focused': {
                        color: '#00A7F5'
                    },
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:after': {
                        borderBottomColor: '#00A7F5',
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#21445e',
                    maxWidth: 200,
                    fontSize: '12px',
                    fontFamily: 'Cabin',
                    fontWeight: 400,
                    lineHeight: '18px',
                    borderRadius: '6px',
                    padding: '4px 8px',
                    textAlign: 'left',
                    boxShadow: '0 0 10px 0 rgba(33, 68, 94, 0.2)'
                },
                arrow: {
                    color: '#21445e'
                },
                tooltipPlacementTop: {
                    '@media only screen and (min-width: 1441px)': {
                        '&.customTopClass': {
                            margin: '8px 14px',
                        },
                    },
                },
                tooltipPlacementBottom: {
                    '@media only screen and (min-width: 1275px)': {
                        '&.fixBigWidth': {
                            margin: '8px 14px',
                        },
                    },
                },
                popper: {
                    borderColor: '#00A7F5',
                    '&.customPopperClass': {
                        top: '10px',
                        left: '15px'
                    }
                },
            }
        },
    },
    overrides: {
        MuiTab: {
            root: {
                fontFamily: 'Cabin',
                fontSize: '12px',
                fontWeight: 600,
                textAlign: 'center',
                color: '21445E',
            }
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#00A7F5'
                },
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#00A7F5',
                }
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: '#21445e',
                maxWidth: 200,
                fontSize: '12px',
                fontFamily: 'Cabin',
                fontWeight: 400,
                lineHeight: '18px',
                borderRadius: '6px',
                padding: '4px 8px',
                textAlign: 'left',
                boxShadow: '0 0 10px 0 rgba(33, 68, 94, 0.2)'
            },
            arrow: {
                color: '#21445e'
            },
            tooltipPlacementTop: {
                '@media only screen and (min-width: 1441px)': {
                    '&.customTopClass': {
                        margin: '8px 14px',
                    },
                },
            },
            tooltipPlacementBottom: {
                '@media only screen and (min-width: 1275px)': {
                    '&.fixBigWidth': {
                        margin: '8px 14px',
                    },
                },
            },
            popper: {
                borderColor: '#00A7F5',
                '&.customPopperClass': {
                    top: '10px',
                    left: '15px'
                }
            },
        },
    },
});

export { theme };