import axios from 'axios';
import { toast } from 'react-toastify';
import config from './config';
import interceptors from './interceptors';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '@upshow/auth';
import { ApolloClient, from, gql, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import packageInfo from '../../../package.json';
const { version } = packageInfo;

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        if (graphQLErrors[0].message === 'Context creation failed: User is not Manager') {
            toast.error('This user does not have access permissions.');
            setTimeout(() => window.oktaAuth.signOut(), 5000);
        } else if (graphQLErrors[0].message === 'Context creation failed: User is Remote') {
            window.location.href = process.env.REACT_APP_REMOTE_CONTROL;
        }
    }
});

const link = new BatchHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 20 // Wait no more than 20ms after first batched operation
});

const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getToken();
    return {
        headers: {
            ...headers,
            'Authorization': `Bearer ${accessToken}`,
            'X-App-Version': version
        }
    };
});

const appLink = from([
    errorLink, authLink.concat(link),
]);

const client = new ApolloClient({
    link: appLink,
    cache: new InMemoryCache({
        possibleTypes: {
            GenericScheduling: [
                'Scheduling',
                'ScriptSchedule',
                'UpshowNowScheduling',
                'ApplicationSchedule',
                'PlutoTvSchedule',
                'TriviaSchedule',
                'MediaChannelScheduling'
            ],
            EntertainmentFeed: [
                'UpshowNowChannel',
                'Application',
                'PlutoTvChannel',
                'LiveEvent',
                'MediaChannel',
                'UpshowGame'
            ]
        }
    })
});

// Create axios instance
const axiosClient = axios.create(config);

// Attach request interceptors
for (let requestInterceptor of interceptors.request) {
    axiosClient.interceptors.request.use(requestInterceptor.onSuccess, requestInterceptor.onError);
}

// Attach response interceptors
for (let responseInterceptor of interceptors.response) {
    axiosClient.interceptors.response.use(responseInterceptor.onSuccess, responseInterceptor.onError);
}

function handleClientResponse (response) {
    return { data: JSON.parse(JSON.stringify(response)) }; // expecting an axios response instead of apollo
}

function graphqlQuery (args) {
    const query = gql`${args.query}`;
    const variables = args.variables;
    const operation = query.definitions[0].operation;

    if (operation === 'mutation') {
        return client.mutate({ variables, mutation: query }).then(handleClientResponse);
    } else {
        return client.query({ variables, query, fetchPolicy: 'network-only' }).then(handleClientResponse);
    }
}

function analyticsQuery (path) {
    return axiosClient.get(process.env.REACT_APP_ANALYTICS_API + path)
        .then(res => res.data);
}

export { graphqlQuery, analyticsQuery, client };

export default graphqlQuery;