import { gql } from "@apollo/client";

export const GENERIC_SCHEDULE = gql`fragment GenericSchedule on GenericScheduling { id mondays tuesdays wednesdays thursdays fridays saturdays sundays validFromTime validThroughTime validFrom validThrough tz }`;

export const SPOTLIGHT_SCHEDULE = gql`
    ${GENERIC_SCHEDULE}
    fragment SpotlightSchedule on Scheduling {
        id
        ...GenericSchedule
        position
        organization { id name }
        type
        organization_id
        org_spotlight_id
        behavior
        play_full_video
    }`;


export const MINIMUM_SPOTLIGHT_TO_RENDER = gql`
    fragment SpotlightInfo on Spotlight {
        id
        title
        active
        background {
            id
            type
            url
            metadata
            media_object {id transcoderStatus durationMillis}
        }
        properties
        campaign_id
        TrackingCode {
            id
        }
        organization_id
        text: textRaw
        metadata: metadataRaw
        is_deleted 
        created_at
        approval_status
        is_prohibited(organization_id: $organization_id)
        is_only_playlist
        blocked_by_orgs(org_id: $organization_id) {
            id
        }
    }`;