import {all, call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {jukinFeeds as jukinFeedsApi} from "../../../../api/modules";
import JukinFeed from "./JukinFeed";
import * as actions from "./actions";


export function* getJukinFeeds(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(jukinFeedsApi.getJukinFeeds, organizationId);
        const {result, entities} = JukinFeed.parseArray(response);
        yield put(actions.getJukinFeedsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch Viral Video Channels';
        yield call(toast.error, message);
        yield put(actions.getJukinFeedsFailure(organizationId, {message, error}));
    }
}

export function* watchGetJukinFeeds() {
    yield takeEvery(actions.GET_JUKIN_FEEDS, getJukinFeeds);
}

export default function* rootSaga() {
    yield all([
        watchGetJukinFeeds()
    ]);
}