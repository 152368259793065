import { graphqlQuery } from '../axios';
import moment from 'moment';
import _get from 'lodash/get'

export function getContent(organizationId, limit = 200) {
    return graphqlQuery({
        query: `query getContent($org_id: Int!, $limit: Int = 200){
                Organization(id: $org_id) { id
                    ElasticContentEdge(first: $limit){
                        edges{
                            id
                            url
                            type
                            thumbnail
                            network
                            permalink
                            isExpired
                            postedAt
                            user {
                                id
                                userName
                                network
                                profilePicture
                                profileUrl
                            }
                        }
                    }
                }
        }`, variables: {
            org_id: organizationId,
            limit: limit
        }
    })
        .then(response => {
            const content = response?.data?.data?.Organization?.ElasticContentEdge?.edges ?? [];

            return content
                // No expired content first
                .sort((left, right) => {
                    if (left.isExpired !== right.isExpired) {
                        return left.isExpired-right.isExpired;
                    }

                    return moment.utc(right.postedAt).diff(moment.utc(left.postedAt));
                });
        });
}

export function banContent(organizationId, contentId) {
    return banContents(organizationId, [contentId]);
}

export function banContents(organizationId, contentIds) {
    return graphqlQuery({
        query: `mutation banContents($organizationId: Int!, $contentIds: [Int!]!){
            ban_contents_from_organization(organization_id: $organizationId content_ids: $contentIds)
        }`, variables: {organizationId, contentIds}
    })
        .then(response => _get(response, 'data.data.ban_contents_from_organization', false));
}
