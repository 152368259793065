import Entity from "../Entity";
import Organization from "../organizations/Organization";

class SpotlightBackground extends Entity {

    constructor() {
        super('spotlightBackgrounds');
    }

    getSpotlightBackgroundsByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getSpotlightBackgroundsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

}

export default new SpotlightBackground();