"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _helpers = require("./helpers");
var _locator = require("./locator");
// import { decode } from "./decoder";
function QRDecoder(image, width, height) {
  const binarized = (0, _helpers.loadBinarized)(image, width, height);
  const locations = (0, _locator.locate)(binarized);
  // const data = decode(binarized);
  return {
    locations
  };
}
var _default = exports.default = QRDecoder;