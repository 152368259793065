
function getState (state) {
    return state.session;
}

export function getMessages (state) {
    return getState(state).messages;
}

export function hasRole (state, role) {
    const { userInfo } = getState(state);
    const roles = !!userInfo && !!userInfo.roles ? userInfo.roles : [];
    const result = roles.find(element => element === role);
    return !!result;
}

export function hasRoles (state, roles) {
    return roles.every(r => hasRole(state, r));
}

export function isCanary (state) {
    return hasRole(state, 'ROLE_CANARY');
}

export function hasPlugins (state) {
    return hasRole(state, 'ROLE_CANARY') || hasRole(state, 'ROLE_PLUGINS');
}

export function canManageDevices (state) {
    return ['ROLE_CANARY', 'ROLE_DEVICE_MANAGER', 'ROLE_BACKEND_ADMIN'].some(r => hasRole(state, r));
}

export function canSetDefaultMix (state) {
    return hasRole(state, 'ROLE_CAN_SETUP_MIXES');
}

export function getUsername (state) {
    const userInfo = getState(state).userInfo;
    return userInfo ? userInfo.username : null;
}

export function getUserInfo (state) {
    const userInfo = getState(state).userInfo;
    return userInfo ? userInfo : null;
}

export function isFetchingUserInfo (state) {
    const isGettingUserInfo = getState(state).isGettingUserInfo;
    return !!isGettingUserInfo;
}

export function getUserSettings (state) {
    const userInfo = getState(state).userInfo;
    return userInfo ? userInfo.settings : null;
}

export function isOrganizationTreeModalOpen (state) {
    return getState(state).isOrganizationTreeModalOpen;
}

export function getCanvaInstance (state) {
    return getState(state).canvaInstance;
}