import {all, call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {plutoChannels as plutoChannelsApi} from "../../../../api/modules";
import PlutoChannel from "./PlutoChannel";
import * as actions from "./actions";


export function* getPlutoChannels(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(plutoChannelsApi.getPlutoChannels, organizationId);
        const {result, entities} = PlutoChannel.parseArray(response);
        yield put(actions.getPlutoChannelsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch Pluto Channels';
        yield call(toast.error, message);
        yield put(actions.getPlutoChannelsFailure(organizationId, {message, error}));
    }
}

export function* watchGetPlutoChannels() {
    yield takeEvery(actions.GET_PLUTO_CHANNELS, getPlutoChannels);
}

export default function* rootSaga() {
    yield all([
        watchGetPlutoChannels(),
    ]);
};