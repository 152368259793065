class DialogService {

    constructor() {
        this.dialog = null;
    }

    open(title, content, actions = [{title: 'Ok'}], notAutoClose = false, showBackground = false, fullWidth = false, blockClose = false, specificWidth = undefined) {
        this._checkDialogRef();
        this.dialog.open(title, content, actions, notAutoClose, showBackground, fullWidth, blockClose, specificWidth);
    }

    open_limited(title, content, actions = [{title: 'Ok'}], specificWidth = undefined) {
        this._checkDialogRef();
        this.dialog.open(title, content, actions, false, false, false, false, specificWidth);
    }

    update(title, content, actions) {
        this._checkDialogRef();
        this.dialog.open(title, content, actions);
    }

    close() {
            this._checkDialogRef();
            this.dialog.close();
    }

    _checkDialogRef(){
        if(this.dialog === null) {
            throw new Error('You should assign a ref to DialogService.dialog before using it');
        }
    }

}

export default new DialogService();