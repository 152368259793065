import React from 'react';

function AssetsIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"  className="iconColor">
            <g id="Create-Spotlights" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Spotlight---Create-spotlight-6.2" transform="translate(-15.000000, -448.000000)" fillRule="nonzero">
                    <g id="Menu---Spotlight-Manager">
                        <g id="icon/hardware/SpotlightManager-SkyBlue-Copy" transform="translate(18.000000, 448.000000)">
                            <path className="assets-icon" d="M5.61594208,4.05303021 L5.61594208,2.49999998 C5.61594208,2.0397627 5.98903813,1.66666667 6.44927542,1.66666667 L9.56521749,1.66666667 C10.0254548,1.66666667 10.3985508,2.0397627 10.3985508,2.49999998 L10.3985508,4.6032321 C10.5029524,4.48548674 10.6418257,4.39594972 10.8051529,4.35201253 L14.3614619,3.40286757 C14.805512,3.284355 15.261681,3.54772323 15.3810726,3.99153777 L18.7045823,16.3460141 C18.8241401,16.7904511 18.5607747,17.24766 18.1163381,17.3672195 L14.560029,18.3163644 C14.1159789,18.434877 13.65981,18.1715088 13.5404183,17.7276942 L10.3985508,6.04843599 L10.3985508,17.5378784 C10.3985508,17.9981157 10.0254548,18.3712117 9.56521749,18.3712117 L6.44927542,18.3712117 C6.44322301,18.3712117 6.43718567,18.3711472 6.43116405,18.3710188 C6.42513934,18.3711473 6.41909889,18.3712119 6.41304336,18.3712119 L2.49999998,18.3712119 C2.0397627,18.3712119 1.66666667,17.9981158 1.66666667,17.5378786 L1.66666667,4.88636354 C1.66666667,4.42612625 2.0397627,4.05303021 2.49999998,4.05303021 L5.61594208,4.05303021 Z M3.33333333,5.71969688 L3.33333333,16.7045452 L5.57971021,16.7045452 L5.57971021,5.71969688 L3.33333333,5.71969688 Z M14.9338135,16.4916007 L16.8782233,15.972657 L13.9876773,5.22763132 L12.0432674,5.74657507 L14.9338135,16.4916007 Z M7.28260875,3.33333333 L7.28260875,16.704545 L8.73188406,16.704545 L8.73188406,3.33333333 L7.28260875,3.33333333 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default AssetsIcon;
