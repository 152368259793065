const NAMESPACE = `entities/schedulingRules/`;


export const GET_SCHEDULES = `${NAMESPACE}GET_SCHEDULES`;

export function getSchedules(organizationId) {
    return {
        type: GET_SCHEDULES,
        payload: {
            organizationId,
        },
    };
}

export const GET_SCHEDULES_SUCCESS = `${NAMESPACE}GET_SCHEDULES_SUCCESS`;

export function getSchedulesSuccess(organizationId, scheduleIds, spotlightIds, entities) {
    return {
        type: GET_SCHEDULES_SUCCESS,
        payload: {
            organizationId,
            scheduleIds,
            spotlightIds,
        },
        entities,
    };
}


export const GET_SCHEDULES_FAILURE = `${NAMESPACE}GET_SCHEDULES_FAILURE`;

export function getSchedulesFailure(organizationId, error) {
    return {
        type: GET_SCHEDULES_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}