import Entity from "../Entity";
import Organization from '../organizations/Organization';

class UiScripts extends Entity {

    constructor() {
        super('uiScripts');
    }

    getUiScripts(state, organizationId) {
        const ids = Organization.getUiScriptsById(state, organizationId);
        return this.findByIds(state, ids, true);
    }

    getAccountScripts(state, organizationId) {
        const ids = Organization.getAccountsScriptById(state, organizationId);
        return this.findByIds(state, ids, true);
    }

    getDeviceScripts(state, organizationId) {
        const ids = Organization.getDevicesScriptById(state, organizationId);
        return this.findByIds(state, ids, true);
    }

    getDefaultOrgScript(state, organizationId) {
        const id = Organization.getDefaultOrgScriptById(state, organizationId);
        return this.findById(state, id, true);
    }
}

export default new UiScripts();