"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DEFAULT_LOCATION", {
  enumerable: true,
  get: function () {
    return _ImageAnalyzer.DEFAULT_LOCATION;
  }
});
Object.defineProperty(exports, "detectQRPlaceholder", {
  enumerable: true,
  get: function () {
    return _ImageAnalyzer.detectQRPlaceholder;
  }
});
var _ImageAnalyzer = require("./ImageAnalyzer");