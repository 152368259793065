const NAMESPACE = 'entities/spotlights/';


export const GET_SPOTLIGHTS = `${NAMESPACE}GET_SPOTLIGHTS`;

export function getSpotlights(organizationId) {
    return {
        type: GET_SPOTLIGHTS,
        payload: {
            organizationId,
        }
    };
}


export const GET_SPOTLIGHTS_SUCCESS = `${NAMESPACE}GET_SPOTLIGHTS_SUCCESS`;

export function getSpotlightsSuccess(organizationId, spotlightIds, entities) {
    return {
        type: GET_SPOTLIGHTS_SUCCESS,
        payload: {
            organizationId,
            spotlightIds,
        },
        entities
    };
}


export const GET_SPOTLIGHTS_FAILURE = `${NAMESPACE}GET_SPOTLIGHTS_FAILURE`;

export function getSpotlightsFailure(organizationId, error) {
    return {
        type: GET_SPOTLIGHTS_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}


export const GET_SPOTLIGHT = `${NAMESPACE}GET_SPOTLIGHT`;

export function getSpotlight(organizationId, spotlightId) {
    return {
        type: GET_SPOTLIGHT,
        payload: {
            organizationId,
            spotlightId,
        }
    };
}


export const GET_SPOTLIGHT_SUCCESS = `${NAMESPACE}GET_SPOTLIGHT_SUCCESS`;

export function getSpotlightSuccess(organizationId, spotlightId, entities) {
    return {
        type: GET_SPOTLIGHT_SUCCESS,
        payload: {
            organizationId,
            spotlightId,
        },
        entities
    };
}


export const GET_SPOTLIGHT_FAILURE = `${NAMESPACE}GET_SPOTLIGHT_FAILURE`;

export function getSpotlightFailure(organizationId, spotlightId, error) {
    return {
        type: GET_SPOTLIGHT_FAILURE,
        payload: {
            organizationId,
            spotlightId,
            error,
        },
    };
}


export const SET_DISPLAY_FILTER = `${NAMESPACE}SET_DISPLAY_FILTER`;

export function setDisplayFilter(displayFilter) {
    return {
        type: SET_DISPLAY_FILTER,
        payload: {
            displayFilter,
        },
    };
}


export const DELETE_SPOTLIGHT = `${NAMESPACE}DELETE_SPOTLIGHT`;

export function deleteSpotlight(organizationId, spotlightId, redirect) {
    return {
        type: DELETE_SPOTLIGHT,
        payload: {
            organizationId,
            spotlightId,
            redirect,
        }
    };
}


export const DELETE_SPOTLIGHT_SUCCESS = `${NAMESPACE}DELETE_SPOTLIGHT_SUCCESS`;

export function deleteSpotlightSuccess(organizationId, spotlightId) {
    return {
        type: DELETE_SPOTLIGHT_SUCCESS,
        payload: {
            organizationId,
            spotlightId,
        }
    };
}


export const DELETE_SPOTLIGHT_FAILURE = `${NAMESPACE}DELETE_SPOTLIGHT_FAILURE`;

export function deleteSpotlightFailure(organizationId, spotlightId, error) {
    return {
        type: DELETE_SPOTLIGHT_FAILURE,
        payload: {
            organizationId,
            spotlightId,
            error,
        },
    };
}


export const CREATE_SPOTLIGHT = `${NAMESPACE}CREATE_SPOTLIGHT`;

export function createSpotlight(organizationId, spotlight, redirect, type, addSchedules, destinationUrl, logo, engagement_value, scriptSchedules, displayRules = {}, addTags = []) {

    return {
        type: CREATE_SPOTLIGHT,
        payload: {
            organizationId,
            spotlight,
            redirect,
            type,
            addSchedules,
            destinationUrl,
            logo,
            engagement_value,
            displayRules,
            addTags,
            scriptSchedules
        }
    };
}


export const CREATE_SPOTLIGHT_SUCCESS = `${NAMESPACE}CREATE_SPOTLIGHT_SUCCESS`;

export function createSpotlightSuccess(organizationId) {
    return {
        type: CREATE_SPOTLIGHT_SUCCESS,
        payload: {
            organizationId,
        }
    };
}


export const CREATE_SPOTLIGHT_FAILURE = `${NAMESPACE}CREATE_SPOTLIGHT_FAILURE`;

export function createSpotlightFailure(organizationId, error) {
    return {
        type: CREATE_SPOTLIGHT_FAILURE,
        payload: {
            organizationId,
            error,
        },
    };
}


export const UPDATE_SPOTLIGHT = `${NAMESPACE}UPDATE_SPOTLIGHT`;

export function updateSpotlight(organizationId, spotlightId, spotlight, spt_org_id, redirect = true, addSchedules = [], deleteSchedules = [],
        displayRules,scriptSchedules = [], delete_script_ids = [], addTags=[], deleteTags=[]) {

    return {
        type: UPDATE_SPOTLIGHT,
        payload: {
            organizationId,
            spotlightId,
            spotlight,
            redirect,
            addSchedules,
            deleteSchedules,
            spt_org_id,
            displayRules,
            addTags,
            deleteTags,
            scriptSchedules,
            delete_script_ids
        }
    };
}


export const UPDATE_SPOTLIGHT_SUCCESS = `${NAMESPACE}UPDATE_SPOTLIGHT_SUCCESS`;

export function updateSpotlightSuccess(organizationId, spotlightId, entities) {
    return {
        type: UPDATE_SPOTLIGHT_SUCCESS,
        payload: {
            organizationId,
            spotlightId,
        },
        entities
    };
}


export const UPDATE_SPOTLIGHT_FAILURE = `${NAMESPACE}UPDATE_SPOTLIGHT_FAILURE`;

export function updateSpotlightFailure(organizationId, spotlightId, error) {
    return {
        type: UPDATE_SPOTLIGHT_FAILURE,
        payload: {
            organizationId,
            spotlightId,
            error,
        },
    };
}

export const DUPLICATE_SPOTLIGHT = `${NAMESPACE}DUPLICATE_SPOTLIGHT`;

export function duplicateSpotlight(organizationId, spotlight, schedules, redirectToDuplicated) {
    return {
        type: DUPLICATE_SPOTLIGHT,
        payload: {
            organizationId,
            spotlightId: spotlight.id,
            spotlight,
            schedules,
            redirectToDuplicated
        }
    };
}

export const DUPLICATE_SPOTLIGHT_SUCCESS = `${NAMESPACE}DUPLICATE_SPOTLIGHT_SUCCESS`;

export function duplicateSpotlightSuccess(spotlightId) {
    return {
        type: DUPLICATE_SPOTLIGHT_SUCCESS,
        payload: {
            spotlightId,
        }
    };
}


export const DUPLICATE_SPOTLIGHT_FAILURE = `${NAMESPACE}DUPLICATE_SPOTLIGHT_FAILURE`;

export function duplicateSpotlightFailure(spotlightId, error) {
    return {
        type: DUPLICATE_SPOTLIGHT_FAILURE,
        payload: {
            spotlightId,
            error,
        },
    };
}

export const DISABLE_SELECTED_SPOTLIGHTS = `${NAMESPACE}DISABLE_SELECTED_SPOTLIGHTS`;

export function disableSelectedSpotlights(spotlightsIds, organizationId) {
    return {
        type: DISABLE_SELECTED_SPOTLIGHTS,
        payload: {
            organizationId,
            spotlightsIds,
        }
    };
}

export const DISABLE_SELECTED_SPOTLIGHTS_SUCCESS = `${NAMESPACE}DISABLE_SELECTED_SPOTLIGHTS_SUCCESS`;

export function disableSelectedSpotlightsSuccess(spotlightsIds) {
    return {
        type: DISABLE_SELECTED_SPOTLIGHTS_SUCCESS,
        payload: {
            spotlightsIds
        },
    };
}

export const DISABLE_SELECTED_SPOTLIGHTS_FAILURE = `${NAMESPACE}DISABLE_SELECTED_SPOTLIGHTS_FAILURE`;

export function disableSelectedSpotlightsFailure(spotlightsIds, error) {
    return {
        type: DISABLE_SELECTED_SPOTLIGHTS_FAILURE,
        payload: {
            spotlightsIds,
            error,
        }
    };
}

export const SELECT_SPOTLIGHT = `${NAMESPACE}SELECT_SPOTLIGHT`;

export function selectSpotlight(spotlightsId) {
    return {
        type: SELECT_SPOTLIGHT,
        payload: {
            spotlightsId,
        }
    };
}

export const DESELECT_SPOTLIGHT = `${NAMESPACE}DESELECT_SPOTLIGHT`;

export function deselectSpotlight(spotlightsId) {
    return {
        type: DESELECT_SPOTLIGHT,
        payload: {
            spotlightsId,
        }
    };
}

export const CLEAR_SELECTED_SPOTLIGHTS = `${NAMESPACE}CLEAR_SELECTED_SPOTLIGHTS`;

export function clearSelectedSpotlights() {
    return {
        type: CLEAR_SELECTED_SPOTLIGHTS,
        payload: {}
    };
}

export const DELETE_SELECTED_SPOTLIGHTS = `${NAMESPACE}DELETE_SELECTED_SPOTLIGHTS`;

export function deleteSelectedSpotlights(spotlightsIds, organizationId) {
    return {
        type: DELETE_SELECTED_SPOTLIGHTS,
        payload: {
            organizationId,
            spotlightsIds,
        }
    };
}

export const DELETE_SELECTED_SPOTLIGHTS_SUCCESS = `${NAMESPACE}DELETE_SELECTED_SPOTLIGHTS_SUCCESS`;

export function deleteSelectedSpotlightsSuccess(spotlightsIds) {
    return {
        type: DELETE_SELECTED_SPOTLIGHTS_SUCCESS,
        payload: {
            spotlightsIds
        },
    };
}

export const DELETE_SELECTED_SPOTLIGHTS_FAILURE = `${NAMESPACE}DELETE_SELECTED_SPOTLIGHTS_FAILURE`;

export function deleteSelectedSpotlightsFailure(spotlightsIds, error) {
    return {
        type: DELETE_SELECTED_SPOTLIGHTS_FAILURE,
        payload: {
            spotlightsIds,
            error,
        }
    };
}

export const CREATE_TRACKING_CODE = `${NAMESPACE}CREATE_TRACKING_CODE`;

export function createTrackingCode(organizationId, destination, spotlightId, logo, engagement_value) {
    return {
        type: CREATE_TRACKING_CODE,
        payload: {
            organizationId,
            destination,
            spotlightId,
            logo,
            engagement_value,
        }
    };
}

export const CREATE_TRACKING_CODE_SUCCESS = `${NAMESPACE}CREATE_TRACKING_CODE_SUCCESS`;

export function createTrackingCodeSuccess() {
    return {
        type: CREATE_TRACKING_CODE_SUCCESS,
    };
}


export const CREATE_TRACKING_CODE_FAILURE = `${NAMESPACE}CREATE_TRACKING_CODE_FAILURE`;

export function createTrackingCodeFailure(error) {
    return {
        type: CREATE_TRACKING_CODE_FAILURE,
        payload: {
            error,
        },
    };
}

export const UPDATE_TRACKING_CODE = `${NAMESPACE}UPDATE_TRACKING_CODE`;

export function updateTrackingCode(organizationId, codeId, destination, spotlightId, logo, engagement_value) {
    return {
        type: UPDATE_TRACKING_CODE,
        payload: {
            organizationId,
            codeId,
            destination,
            spotlightId,
            logo,
            engagement_value,
        }
    };
}

export const UPDATE_TRACKING_CODE_SUCCESS = `${NAMESPACE}UPDATE_TRACKING_CODE_SUCCESS`;

export function updateTrackingCodeSuccess() {
    return {
        type: UPDATE_TRACKING_CODE_SUCCESS,
    };
}


export const UPDATE_TRACKING_CODE_FAILURE = `${NAMESPACE}UPDATE_TRACKING_CODE_FAILURE`;

export function updateTrackingCodeFailure(error) {
    return {
        type: UPDATE_TRACKING_CODE_FAILURE,
        payload: {
            error,
        },
    };
}
