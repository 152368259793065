import { graphqlQuery } from "../axios";
import _get from "lodash/get";

export function getPlutoSchedules(organizationId) {
    return graphqlQuery({
        query: `query ecp_getPlutoSchedules($org_id: Int!, $include_parents: Boolean = false){
          Organization(id: $org_id) {
                PlutoTVSchedules(includeParents: $include_parents) {
                  id
                  mondays
                  tuesdays
                  wednesdays
                  thursdays
                  fridays
                  saturdays
                  sundays
                  validFrom
                  validThrough
                  validFromTime
                  validThroughTime
                  behavior
                  tz
                  organization: public_organization {
                    id
                    name
                  }
                  channel {
                    id
                    title
                    thumbnail
                  }
                  
                }
            }
          }`, variables: {org_id: organizationId, include_parents: true}
    }).then(response => _get(response, 'data.data.Organization.PlutoTVSchedules', []))
    .then(schs => schs.map( sch => { sch.type = "regular"; return sch}) );
}

export function createPlutoSchedule(organizationId, schedule) {
  const payload = {
    mondays: schedule.mondays,
    tuesdays: schedule.tuesdays,
    wednesdays: schedule.wednesdays,
    thursdays: schedule.thursdays,
    fridays: schedule.fridays,
    saturdays: schedule.saturdays,
    sundays: schedule.sundays,
    validFrom: schedule.validFrom,
    validThrough: schedule.validThrough,
    validFromTime: schedule.validFromTime,
    validThroughTime: schedule.validThroughTime,
    type: schedule.type,
    behavior: schedule.behavior,
    tz: schedule.tz,
  };
  const channelId = schedule.channel_id
  if (Array.isArray(schedule.organizationIds)) {
    const queries = schedule.organizationIds.reduce((acc, org_id) => {
      return acc + `${"org_" + org_id}: orgMutation (organization_id: ${org_id}){
                  create_plutotv_schedule(id: $id, input: $input) {
                    successful
                    message
                    schedule {
                      id
                  }
                  }
               }`
    }, '');
    return graphqlQuery({
      query: `mutation createPlutoSchedule($id: Int!, $input: ScheduleInput!) {
          ${queries}
        }`, variables: { id: channelId, input: payload }
    }).then(response => _get(response, 'data', {}));
  }

  return graphqlQuery({
    query: `mutation createPlutoSchedule($org_id: Int!, $id: Int!, $input: ScheduleInput!) {
              orgMutation (organization_id: $org_id){
                create_plutotv_schedule(id: $id, input: $input) {
                  successful
                  message
                  schedule {
                    id
                  }
                }
              }
    }`, variables: { org_id: organizationId, id: channelId, input: payload }
  }).then(response => _get(response, 'data.data.orgMutation.create_plutotv_schedule', {}));
}


export function deletePlutoSchedule(organizationId, scheduleId) {
  return graphqlQuery({
    query: `mutation deletePlutoSchedule($org_id: Int!, $id: Int!) {
              orgMutation(organization_id: $org_id) {
                delete_plutotv_schedule(id: $id) {
                  successful
                  message
                }
              }
    }`, variables: {org_id: organizationId, id: scheduleId}
}).then(response => _get(response, 'data.data.orgMutation.delete_plutotv_schedule', {}));
}

export function deleteAllPlutoSchedules(org_id, channel_id) {
    return graphqlQuery({
        query: `mutation deleteAllPlutoSchedules($org_id: Int!, $channel_id: Int!){
            orgMutation(organization_id: $org_id){
              clear_plutotv_schedules(id: $channel_id){
                    status
                    message 
                    successful
                }
            }
        }`, variables: {org_id, channel_id}
    }).then(response => _get(response, 'data.data.orgMutation.clear_plutotv_schedules', {}));
}