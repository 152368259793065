import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import UpshowNowSchedule from './UpshowNowSchedule';
import { upshowNowSchedules as upshowNowSchedulesApi } from '../../../../api/modules';
import * as actions from './actions';
import scheduleAppliesToNow from '../../../../helpers/scheduleAppliesToNow';
import { scheduleAppliesToFuture } from '@upshow/sched-utils';
import Organization from '../organizations/Organization';

export function * getSchedules (action) {
    const { organizationId, includePastSchedules = false } = action.payload;

    try {
        const schedules = yield call(upshowNowSchedulesApi.getUpshowNowSchedules, organizationId);
        const defaultTz = yield select(state => Organization.getCurrentTimezoneName(state));
        const activeSchedules = includePastSchedules ? schedules : schedules.filter((schedule) => scheduleAppliesToNow(schedule, defaultTz) || scheduleAppliesToFuture(schedule, defaultTz));

        const { result, entities } = UpshowNowSchedule.parseArray(activeSchedules);
        yield put(actions.getSchedulesSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch organization schedules';
        yield call(toast.error, message);
        yield put(actions.getSchedulesFailure(organizationId, { message, error }));
    }
}

export function * watchGetSchedules () {
    yield takeEvery(actions.GET_SCHEDULES, getSchedules);
}

export default function * rootSaga () {
    yield all([
        watchGetSchedules(),
    ]);
}