import { graphqlQuery } from "../axios";
import _get from "lodash/get";

const scriptSegment = `
    id
    name
    sequence
    Sequence {
        timestamp
        state
        metadata {
          id
        }
        type
        ScriptStates {
            ... on Spotlight {
                id
                active
            }
            ... on PlutoTvChannel {
                id
                active
            }
            ... on UpshowNowChannel {
                id
                active
                metadata
            }
            ... on MediaChannel {
                id
                enabled
                is_premium
                org_has_access(id: $org_id, include_parents: true)
            }
            ... on ApplicationInstance {
                id
                application {
                    id
                    active
                }
            }
        }
    }
    active
    public
    description
    title
    created_at
    updated_at
    metadata
    organization_id
    locked_in_org(org_id: $org_id)
    locked_include_parents: locked_in_org(org_id: $org_id include_parents: true)
    isSmart
    takeover_content_id
    takeover_content {
        ...on Spotlight {
            id
            title
            active
            organization { id name }
            background {
                id
                thumbnail
            }
            properties
            text: textRaw
            metadata: metadataRaw
            TrackingCode {
                id
                tracking_code
            }
        }
        ...on ApplicationInstance { id application { id title icon } }
    }
`;

export function getUiScripts(org_id) {
    return graphqlQuery({
        query: `query ecp_get_ui_scripts($org_id : Int!){
            ui_scripts {
                ${scriptSegment}
            }
            Organization(id: $org_id){ id
                organization_scripts(includeParents: true){
                    ${scriptSegment}
                }
            }
                      
        }`, variables: {org_id}
        }).then(response => {
            const data = _get(response, 'data.data.ui_scripts', []);
            const data_org = _get(response, 'data.data.Organization.organization_scripts', []);
            const error = _get(response, 'data.errors');
            return { data: data?.concat(data_org), error };
        });
}

export function getAccountsScript(organizationId) {
    return graphqlQuery({
        query: `query getAccountsScript($org_id: Int!) {
                  Organization(id: $org_id) { id
                    accounts_script {
                        account_id
                        account_name
                        name
                        title
                        sequence
                        description
                    }
                }
        }`, variables: {org_id: organizationId}
    }).then(response => {
        const account_scripts = _get(response, 'data.data.Organization.accounts_script', []);
        const data = account_scripts?.map( (script, idx) => {
            return {
                ...script,
                id: idx - 100000,
            }
        });

        const error = _get(response, 'data.errors');

        return { data, error };
    });
  }

  export function getDevicesScript(organizationId) {
    return graphqlQuery({
        query: `query getDevicesScript($org_id: Int!) {
                    Organization(id: $org_id) { id
                        devices_script {
                            device_name
                            name
                            title
                            sequence
                            description
                        }
                    }
        }`, variables: {org_id: organizationId}
    }).then(response => {
        const device_scripts = _get(response, 'data.data.Organization.devices_script', []);
        const data = device_scripts?.map( (script, idx) => {
            return {
                ...script,
                id: idx - 200000,
            }
        });
        const error = _get(response, 'data.errors');

        return { data, error };
    });
  }

  export function getDefaultOrgScript(organizationId) {
    return graphqlQuery({
        query: `query getDefaultOrgScript($org_id: Int!) {
                    Organization(id: $org_id) { id
                        default_org_script {
                            id
                            name
                            title
                            sequence
                            active
                            public
                            description
                            created_by {
                                id
                            }
                            created_at
                            updated_by {
                                id
                            }
                            updated_at
                            metadata
                        }
                    }
        }`, variables: {org_id: organizationId}
    }).then(response => {
        const data = _get(response, 'data.data.Organization.default_org_script', null);
        const error = _get(response, 'data.errors');

        return { data, error };
    });
  }

export function createUiScript(name, sequence, active, isPublic, description, title, organization_id) {
    return graphqlQuery({
        query: `mutation ecp_createUiScript($name: String!, $sequence: [JSON]!, $active: Boolean!,
                                            $public: Boolean!, $description: String, $title: String!, 
                                            $organization_id: Int) {
            create_ui_script(name: $name, sequence: $sequence, active: $active, public: $public,
                description: $description, title: $title, organization_id: $organization_id) {
                    status
                    successful
                    message
                    script { 
                        id
                        name
                        sequence
                        active
                        public
                        description
                        title
                        created_at
                        updated_at
                        metadata
                        organization_id
                     }
            }
        }`, variables: {name, sequence, active, public: isPublic, description, title, organization_id}
    }).then(response => {
        const data = _get(response, 'data.data.create_ui_script', null);
        const error = _get(response, 'data.errors');
        return { data, error };
    });
}

export function updateUiScript(id, title, sequence, description, active) {
    return graphqlQuery({
        query: `mutation ecp_updateUiScript($id: Int!, $sequence: [JSON], $active: Boolean, $description: String, $title: String) {
            update_ui_script(id: $id, sequence: $sequence, active: $active, description: $description, title: $title) {
                    status
                    successful
                    message
                    script { 
                        id
                        name
                        sequence
                        active
                        public
                        description
                        title
                        created_at
                        updated_at
                        metadata
                        organization_id
                    }
            }
        }`, variables: {id, title, sequence, description, active}
    }).then(response => {
        const data = _get(response, 'data.data.update_ui_script', null);
        const error = _get(response, 'data.errors');
        return { data, error };
    });
}

export function deleteUiScript(id) {
    return graphqlQuery({
        query: `mutation ecp_deleteUiScript($id: Int!) {
            delete_ui_script(id: $id) {
                status
                successful
                message
            } 
        }`, variables: {id}
    }).then(response => {
        const data = _get(response, 'data.data.delete_ui_script', null);
        const error = _get(response, 'data.errors');
        return { data, error };
    });
}

export function duplicateUiScript(id,  organization_id) {
    return graphqlQuery({
        query: `mutation ecp_duplicateUiScript($id: Int!, $organization_id: Int!) {
            duplicate_ui_script(id: $id organization_id: $organization_id) {
                status
                successful
                message
                script { 
                    id
                    name
                }
            } 
        }`, variables: {id, organization_id}
    }).then(response => {
        const data = _get(response, 'data.data.duplicate_ui_script', null);
        const error = _get(response, 'data.errors');
        return { data, error };
    });
}