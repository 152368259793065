import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { buildUrl } from '../../../helpers/organizationPath';
import { gql, useQuery } from '@apollo/client';
import { MINIMUM_SPOTLIGHT_TO_RENDER } from '../../../helpers/graphqlFragments';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import EmptyState from '../../../components/EmptyState';

const GET_SPOTLIGHT = gql`
    ${MINIMUM_SPOTLIGHT_TO_RENDER}
    query getSpotlightForRedirection($organization_id: Int! $spotlight_id: Int!) {
        Organization(id: $organization_id) {
            id
            spotlight(id: $spotlight_id) {
                ...SpotlightInfo
                public_organization {
                    id
                    name
                }
                organization {
                  id
                  parent_organizations {
                    id
                  }
                }
                updated_by_public {
                    id
                    username
                    avatar_url
                }
                approval_status
                updated_at
            }
        }
    }
`;

const generateBaseUrl = (spotlight, baseUrl) => {
    if (spotlight.organization.parent_organizations && spotlight.organization.parent_organizations.length > 0) {
        let orgIds = [];
        for (let index = spotlight.organization.parent_organizations.length -1; index >= 0 ; index--){
            orgIds.push(spotlight.organization.parent_organizations[index].id);
        }
        orgIds.push(spotlight.organization.id);
        return buildUrl('organizations', orgIds);
    } else {
        return baseUrl;
    }
}

const GetSpotlightService = (orgId, sptId) => {
    const getSpotlightParam = {
        variables: { organization_id: parseInt(orgId), spotlight_id: parseInt(sptId) },
        fetchPolicy: 'cache-and-network'
    };

    const { loading, error, data } = useQuery(GET_SPOTLIGHT, getSpotlightParam);

    return { loading, error, response: data?.Organization?.spotlight };
};

const RedirectSpotlight = () => {
    const { orgId, sptId } = useParams();

    const spotlightResponse = GetSpotlightService(orgId, sptId);

    const basePath = `/organizations/${orgId}/spotlights/${sptId}?initialToolbox=TEXT`;

    if (spotlightResponse.loading && !spotlightResponse.response) return <div className="Organizations"><LoadingOverlay/></div>;
    if (!!spotlightResponse.error) return <EmptyState type="ERROR"/>;

    return (<Redirect to={generateBaseUrl(spotlightResponse.response, basePath)}/>);
}

export default RedirectSpotlight;