import Entity from '../Entity';
import Organization from '../organizations/Organization';

class UpshowNowChannel extends Entity {

    constructor () {
        super('upshowNowChannels');
    }

    reducer (state = this.initialState, action) {
        return super.reducer(state, action);
    }

    getUpshowNowChannelsByOrganization (state, organizationId, denormalized = false) {
        const ids = Organization.getUpshowNowChannelsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

}

export default new UpshowNowChannel();