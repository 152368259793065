import Entity from "../Entity";
import * as actions from "./actions";

class OrganizationSetting extends Entity {

    constructor() {
        super('organizationsSettings');

        this.initialState = {
            mixTileSelected: null,
        };
    }

    reducer(state = this.initialState, action) {
        switch (action.type) {

            case actions.OPEN_MIXES_SIDE_PANEL: {
                const { mixTileSelected } = action.payload;
                
                return {
                    ...state,
                    mixTileSelected,
                };
            }

            case actions.CLOSE_MIXES_SIDE_PANEL: {
                return {
                    ...state,
                    mixTileSelected: null,
                };
            }

            default: {
                return super.reducer(state, action);
            }
        }
    }
    
    isMixesSidePanelOpen(state) {
        return !!this.getState(state).mixTileSelected;
    }

    getSelectedMixTile(state) {
        return this.getState(state).mixTileSelected;
    }
}

export default new OrganizationSetting();