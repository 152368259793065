import React, { Fragment } from "react";

import Dialog from "../Dialog";
import DialogService from "../../services/DialogService";

class GlobalDialog extends React.Component {
    render() {
        return (
            <Fragment>
                <Dialog ref={ref => DialogService.dialog = ref}/>
            </Fragment>
        );

    }
}

export default GlobalDialog;