import React, { Suspense } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GET_CAMPAIGNS } from '../../api/queries/campaigns';

import history from '../../services/HistoryService';
import { parse } from '../../helpers/organizationPath';
import Loading from '../../components/LoadingOverlay/LoadingOverlay';
import FcoCheckMessage from '../../components/FcoCheckMessage';
import {
    AssetUploader,
    Audio,
    CampaignCreator,
    CampaignDetails,
    CampaignsInsights,
    CollectionContents,
    CollectionEditor,
    CollectionLibrary,
    Contests,
    CreateSpotlight,
    HomeDashboard,
    InsightsCampaignDetails,
    ListApps,
    ListDevices,
    LiveEventRequest,
    Mixes,
    MixesEditor,
    NewAssetLibrary,
    NewEntertainment,
    PromotionsManager,
    Settings,
    SingleApp,
    SingleSpotlight,
    SocialContent,
    SpotlightApprovals,
    SurveyDetails,
    Surveys,
    DirectShare,
    SmartPlaylist,
    SundayTicket,
    ThursdayNightFootball,
    Peacock,
    NFLPage,
    Netflix
} from '../index';

import './Organization.scss';

// End Collection Section

// Start Recent Campaign Section
const RecentCampaignSection = (props) => {
    const { match, currentOrganization } = props;

    const { data, loading } = useQuery(GET_CAMPAIGNS, {
        variables: {
            organizationId: currentOrganization.id,
            limit: 1
        }, fetchPolicy: 'cache-and-network'
    });

    const campaigns = data?.Organization.campaigns ?? [];

    if (loading) {
        return <Loading label="Loading"/>;
    }

    const redirectUrl = campaigns.length > 0 ? `campaigns/${campaigns[0].id}` : 'promotions?tab=campaigns';

    return <Redirect to={`${match.url}/${redirectUrl}`}/>;
};

// End Recent Campaign Section

class Organization extends React.Component {

    componentDidMount () {
        if (!this.redirectIfRequired() && !this.props.isLoading && !this.props.currentOrganization) {
            this.switchToUrlOrg();
        }
    }

    componentDidUpdate (prevProps) {

        if (!this.redirectIfRequired()) {
            const organizationHasChanged = this.props.match.params.orgPath !== prevProps.match.params.orgPath;

            if (organizationHasChanged) {
                this.switchToUrlOrg();
            }
        }
    }

    redirectIfRequired () {
        const {
            redirectToUrl,
            redirectToRelativeUrlSuccess,
        } = this.props;

        if (redirectToUrl) {
            redirectToRelativeUrlSuccess();
            history.push(redirectToUrl);
            return true;
        }

        return false;
    }

    switchToUrlOrg () {
        const parsedPath = parse(this.props.match.params.orgPath);
        this.props.switchOrganization(parsedPath);
    }

    getFallbackRoute () {
        const { match } = this.props;
        return `${match.url}/home`;
    }

    render () {
        const {
            isSwitchingOrganization,
            errorSwitchingOrganization,
            currentOrganization,
            match,
            isLoading,
            hasPlugins,
            canManageDevices,
            orgHasFeature: orgHas,
            isFetchingUser,
            userHasOrgPermission,
            firstRootOrgId
        } = this.props;

        if (errorSwitchingOrganization && !isSwitchingOrganization) {
            return <Redirect to={`/organizations/${firstRootOrgId}/home`}/>
        }

        if (isSwitchingOrganization || !currentOrganization || isFetchingUser || isLoading) {
            return <div className="Organization">
                <div className="main-container">
                    <div className="page-container">
                        <Loading label={'Loading'}/>
                    </div>
                </div>
            </div>;
        }


        return (
            <>
                <Suspense fallback={<Loading />} >
                    <div className="Organization">

                        <div className="main-container">
                            <FcoCheckMessage/>
                            <div className="page-container">

                                <Switch>

                                    <Route
                                        path={`${match.url}/home`}
                                        component={HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/content/direct-share`}
                                        component={orgHas('SOCIAL') ? DirectShare : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/content`}
                                        component={orgHas('SOCIAL') ? SocialContent : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/spotlights/new`}
                                        component={userHasOrgPermission('spotlights') && orgHas('SPOTLIGHT') ? CreateSpotlight : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/spotlights/:spotlightId`}
                                        component={orgHas('SPOTLIGHT') ? SingleSpotlight : HomeDashboard}
                                    />

                                    <Route path={`${match.url}/library/new`}>
                                        <AssetUploader/>
                                    </Route>
                                    <Route path={`${match.url}/library`}>
                                        <NewAssetLibrary/>
                                    </Route>
                                    <Route path={`${match.url}/collections/new`}>
                                        <CollectionEditor/>
                                    </Route>
                                    <Route path={`${match.url}/collections/:collectionId`}>
                                        <CollectionContents/>
                                    </Route>
                                    <Route path={`${match.url}/collections`}>
                                        <CollectionLibrary/>
                                    </Route>

                                    <Route
                                        path={`${match.url}/entertainment/live-events/request/:eventId`}
                                        component={userHasOrgPermission('entertainment') && orgHas('ENTERTAINMENT') ? LiveEventRequest : HomeDashboard}/>

                                    <Route
                                        path={`${match.url}/entertainment`}
                                        component={userHasOrgPermission('entertainment') && orgHas('ENTERTAINMENT') ? NewEntertainment : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/sunday-ticket`}
                                        component={orgHas('LIVE_EVENTS') && orgHas('SUNDAY_TICKET') ? SundayTicket : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/peacock`}
                                        component={orgHas('LIVE_EVENTS') && orgHas('PEACOCK') ? Peacock : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/nfl`}
                                        component={orgHas('LIVE_EVENTS') ? NFLPage : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/thursday-night-football`}
                                        component={orgHas('LIVE_EVENTS') ? ThursdayNightFootball : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/netflix`}
                                        component={orgHas('LIVE_EVENTS') && orgHas('NETFLIX') ? Netflix : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/plugins/:connectedAppId`}
                                        component={userHasOrgPermission('plugins') && hasPlugins ? SingleApp : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/plugins`}
                                        component={userHasOrgPermission('plugins') && hasPlugins ? ListApps : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/devices`}
                                        component={userHasOrgPermission('devices') && canManageDevices && orgHas('DEVICES') ? ListDevices : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/audio`}
                                        component={userHasOrgPermission('audio') && Audio}
                                    />

                                    <Route
                                        path={`${match.url}/new_insights`}
                                        component={orgHas('SPOTLIGHT') && userHasOrgPermission('insights') ? CampaignsInsights : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/campaign/:campaignId/insights`}
                                        component={orgHas('SPOTLIGHT') ? InsightsCampaignDetails : HomeDashboard}
                                    />
                                    <Route
                                        path={`${match.url}/playlists/new`}
                                        component={userHasOrgPermission('playlists') && orgHas('MIXES_EDITOR') ? MixesEditor : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/playlists/:mixId`}
                                        component={userHasOrgPermission('playlists') && orgHas('MIXES_EDITOR') ? MixesEditor : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/playlists`}
                                        component={userHasOrgPermission('playlists') && orgHas('MIXES') ? Mixes : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/smart_playlists`}
                                        component={userHasOrgPermission('playlists') && orgHas('SMART_PLAYLISTS') ? SmartPlaylist : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/settings`}
                                        component={(userHasOrgPermission('org_settings') || userHasOrgPermission('user_management')) && Settings}
                                    />

                                    <Route
                                        path={`${match.url}/contests`}
                                        component={userHasOrgPermission('boh_contests') && orgHas('BOH_CONTESTS') ? Contests : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/campaigns/new`}
                                        component={userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? CampaignCreator : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/campaigns/:campaignId`}
                                        component={userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? CampaignDetails : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/campaigns`}
                                        component={userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? PromotionsManager : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/promotions`}
                                        component={userHasOrgPermission('spotlights') && orgHas('SPOTLIGHT') ? PromotionsManager : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/approvals`}
                                        component={orgHas('SPOTLIGHT_APPROVAL') && userHasOrgPermission('spotlight_approver') ? SpotlightApprovals : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/surveys/:surveyId`}
                                        component={userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? SurveyDetails : HomeDashboard}
                                    />

                                    <Route
                                        path={`${match.url}/surveys`}
                                        component={userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') && orgHas('SPOTLIGHT') ? Surveys : HomeDashboard}
                                    />

                                    <Route path={`${match.url}/recent-campaign`}>
                                        <RecentCampaignSection {...this.props}/>
                                    </Route>

                                    <Route
                                        path={`${match.url}`}
                                        render={() => <Redirect to={this.getFallbackRoute()}/>}
                                    />

                                </Switch>

                            </div>

                        </div>

                    </div>

                </Suspense>
            </>
        );
    }
}

Organization.propTypes = {
    isSwitchingOrganization: PropTypes.bool.isRequired,
    errorSwitchingOrganization: PropTypes.string,
    currentOrganization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }),
    redirectToUrl: PropTypes.string,
    redirectToRelativeUrlSuccess: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasPlugins: PropTypes.bool.isRequired,
    isFetchingUser: PropTypes.bool.isRequired,
    orgHasFeature: PropTypes.func.isRequired,
    userHasOrgPermission: PropTypes.func.isRequired,
    canManageDevices: PropTypes.bool.isRequired,
    switchOrganization: PropTypes.func.isRequired,
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        params: PropTypes.shape({
            orgPath: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    hasRoleCanary: PropTypes.bool.isRequired,
    firstRootOrgId: PropTypes.number.isRequired
};

export default Organization;
