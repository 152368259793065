import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import Organization from '../../redux/modules/entities/organizations/Organization';
import { GET_SALESFORCE_METADATA } from '../../api/queries/settings';

import MessageActionBar from '../MessageActionBar';

const mapStateToProps = (state) => {
    const currentOrgId = Organization.getCurrentOrganizationId(state);
    const orgHasFeature = Organization.getFeatureAccesor(state);

    return {
        baseUrl: Organization.getCurrentOrganizationUrl(state),
        isLeaf: Organization.isLeaf(state, currentOrgId),
        hasPeacock: orgHasFeature('PEACOCK'),
        orgId: currentOrgId,
    };
};

const FcoCheckMessage = (props) => {
    const { baseUrl, isLeaf, hasPeacock, orgId } = props;
    const history = useHistory();

    //TODO: Do not use SalesforceMetadata
    const { data: sfData, loading, error } = useQuery(GET_SALESFORCE_METADATA, {
        variables: { organizationId: orgId },
        fetchPolicy: 'cache-and-network',
    });

    if (loading || error) return null;

    const salesforceData = sfData.Organization.SalesforceMetadata ? sfData.Organization.SalesforceMetadata.metadata.details : {};

    const hasFCOTier = !!salesforceData.Fire_Code_Occupancy__c;
    const hasFCONumber = !!salesforceData.Fire_Code_Occupancy2__c;

    const goToSettings = () => {
        history.push(`${baseUrl}/settings?tab=general`);
    }

    if ((hasFCOTier && hasFCONumber) || !hasFCOTier || !isLeaf || !hasPeacock) return null;

    return (
        <MessageActionBar
            variant="alert"
            hideClose
            titleIcon={<ReportProblemOutlinedIcon fontSize='small' classes={{root: 'icon'}}/>}
            titleText="Fire Code Occupancy (FCO) number has not been provided."
            description="To continue showing Peacock Sports Pass content, please enter your FCO."
            buttonAction={goToSettings}
            buttonDisable={false}
            buttonText="Enter Now"
        />
    )
}

export default connect(mapStateToProps)(FcoCheckMessage);