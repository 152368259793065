const NAMESPACE = 'entities/spotlightBackgrounds/';

export const GET_SPOTLIGHT_BACKGROUNDS = `${NAMESPACE}GET_SPOTLIGHT_BACKGROUNDS`;

export function getSpotlightBackgrounds(organizationId) {
    return {
        type: GET_SPOTLIGHT_BACKGROUNDS,
        payload: {
            organizationId,
        }
    };
}


export const GET_SPOTLIGHT_BACKGROUNDS_SUCCESS = `${NAMESPACE}GET_SPOTLIGHT_BACKGROUNDS_SUCCESS`;

export function getSpotlightBackgroundsSuccess(organizationId, spotlightBackgroundsIds, entities) {
    return {
        type: GET_SPOTLIGHT_BACKGROUNDS_SUCCESS,
        payload: {
            organizationId,
            spotlightBackgroundsIds,
        },
        entities,
    };
}


export const GET_SPOTLIGHT_BACKGROUNDS_FAILURE = `${NAMESPACE}GET_SPOTLIGHT_BACKGROUNDS_FAILURE`;

export function getSpotlightBackgroundsFailure(organizationId, error) {
    return {
        type: GET_SPOTLIGHT_BACKGROUNDS_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}