"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadBinarized = loadBinarized;
var _BitMatrix = require("./BitMatrix");
function loadBinarized(image, width, height) {
  const out = _BitMatrix.BitMatrix.createEmpty(width, height);
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      out.set(x, y, image[(y * width + x) * 4] === 0x00);
    }
  }
  return out;
}