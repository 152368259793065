import React from 'react';

import Home  from './Home';
const ListApps = React.lazy(() => import('./ListApps'));
const SocialContent = React.lazy(() => import('./SocialContent'));
const ListDevices = React.lazy(() => import('./ListDevices'));
const CreateSpotlight = React.lazy(() => import('./CreateSpotlight'));
const Organizations = React.lazy(() => import('./Organizations'));
const Organization = React.lazy(() => import('./Organization'));
const SingleApp = React.lazy(() => import('./SingleApp'));
const SingleSpotlight = React.lazy(() => import('./SingleSpotlight'));
const Mixes = React.lazy(() => import('./Mixes'));
const MixesEditor = React.lazy(() => import('./Mixes/Editor'));
const Billing = React.lazy(() => import('./Billing'));
const CreditCard = React.lazy(() => import('./CreditCard'));
const Settings = React.lazy(() => import('./Settings'));
const NewAssetLibrary = React.lazy(() => import('./NewAssetLibrary'));
const AssetUploader = React.lazy(() => import('./AssetUploader'));
const CollectionEditor = React.lazy(() => import('./Collections/Editor'));
const CollectionLibrary = React.lazy(() => import('./Collections/Library'));
const CollectionContents = React.lazy(() => import('./Collections/Contents'));
const LiveEventRequest = React.lazy(() => import('./LiveEventRequest'));
const Contests = React.lazy(() => import('./Contests'));
const HomeDashboard = React.lazy(() => import('./HomeDashboard'));
const CampaignCreator = React.lazy(() => import('./Campaigns/CampaignCreator'));
const CampaignDetails = React.lazy(() => import('./Campaigns/CampaignDetails'));
const SmartPaper = React.lazy(() => import('./Campaigns/SmartPaper'));
const PromotionsManager = React.lazy(() => import('./Promotions/PromotionsManager'));
const SpotlightApprovals = React.lazy(() => import('./SpotlightApprovals'));
const CampaignsInsights = React.lazy(() => import('./Campaigns/Insights'));
const InsightsCampaignDetails = React.lazy(() => import('./Campaigns/Insights/InsightsCampaignDetails'));
const Surveys = React.lazy(() => import('./Surveys'));
const SurveyDetails = React.lazy(() => import('./Surveys/Details'));
const Audio = React.lazy(() => import('./Audio'));
const NewEntertainment = React.lazy(() => import('./NewEntertainment'));
const DirectShare = React.lazy(() => import('./DirectShare'));
const SmartPlaylist = React.lazy(() => import('./SmartPlaylist'));
const SundayTicket = React.lazy(() => import('./SundayTicket'));
const Peacock = React.lazy(() => import('./Peacock'));
const NFLPage = React.lazy(() => import('./NFLPage'));
const ThursdayNightFootball = React.lazy(() => import('./ThursdayNightFootball'));
const Netflix = React.lazy(() => import('./Netflix'));

export {
    Home,
    ListApps,
    SocialContent,
    ListDevices,
    CreateSpotlight,
    Organization,
    Organizations,
    SingleApp,
    SingleSpotlight,
    Mixes,
    MixesEditor,
    Billing,
    CreditCard,
    Settings,
    NewAssetLibrary,
    AssetUploader,
    CollectionEditor,
    CollectionLibrary,
    CollectionContents,
    LiveEventRequest,
    Contests,
    HomeDashboard,
    CampaignCreator,
    CampaignDetails,
    SmartPaper,
    PromotionsManager,
    SpotlightApprovals,
    CampaignsInsights,
    InsightsCampaignDetails,
    Surveys,
    SurveyDetails,
    Audio,
    NewEntertainment,
    DirectShare,
    SmartPlaylist,
    SundayTicket,
    ThursdayNightFootball,
    Peacock,
    NFLPage,
    Netflix
};
