import { graphqlQuery } from '../axios';
import _get from 'lodash/get';

import { spotlightBackgroundFragment } from './spotlightBackgrounds';

export function getSpotlightAssets (organizationId) {
    return graphqlQuery({
        query: `query getSpotlightAssets ($org_id: Int! $include_parents: Boolean) {
            Organization(id: $org_id) { id
                spotlight_backgrounds(includeParents: $include_parents) { ...backgroundFields }
            }
        }
        
        ${spotlightBackgroundFragment}
        `,
        variables: { org_id: organizationId, include_parents: true }
    })
        .then(response => _get(response, 'data.data.Organization.spotlight_backgrounds', []));
}

export function getSpotlightAsset (organizationId, spotlightAssetId) {
    return graphqlQuery({
        query: `query getSpotlightAsset ($org_id: Int! $id: Int!) {
            Organization(id: $org_id) { id
                spotlight_background(id: $id) { ...backgroundFields }
            }
        }

        ${spotlightBackgroundFragment}
        `,
        variables: { org_id: organizationId, id: spotlightAssetId }
    })
        .then(response => _get(response, 'data.data.Organization.spotlight_background', {}));
}

export function createSpotlightAsset (organizationId, spotlightAsset, noPreprocess) {
    let SpotlightBackgroundInput = { ...spotlightAsset };

    if (noPreprocess) {
        const orientation = _get(SpotlightBackgroundInput, 'orientation', '');
        const isVertical = orientation === 'vertical';
        SpotlightBackgroundInput = {
            ...SpotlightBackgroundInput,
            disable_video_transcoding: false,
            thumbnail: isVertical ? 'https://cdn.upshow.tv/Video_Encoding_H.svg' : 'https://cdn.upshow.tv/Video_Encoding.svg',
        };
    }

    return graphqlQuery({
        query: `mutation moduleCreateSpotlightBackground($organizationId: Int!, $SpotlightBackgroundInput: SpotlightBackgroundInput!) {
            orgMutation(organization_id: $organizationId) {
                create_spotlight_asset(spotlightBackgroundInput: $SpotlightBackgroundInput) {
                    status
                    message 
                    successful
                    spotlightBackground {
                      id
                      type
                      url
                      metadata
                      title
                      media_object {
                          id
                          transcoderStatus
                          durationMillis
                      }
                    }
               }
            }
        }`, variables: { organizationId, SpotlightBackgroundInput }
    }).then(response => _get(response, 'data.data.orgMutation.create_spotlight_asset.spotlightBackground', {}));
}
