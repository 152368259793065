import React from 'react';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import confetti from '../../assets/confetti_dialog.svg';

import './Dialog.scss';

class Dialog extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            open: false,
            title: null,
            content: null,
            actions: [],
            showBackground: false,
            fullWidth: false,
            specificWidth: undefined,
            blockClose: false
        };

        this.open = this._open.bind(this);
        this.close = this._close.bind(this);
    }

    _open (title, content, actions, notAutoClose, showBackground, fullWidth, blockClose, specificWidth) {
        this.setState({ title, content, actions, open: true, notAutoClose, showBackground, fullWidth, blockClose, specificWidth });
    }

    _close () {
        this.setState({ open: false });
    }

    render () {
        const { title, content, actions, open, notAutoClose, showBackground, fullWidth, blockClose, specificWidth } = this.state;
        const actionsAmount = actions?.length ?? 0;
        const DEFAULT_WIDTH = 'auto';
        const width = specificWidth ? specificWidth : DEFAULT_WIDTH;

        return (
            <MuiDialog
                BackdropProps={{ style: { backgroundColor: 'var(--dark-slate-blue-50)' } }} onClose={!blockClose ? this.close : null}
                open={open} classes={{ paper: 'Dialog' }} scroll='paper'
                PaperProps={{ style: { width: fullWidth ? 'auto' : width, maxWidth: width } }}>
                <DialogTitle classes={{ root: `title ${showBackground ? 'with-background' : ''}` }}>
                    {showBackground && <img className='confetti-image' src={confetti} alt='Confetti'/>}
                    {!blockClose && <IconButton classes={{ root: 'title-close' }} aria-label="Close" onClick={this.close}>
                        <CloseIcon fontSize="default"/>
                    </IconButton>}
                    <Typography classes={{ root: `title-text ${actionsAmount === 1 ? 'centered' : ''}` }}>{title}</Typography>
                </DialogTitle>
                <DialogContent classes={{ root: `content ${actionsAmount === 1 ? 'centered' : ''}` }}>
                    {content}
                </DialogContent>
                {actionsAmount > 0
                && <DialogActions className={`actions ${actionsAmount === 1 ? 'centered' : ''}`}>
                    {actions.map((a, i) => (
                        <Button
                            className="button buttonDuplicate"
                            classes={{ containedPrimary: 'containedPrimary', outlinedPrimary: 'outlinedPrimary' }}
                            label={a.title}
                            name={a.title}
                            disabled={a.disabled}
                            style={a.style}
                            key={i}
                            color={a.danger ? 'secondary' : 'primary'}
                            variant={a.primary ? 'contained' : 'outlined'}
                            onClick={() => {
                                if (typeof a.handler === 'function') {
                                    a.handler();
                                }
                                if (!notAutoClose) {
                                    this.close();
                                }
                            }}
                        >
                            {a.title}
                        </Button>
                    ))}
                </DialogActions>
                }
            </MuiDialog>
        );
    }
}

export default Dialog;
