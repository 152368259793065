import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import EmptyState from '../../../components/EmptyState';

const GET_ORGANIZATIONS_IDS = gql`query getOrganizationsIds { me { organizations { id } } }`;

const RedirectEntertainment = ({ tab }) => {
    const { loading, data, error } = useQuery(GET_ORGANIZATIONS_IDS);
    const orgs = data?.me.organizations.map(o => o.id) || [];
    const redirectURL = `/organizations/${orgs[0]}/entertainment?tab=${tab || 'latest'}`;

    if (loading) return <div className="Organizations"><LoadingOverlay/></div>;

    if (error || !orgs[0]) return <EmptyState type="ERROR"/>;

    return <Redirect to={redirectURL}/>;
};

export default RedirectEntertainment;