import Entity from "../Entity";
import Organization from "../organizations/Organization";
import * as schedulesActions from "../plutoSchedules/actions";

class PlutoChannel extends Entity {

    constructor() {
        super('plutoChannels');
    }


    reducer(state = this.initialState, action) {
        switch (action.type) {

            case schedulesActions.CREATE_SCHEDULE: {
                const {channelId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isCreatingSchedule: true,
                            errorCreatingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.CREATE_SCHEDULE_SUCCESS: {
                const {channelId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isCreatingSchedule: false,
                            errorCreatingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.CREATE_SCHEDULE_FAILURE: {
                const {channelId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isCreatingSchedule: false,
                            errorCreatingSchedule: error,
                        }
                    },
                };
            }

            case schedulesActions.DELETE_SCHEDULE: {
                const {channelId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: true,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.DELETE_SCHEDULE_SUCCESS: {
                const {channelId} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case schedulesActions.DELETE_SCHEDULE_FAILURE: {
                const {channelId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: error,
                        }
                    },
                };
            }

            default: {
                return super.reducer(state, action);
            }

        }
    }

    isCreatingSchedule(state, id) {
        return !!this.getMetadata(state, id).isCreatingSchedule;
    }

    isDeletingSchedule(state, id) {
        return !!this.getMetadata(state, id).isDeletingSchedule;
    }

    getPlutoChannelsByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getPlutoChannelsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

}

export default new PlutoChannel();