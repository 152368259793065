import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ButtonCreateNew from './ButtonCreateNew';
import Organization from '../../../redux/modules/entities/organizations/Organization';
import SpotlightAsset from '../../../redux/modules/entities/spotlightAssets/SpotlightAsset';
import { getSpotlightAssetByType } from "../../../helpers/normalizeSpotlight";

const mapStateToProps = (state) => {
    const organizationId = Organization.getCurrentOrganizationId(state);

    return {
        organizationId,
        brandkit: Organization.getCurrentOrganizationBrandkitMap(state),
        defaultBackgrounds: SpotlightAsset.getSpotlightAssetsByOrganization(state, organizationId)
    }
};

const mapDispatchToProps = (dispatch) => ({
    getDefaultSpotlightAssets(organizationId) {
        getSpotlightAssetByType(dispatch, organizationId, 'image');
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ButtonCreateNew));
