import Entity from "../Entity";
import Organization from "../organizations/Organization";
import PlutoChannel from "../plutoChannels/PlutoChannel";
import { scheduleisActive } from "@upshow/sched-utils";
import * as actions from "./actions";

class PlutoSchedule extends Entity {

    constructor() {
        super('plutoSchedules', {
            organization: Organization,
            channel: PlutoChannel,
        });
    }

    reducer(state = this.initialState, action) {

        switch (action.type) {

            case actions.DELETE_SCHEDULE: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: true,
                            errorDeleting: null,
                        }
                    },
                };
            }

            case actions.DELETE_SCHEDULE_SUCCESS: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: false,
                            errorDeleting: false,
                        }
                    },
                };
            }

            case actions.DELETE_SCHEDULE_FAILURE: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: false,
                            errorDeleting: false,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES: {
                const {channelId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: true,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES_SUCCESS: {
                const {channelId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES_FAILURE: {
                const {channelId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: error,
                        }
                    },
                };
            }


            default: {
                return super.reducer(state, action);
            }

        }
    }

    getPlutoSchedulesByOrganization(state, organizationId, denormalized = true) {
        const ids = Organization.getPlutoSchedulesIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getPlutoSchedulesByOrganizationAndChannel(state, organizationId, channelId) {
        const schedules = this.getPlutoSchedulesByOrganization(state, organizationId);
        return schedules.filter(s => s.channel.id === channelId);
    }

    getActivePlutoChannelsIdsByOrganization(state, organizationId) {
        const schedules = this.getPlutoSchedulesByOrganization(state, organizationId);

        const byChannel = schedules.reduce((acc, schedule) => {
            const channelId = schedule.channel.id;
            return {
                ...acc,
                [channelId]: Array.isArray(acc[channelId]) ? [...acc[channelId], schedule] : [schedule],
            };
        }, {});


        const activeChannelsIds = [];

        for (let channelId of Object.keys(byChannel)) {
            const lastSchedule = byChannel[channelId];
            if(lastSchedule.length > 0 && !!lastSchedule.find(sch => sch.type !== "negative")){
                activeChannelsIds.push(channelId);
            }
        }

        return activeChannelsIds;
    }

    getOnScreenPlutoChannelsIdsByOrganization(state, organizationId) {
        const schedules = this.getPlutoSchedulesByOrganization(state, organizationId);
        const currentIsoDate = (new Date()).toISOString();
        const timezone = Organization.getDefaultTimezoneName(state);
        return schedules.filter( schedule => {
            return scheduleisActive(currentIsoDate, schedule, timezone)
        });
    }

    getActivePlutoChannelsByOrganization(state, organizationId, denormalized = false) {
        const ids = this.getActivePlutoChannelsIdsByOrganization(state, organizationId);
        return PlutoChannel.findByIds(state, ids, denormalized);
    }

}

export default new PlutoSchedule();