import { graphqlQuery } from "../axios";
import _get from "lodash/get";

export function getJukinSchedules(organizationId) {
  return graphqlQuery({
      query: `query getJukinSchedules($org_id: Int!, $include_parents: Boolean = false){
                  Organization(id: $org_id) {
                    MediaChannelSchedules(includeParents: $include_parents) {
                        id
                        mondays
                        tuesdays
                        wednesdays
                        thursdays
                        fridays
                        saturdays
                        sundays
                        validFrom
                        validThrough
                        validFromTime
                        validThroughTime
                        position
                        behavior
                        tz
                        organization: public_organization {
                          id
                          name
                        }
                        type
                        feed: channel {
                          feedType
                          id
                          title
                          thumbnail
                          is_premium
                          tags enabled
                        }
                    }
                }
        }`, variables: {org_id: organizationId, include_parents: true}
  }).then(response => _get(response, 'data.data.Organization.MediaChannelSchedules', []));
}

export function createJukinSchedule(organizationId, schedule) {
    const payload = {
      mondays: schedule.mondays,
      tuesdays: schedule.tuesdays,
      wednesdays: schedule.wednesdays,
      thursdays: schedule.thursdays,
      fridays: schedule.fridays,
      saturdays: schedule.saturdays,
      sundays: schedule.sundays,
      validFrom: schedule.validFrom,
      validThrough: schedule.validThrough,
      validFromTime: schedule.validFromTime,
      validThroughTime: schedule.validThroughTime,
      type: schedule.type,
      behavior: schedule.behavior,
      tz: schedule.tz,
    };
    const channelId = schedule.channel_id
    if(Array.isArray(schedule.organizationIds)){
        const queries = schedule.organizationIds.reduce( (acc, org_id) => {
           return acc + `${"org_" + org_id}: orgMutation (organization_id: ${org_id}){
                    create_media_channel_schedule(id: $id, input: $input) {
                      successful
                      message
                      schedule {
                        id
                    }
                    }
                 }`
          }, '');
        return graphqlQuery({
          query: `mutation createJukinSchedule($id: Int!, $input: ScheduleInput!) {
            ${queries}
          }`, variables: {id: channelId, input: payload}
        }).then(response => _get(response, 'data', {}));
    }

  return graphqlQuery({
      query: `mutation createJukinSchedule($org_id: Int!, $id: Int!, $input: ScheduleInput!) {
                orgMutation (organization_id: $org_id){
                  create_media_channel_schedule(id: $id, input: $input) {
                    successful
                    message
                    schedule {
                      id
                    }
                  }
                }
      }`, variables: {org_id: organizationId, id: channelId, input: payload}
  }).then(response => _get(response, 'data.data.orgMutation.create_media_channel_schedule', {}));
}

export function deleteJukinSchedule(organizationId, scheduleId) {
  return graphqlQuery({
      query: `mutation deleteJukinSchedule($org_id: Int!, $id: Int!) {
                orgMutation(organization_id: $org_id) {
                  delete_media_channel_schedule(id: $id) {
                    successful
                    message
                  }
                }
      }`, variables: {org_id: organizationId, id: scheduleId}
  }).then(response => _get(response, 'data.data.orgMutation.delete_media_channel_schedule', {}));
}

export function deleteAllJukinSchedules(organizationId, channel_id) {
  return graphqlQuery({
      query: `mutation deleteAllJukinSchedules($org_id: Int!, $id: Int!) {
                orgMutation(organization_id: $org_id) {
                  clear_media_channel_schedules(id: $id) {
                    successful
                    message
                  }
                }
      }`, variables: {org_id: organizationId, id: channel_id}
  }).then(response => _get(response, 'data.data.orgMutation.clear_media_channel_schedules', {}));
}