import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";

import {scriptSchedules as scriptSchedulesApi} from "../../../../api/modules";
import * as actions from "./actions";
import scheduleAppliesToNow from "../../../../helpers/scheduleAppliesToNow";
import { scheduleAppliesToFuture } from "@upshow/sched-utils";
import ScriptSchedule from "./ScriptSchedule";
import Organization from '../organizations/Organization';

export function* getSchedules(action) {
    const {organizationId, includePastSchedules = false} = action.payload;

    try {
        const response = yield call(scriptSchedulesApi.getScriptSchedules, organizationId);

        if(response.error){
            throw new Error(response);
        }

        const defaultTz = yield select(state => Organization.getCurrentTimezoneName(state));
        const activeSchedules = includePastSchedules ? response.schedules : response.schedules.filter((schedule) => scheduleAppliesToNow(schedule, defaultTz) || scheduleAppliesToFuture(schedule, defaultTz));
        const {result, entities} = ScriptSchedule.parseArray(activeSchedules);

        yield put(actions.getSchedulesSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch organization playlist schedules';
        yield call(toast.error, message);
        yield put(actions.getSchedulesFailure(organizationId, {message, error}));
    }
}

export function* watchGetSchedules() {
    yield takeEvery(actions.GET_SCHEDULES, getSchedules);
}

export function* createSchedule(action) {
    const {organizationId, scriptId, schedule, callback} = action.payload;

    try {
        const response = yield call(scriptSchedulesApi.createScriptSchedule, organizationId, scriptId, schedule);

        if(!response.successful){
            throw new Error(response);
        }

        yield call(toast.success, response.message);
        yield put(actions.createScheduleSuccess(organizationId, scriptId));
        if(typeof callback === 'function'){
            yield call(callback)
        } else {
            yield put(actions.getSchedules(organizationId));
        }
    } catch (error) {
        const message = 'Couldn\'t schedule playlist';
        yield call(toast.error, message);
        yield put(actions.createScheduleFailure(organizationId, scriptId, {message, error}));
    }
}

export function* watchCreateSchedule() {
    yield takeEvery(actions.CREATE_SCHEDULE, createSchedule);
}

export function* deleteSchedule(action) {
    const {organizationId, scriptId, scheduleId, callback} = action.payload;

    try {
        const response = yield call(scriptSchedulesApi.deleteScriptSchedule, organizationId, scheduleId);

        if(!response.successful){
            throw new Error(response);
        }

        yield put(actions.deleteScheduleSuccess(organizationId, scriptId, scheduleId));
        if(typeof callback === 'function'){
            yield call(callback)
        }
    } catch (error) {
        const message = 'Couldn\'t delete schedule';
        yield call(toast.error, message);
        yield put(actions.deleteScheduleFailure(organizationId, scriptId, scheduleId, {message, error}));
    }
}

export function* watchDeleteSchedule() {
    yield takeEvery(actions.DELETE_SCHEDULE, deleteSchedule);
}

export function* deleteAllSchedules(action) {
    const {organizationId, scriptId, callback} = action.payload;

    try {
        const response = yield call(scriptSchedulesApi.deleteAllScriptSchedules, organizationId, scriptId);

        if(!response.successful){
            throw new Error(response);
        }

        yield put(actions.deleteAllSchedulesSuccess(organizationId, scriptId));
        if(typeof callback === 'function'){
            yield call(callback)
        } else {
            yield put(actions.getSchedules(organizationId));
        }

    } catch (error) {
        const message = 'Couldn\'t delete schedules';
        yield call(toast.error, message);
        yield put(actions.deleteAllSchedulesFailure(organizationId, scriptId, {message, error}));
    }
}

export function* watchDeleteAllSchedules() {
    yield takeEvery(actions.DELETE_ALL_SCHEDULES, deleteAllSchedules);
}

export default function* rootSaga() {
    yield all([
        watchGetSchedules(),
        watchCreateSchedule(),
        watchDeleteSchedule(),
        watchDeleteAllSchedules(),
    ]);
};