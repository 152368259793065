import Entity from "../Entity";
import Organization from "../organizations/Organization";
import * as actions from "./actions";

class SpotlightAsset extends Entity {

    constructor() {
        super('spotlightAssets');

        this.displayFilterTypes = {
            ALL: {value: 'ALL', label: 'All'},
            CURRENT_ORGANIZATION_ONLY: {value: 'CURRENT_ORGANIZATION_ONLY', label: 'Current Organization Only'},
            PARENT_ORGANIZATIONS_ONLY: {value: 'PARENT_ORGANIZATIONS_ONLY', label: 'Parent Organizations Only'},
        };

        this.initialState = {
            ...this.initialState,
            displayFilter: this.displayFilterTypes.ALL.value,
        }
    }


    reducer(state = this.initialState, action) {

        switch (action.type) {

            case actions.SET_DISPLAY_FILTER: {
                const {displayFilter} = action.payload;
                return {
                    ...state,
                    displayFilter,
                };
            }

            case actions.GET_SPOTLIGHT_ASSET: {
                const {spotlightAssetId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightAssetId]: {
                            ...state.metadataById[spotlightAssetId],
                            isFetching: true,
                            error: null,
                        }
                    },
                };
            }

            case actions.GET_SPOTLIGHT_ASSET_SUCCESS: {
                const {spotlightAssetId} = action.payload;
                const newState = this.shallowMergeReducer(state, action);

                return {
                    ...newState,
                    metadataById: {
                        ...newState.metadataById,
                        [spotlightAssetId]: {
                            ...newState.metadataById[spotlightAssetId],
                            isFetching: false,
                            error: null,
                        }
                    },
                };
            }

            case actions.GET_SPOTLIGHT_ASSET_FAILURE: {
                const {spotlightAssetId, error} = action.payload;

                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [spotlightAssetId]: {
                            ...state.metadataById[spotlightAssetId],
                            isFetching: false,
                            error,
                        }
                    },
                };
            }

            default: {
                return super.reducer(state, action);
            }

        }
    }

    getDisplayFilter(state) {
        return this.getState(state).displayFilter;
    }

    getSpotlightAssetsByOrganization(state, organizationId, denormalized = false) {
        const ids = Organization.getSpotlightAssetsIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getFilteredSpotlightAssetsByOrganization(state, organizationId, denormalized = false) {
        const spotlightAssets = this.getSpotlightAssetsByOrganization(state, organizationId, denormalized);
        const displayFilter = this.getDisplayFilter(state);
        let result = [];

        switch (displayFilter) {

            case this.displayFilterTypes.ALL.value: {
                result = spotlightAssets;
                break;
            }

            case this.displayFilterTypes.CURRENT_ORGANIZATION_ONLY.value: {
                result = spotlightAssets.filter(s => s.organization_id === organizationId);
                break;
            }

            case this.displayFilterTypes.PARENT_ORGANIZATIONS_ONLY.value: {
                result = spotlightAssets.filter(s => s.organization_id !== organizationId);
                break;
            }

            default: {
                result = spotlightAssets;
                break;
            }

        }

        return result;
    }

    getFilteredSpotlightAssetsIdsByOrganization(state, organizationId) {
        return this.getFilteredSpotlightAssetsByOrganization(state, organizationId).map(s => s.id);
    }

    isFetching(state, id) {
        return !!this.getMetadata(state, id).isFetching;
    }

    errorFetching(state, id) {
        return this.getMetadata(state, id).errorFetching;
    }

}

export default new SpotlightAsset();