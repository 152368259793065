const NAMESPACE = 'entities/content/';

export const GET_CONTENT = `${NAMESPACE}GET_CONTENT`;

export function getContent(organizationId) {
    return {
        type: GET_CONTENT,
        payload: {
            organizationId,
        }
    };
}


export const GET_CONTENT_SUCCESS = `${NAMESPACE}GET_CONTENT_SUCCESS`;

export function getContentSuccess(organizationId, contentIds, entities) {
    return {
        type: GET_CONTENT_SUCCESS,
        payload: {
            organizationId,
            contentIds,
        },
        entities,
    };
}


export const GET_CONTENT_FAILURE = `${NAMESPACE}GET_CONTENT_FAILURE`;

export function getContentFailure(organizationId, error) {
    return {
        type: GET_CONTENT_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}


export const BAN_CONTENT = `${NAMESPACE}BAN_CONTENT`;

export function banContent(organizationId, contentId) {
    return {
        type: BAN_CONTENT,
        payload: {
            organizationId,
            contentId,
        }
    };
}


export const BAN_CONTENT_SUCCESS = `${NAMESPACE}BAN_CONTENT_SUCCESS`;

export function banContentSuccess(organizationId, contentId) {
    return {
        type: BAN_CONTENT_SUCCESS,
        payload: {
            organizationId,
            contentId,
        },
    };
}


export const BAN_CONTENT_FAILURE = `${NAMESPACE}BAN_CONTENT_FAILURE`;

export function banContentFailure(organizationId, contentId, error) {
    return {
        type: BAN_CONTENT_FAILURE,
        payload: {
            organizationId,
            contentId,
            error,
        }
    };
}

export const BAN_CONTENTS = `${NAMESPACE}BAN_CONTENTS`;

export function banContents(organizationId, contentIds) {
    return {
        type: BAN_CONTENTS,
        payload: {
            organizationId,
            contentIds,
        }
    };
}

export const BAN_CONTENTS_SUCCESS = `${NAMESPACE}BAN_CONTENTS_SUCCESS`;

export function banContentsSuccess(organizationId, contentIds) {
    return {
        type: BAN_CONTENTS_SUCCESS,
        payload: {
            organizationId,
            contentIds,
        },
    };
}

export const BAN_CONTENTS_FAILURE = `${NAMESPACE}BAN_CONTENTS_FAILURE`;

export function banContentsFailure(organizationId, contentIds, error) {
    return {
        type: BAN_CONTENTS_FAILURE,
        payload: {
            organizationId,
            contentIds,
            error,
        }
    };
}


export const SELECT_CONTENT = `${NAMESPACE}SELECT_CONTENT`;

export function selectContent(contentId) {
    return {
        type: SELECT_CONTENT,
        payload: {
            contentId,
        }
    };
}

export const DESELECT_CONTENT = `${NAMESPACE}DESELECT_CONTENT`;

export function deselectContent(contentId) {
    return {
        type: DESELECT_CONTENT,
        payload: {
            contentId,
        }
    };
}

export const CLEAR_SELECT_CONTENT = `${NAMESPACE}CLEAR_SELECT_CONTENT`;

export function clearSelectContent() {
    return {
        type: CLEAR_SELECT_CONTENT,
        payload: {  }
    };
}