import {all, call, put, takeEvery, select} from "redux-saga/effects";
import {toast} from "react-toastify";
import {organizations as organizationsApi} from "../../../../api/modules";
import Organization from "./Organization";
import {
    SWITCH_ORGANIZATION,
    switchOrganizationSuccess,
    switchOrganizationFailure,
    getOrganizationTimezonesSuccess,
    getOrganizationTimezonesFailure,
    GET_ORGANIZATION,
    getOrganization,
    getOrganizationSuccess,
    getOrganizationFailure,
} from "./actions";
import { closeOrgTreeModal } from "../../session/actions";


export function* switchOrganization(action) {
    const {organizationPath} = action.payload;

    try {
        const pathIsValid = yield select(state => Organization.organizationPathIsValid(state, organizationPath));
        if (pathIsValid) {
            const nextOrg = yield select(state => Organization.findById(state, organizationPath.slice(-1)));
            const slicedOrgName = nextOrg.name.length > 25 ? nextOrg.name.slice(0, 25) + '...' : nextOrg.name;
            yield put(closeOrgTreeModal());
            yield put(getOrganization(nextOrg.id));
            yield put(switchOrganizationSuccess(organizationPath, nextOrg.id));
            yield call(toast.success, `Switched to "${slicedOrgName}" organization`);
        } else {
            yield put(switchOrganizationFailure(organizationPath, 'Organization path is not valid'));
        }
    } catch (e) {
        yield put(switchOrganizationFailure(organizationPath, 'Organization path is not valid'));
    }
}

export function* watchSwitchOrganization() {
    yield takeEvery(SWITCH_ORGANIZATION, switchOrganization);
}

export function* getOrganizationInfo(action) {
    const {organizationId} = action.payload;

    try {
        const { organization, timezones } = yield call(organizationsApi.getOrganization, organizationId);
        const {entities} = Organization.parse(organization);

        yield put(getOrganizationTimezonesSuccess(timezones));
        yield put(getOrganizationSuccess(organizationId, entities));
    } catch (e) {
        yield put(getOrganizationTimezonesFailure(organizationId, 'Couldn\'t access org timezones'));
        yield put(getOrganizationFailure("Error getting organization"));
    }
}

export function* watchGetOrganization() {
    yield takeEvery(GET_ORGANIZATION, getOrganizationInfo);
}

export default function* rootSaga() {
    yield all([
        watchGetOrganization(),
        watchSwitchOrganization(),
    ]);
}