import fonts from '../assets/fonts/fonts.json';
import bluebird from 'bluebird';
import { toast } from 'react-toastify';

export const loadFont = async fontName => {
    const fonts = [...document.fonts];
    if (!fonts.some(f => f.family === fontName))  {
        const newFont = new FontFace(fontName, `url(${getCustomFontURL(fontName)})`);
        await newFont.load();
        document.fonts.add(newFont);
    }
    return fontName;
};

export const getAllFonts = async (fontSettings = []) => {
    const customFonts = await bluebird.map(fontSettings.map(setting => (setting.key.split('.')[1])), fontName => {
        return loadFont(fontName).catch(error => {
            toast.error(`Error loading font ${fontName}`);
            console.error(error)
            return null
        })
    }).filter(f => !!f);

    return [...customFonts.map(fontName => ({ 'value': fontName, 'label': fontName })), ...fonts];
};


export const isCustomFont = (fontName) => {
    return !!fontName && !fonts.map(font => font.value).includes(fontName);
};

export const getCustomFontURL = (font) => `${process.env.REACT_APP_STATIC_CDN_ROOT}/custom_fonts/${font}.ttf`

