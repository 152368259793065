import Interceptor from "../Interceptor";
import store from "../../../../redux/store";
import { getToken } from '@upshow/auth';

export default new Interceptor(async (config) => {

    const state = store.getState();
    const token = await getToken(state);
    if (!token) {
        return config;
    }

    return {
        ...config,
        headers: {
            Authorization: `Bearer ${token}`,
            ...config.headers,
        },
    };

});