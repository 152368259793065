import React, { useContext, useEffect, useState } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { isValidEmail } from "@upshow/validators";
import * as Sentry from '@sentry/react';
import { AuthContext } from "@upshow/auth";

import { getUserInfo } from "../../redux/modules/session/selectors";
import { deleteCookie, getCookie, setCookie } from "../../helpers/cookies";
import { GenericMutationCompletedHelper, GenericMutationErrorHandler } from "../../helpers/genericMutationHandler";
import MessageActionBar from "../MessageActionBar";
import { GET_USER_INFO } from "../../redux/modules/session/actions";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Button, Dialog, DialogContent, DialogTitle, Input, InputLabel } from "@mui/material";

import "./UsernameWarning.scss";

const WARNING_USERNAME_COOKIE =  "warning-username-deprecated-postponed"

const UPDATE_USER = gql`
    mutation replaceUsernameWithNewEmail($email: String!) {
        replaceUsernameWithNewEmail(email: $email) {
            successful
            message
            user {
                username
            }
        }
    }
`;

export default function UsernameWarning() {
    const { email, settings } = useSelector(getUserInfo);
	const dispatch = useDispatch()
    const isWarningPostponed = !!getCookie(WARNING_USERNAME_COOKIE);

    const [newEmail, setNewEmail] = useState("");
    const [confirmNewEmail, setConfirmNewEmail] = useState("");
    const [open, setOpen] = useState(false);

    const [showWarn, setShowWarn] = useState(true);

    const client = useApolloClient();
    const { oktaAuth } = useContext(AuthContext);

    const [updateUserMutation, { loading }] = useMutation(UPDATE_USER, {
        variables: { email: newEmail },
        onError: GenericMutationErrorHandler,
        onCompleted: async ({ replaceUsernameWithNewEmail }) => {
            if (
                GenericMutationCompletedHelper(replaceUsernameWithNewEmail, `Your new login credential is ${replaceUsernameWithNewEmail.user && replaceUsernameWithNewEmail.user.username}`, {
                    autoClose: false,
                    closeOnClick: false,
                    closeButton: true,
                    draggable: false,
                })
            ) {
				dispatch({ type: GET_USER_INFO });
                deleteCookie(WARNING_USERNAME_COOKIE);
                await oktaAuth.signOut().then(() => {
                    client.cache.reset();
                });
                Sentry.configureScope(scope => scope.setUser(null));
            }
        },
    });

    useEffect(() => {
        if (email) {
            setNewEmail(email);
        }
    }, [email]);

    const shouldRenderWarning = !!settings?.validate_email && showWarn && !isWarningPostponed;

    const upshowEmailValid = !newEmail.toLowerCase().includes("@upshow.tv") || email === newEmail
    const validEmail = isValidEmail(newEmail) && upshowEmailValid;

    if (shouldRenderWarning)
        return (
            <>
                <MessageActionBar
                    variant="alert"
                    buttonText="Validate Email"
                    titleText="Action Required"
                    buttonAction={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        /* If this cookie is on scope, the warning will not be displayed */
                        setCookie(WARNING_USERNAME_COOKIE, true, 24);
                        setShowWarn(false);
                    }}
                    titleIcon={<ReportProblemOutlinedIcon fontSize="small" />}
                    description={
                        <span>
                            Starting soon, your email address will be the only thing you need to log into UPshow. Since you are currently using a username, click the button to validate and transition to signing in with your email now. If you opt to not transition now we will transition you at a later date.
                        </span>
                    }
                />
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Verify your email</DialogTitle>
                    <DialogContent className="input-container">
                        <div>
                            <InputLabel htmlFor="userEmail">Enter email</InputLabel>
                            <Input name="userEmail" id="userEmail" value={newEmail} onChange={(evt) => setNewEmail(evt.target.value.replace(/ /g, ""))} invalid={!validEmail} feedback="Email not valid" />
                        </div>
                        <div>
                            <InputLabel htmlFor="userEmailConfirmation">Confirm your email</InputLabel>
                            <Input name="userEmailConfirmation" id="userEmailConfirmation" onChange={(evt) => setConfirmNewEmail(evt.target.value.replace(/ /g, ""))} invalid={newEmail !== confirmNewEmail} feedback="Email not valid" />
                        </div>
                        <div className="button-container">
                            <Button className="cancel-btn" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button onClick={updateUserMutation} disabled={loading || !validEmail || newEmail !== confirmNewEmail} color="primary" variant="contained">
                                Confirm
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );

    return null;
}
