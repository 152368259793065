import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import Organizations from '../Organizations';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import GlobalDialog from '../../components/GlobalDialog';
import RedirectSpotlight from './Redirect/RedirectSpotlight';
import RedirectEntertainment from './Redirect/RedirectEntertainment';
import { client } from '../../api/axios';
import UsernameWarning from '../../components/UsernameWarning';

import './Home.scss';

const Home = () => {
    return (
        <ApolloProvider client={client}>
            <div className="Home">
                <SideBar/>
                <div className="page-container">
                    <TopBar/>
                    <UsernameWarning />
                    <Switch>
                        <Route path="/organizations" component={Organizations}/>
                        <Route path="/org/:orgId/spt/:sptId" children={<RedirectSpotlight/>}/>
                        <Route path="/games" children={<RedirectEntertainment tab='upshow_games'/>}/>
                        <Route path="/entertainment" children={<RedirectEntertainment/>}/>
                        <Route render={() => <Redirect to="/organizations"/>}/>
                    </Switch>
                </div>
            </div>
            <GlobalDialog/>
        </ApolloProvider>
    );
};

export default Home;
