import { graphqlQuery } from "../axios";
import _get from "lodash/get";

export function createTrackingCode(org_id, destination, spotlightId, logo, value) {
    return graphqlQuery({
        query: `mutation createTrackingCode($org_id: Int!, $destination: String!, $spotlightId: Int $logo: String, $value: Float){
            orgMutation(organization_id: $org_id){
                create_tracking_code(destination: $destination, spotlight_id: $spotlightId logo: $logo, engagement_value: $value){
                    status
                    successful
                    message
                    TrackingCode {
                        id
                        domain
                        destination
                        tracking_code
                        logo
                        engagement_value
                        Organization{
                            id
                            name
                        }
                        Spotlight{
                            id
                            title
                        }
                    }
                }
            }
        }`, variables: {org_id, destination, spotlightId, logo, value}
    }).then(response => _get(response, 'data.data.orgMutation.create_tracking_code', {}));
}

export function updateTrackingCode(org_id, code_id, destination, spotlightId, logo, value) {
    return graphqlQuery({
        query: `mutation updateTrackingCode($org_id: Int!, $code_id: Int!, $destination: String!, $spotlightId: Int $logo: String, $value: Float){
            orgMutation(organization_id: $org_id){
                update_tracking_code(id: $code_id, destination: $destination, spotlight_id: $spotlightId logo: $logo, engagement_value: $value){
                    status
                    successful
                    message
                    TrackingCode {
                        id
                        domain
                        destination
                        tracking_code
                        logo
                        engagement_value
                        Organization{
                            id
                            name
                        }
                        Spotlight{
                            id
                            title
                        }
                    }
                }
            }
        }`, variables: {org_id, code_id, destination, spotlightId, logo, value}
    }).then(response => _get(response, 'data.data.orgMutation.update_tracking_code', {}));
}