import React from "react";
import _isEqual from 'lodash/isEqual';
import BusinessIcon from "@mui/icons-material/Business";

import { stringify } from "../../helpers/organizationPath";
import BreadcrumbDropdown from './BreadcrumbDropdown';
import BreadcrumbPath from './BreadcrumbPath';

import "./Breadcrumb.scss";

class Breadcrumb extends React.Component {

    state = {
        orgs: [],
        selectedPath: null,
        currentOrg: null
    };

    createPathToCurrentOrganization(rootOrganizations, currentOrg) {
        const path = [...currentOrg.path];
        const currentOrgPath = [];
        return this.createOrganizationPathRec(rootOrganizations, path, currentOrgPath);
    }

    createOrganizationPathRec(rootOrganizations, path, currentOrgPath) {
        if (path.length > 0) {
            const pathStep = rootOrganizations.find((org) => {
                return org.id === path[0];
            });
            if(!pathStep){
                return currentOrgPath;
            }
            if (path.length > 1 && !pathStep.leaf && !!pathStep.children && pathStep.children.length > 0) {
                pathStep.children = pathStep.children.map((child) => {
                    if (child.id === path[1]) {
                        child.isNext = true;
                    }
                    return child;
                });
            }
            currentOrgPath.push(pathStep);
            path.shift();
            return this.createOrganizationPathRec(pathStep.children, path, currentOrgPath)
        } else {
            return currentOrgPath;
        }
    }

    componentDidMount() {
        this.updateState({});
    }

    componentDidUpdate(prevProps) {
        this.updateState(prevProps);
    }

    updateState(prevProps) {
        const { rootOrganizations, currentOrganization } = this.props;
        let newState = {};

        if (!_isEqual(prevProps.currentOrganization, currentOrganization) && !!this.props.currentOrgPathIds) {
            currentOrganization.path = this.props.currentOrgPathIds;
            newState = { currentOrg: currentOrganization };
        }

        if ((!_isEqual(this.props.location, prevProps.location) ||
            !_isEqual(prevProps.rootOrganizations, rootOrganizations)
        ) && !!rootOrganizations && !!currentOrganization) {
            let orgs = this.parseOrgs([...rootOrganizations], []);
            const selectedPath = this.createPathToCurrentOrganization(orgs, currentOrganization);
            orgs = orgs.map((org) => {
                if (selectedPath[0] && org.id === selectedPath[0].id) {
                    org.isNext = true;
                }
                return org;
            })
            newState = { ...newState, orgs: orgs, selectedPath: selectedPath };
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
    }

    buildUrl(prefix, organizationPath) {
        const { pathname, search, hash } = this.props.location;
        const splittedPath = pathname.slice(1).split('/');
        const stringifiedOrgPath = stringify(organizationPath);

        const newPath = [
            prefix,
            stringifiedOrgPath,
            ...splittedPath.slice(2),
        ].join('/');

        return location.href.includes('/draft') ? `/${prefix}/${stringifiedOrgPath}/promotions` : `/${newPath}${search}${hash}`;
    }

    parseOrgs(organizations = [], carry = []) {
        return organizations.map(org => {
            const itemPath = [...carry, org.id];
            org.path = itemPath;
            org.url = this.buildUrl('organizations', itemPath);
            if (org.children.length > 0) {
                org.children = this.parseOrgs(org.children, itemPath);
            }
            return org;
        });
    }

    render() {
        const { orgs, currentOrg, selectedPath } = this.state;
        const { isLoading, errorLoading, isSwitchingOrganization, errorSwitchingOrganization } = this.props;

        if (isLoading || errorLoading || isSwitchingOrganization
            || errorSwitchingOrganization || !selectedPath || !currentOrg) {
            return <div className="breadcrumb" />;
        }

        return (
            <div className="breadcrumb">
                <div className={"breadcrumbItem"}>
                    <span>{<BusinessIcon />}</span>
                    <BreadcrumbDropdown
                        leaf={false}
                        orgList={orgs}
                        path={"root"} />
                </div>
                <div className="triangle" />
                <BreadcrumbPath path={selectedPath} currentOrg={currentOrg} />
            </div>
        );
    }
}

export default Breadcrumb;