const NAMESPACE = 'entities/jukinFeeds/';

export const GET_JUKIN_FEEDS = `${NAMESPACE}GET_JUKIN_FEEDS`;

export function getJukinFeeds (organizationId) {
    return {
        type: GET_JUKIN_FEEDS,
        payload: {
            organizationId,
        }
    };
}

export const GET_JUKIN_FEEDS_SUCCESS = `${NAMESPACE}GET_JUKIN_FEEDS_SUCCESS`;

export function getJukinFeedsSuccess (organizationId, jukinFeedsIds, entities) {
    return {
        type: GET_JUKIN_FEEDS_SUCCESS,
        payload: {
            organizationId,
            jukinFeedsIds,
        },
        entities,
    };
}

export const GET_JUKIN_FEEDS_FAILURE = `${NAMESPACE}GET_JUKIN_FEEDS_FAILURE`;

export function getJukinFeedsFailure (organizationId, error) {
    return {
        type: GET_JUKIN_FEEDS_FAILURE,
        payload: {
            organizationId,
            error,
        }
    };
}