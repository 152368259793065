import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import Button from '@mui/material/Button';

import ErrorView from '../../components/ErrorView/ErrorView';
import EmptyState from '../../components/EmptyState';
import Organization from '../Organization';

import './Organizations.scss';

class Organizations extends React.Component {
    componentDidMount () {
        const { getUserInfo } = this.props;

        getUserInfo();
    }

    render () {
        const { isLoading, errorLoading, hasOrganizations, firstRootOrgId, match } = this.props;

        const errorButton = (
            <Button
                key="organizations-error-reload-btn"
                variant="contained"
                color="primary"
                className="button organizations-error-reload-btn"
                onClick={() => window.location.href = '/'}>
                Try Again
            </Button>
        );

        if (!isLoading && errorLoading) return <EmptyState type="ERROR" title="Error loading organizations" actions={[errorButton]}/>;

        if (!isLoading && !hasOrganizations) return <ErrorView title="No organizations for this user"/>;

        return (
            <div className="Organizations">
                {hasOrganizations && (
                    <Switch>
                        <Route path={`${match.url}/:orgPath`} component={Organization}/>
                        <Route render={() => <Redirect to={`${match.url}/${firstRootOrgId}`}/>}/>
                    </Switch>
                )}
            </div>
        );
    }

}

Organizations.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    errorLoading: PropTypes.string,
    firstRootOrgId: PropTypes.number,
    hasOrganizations: PropTypes.bool.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired
};

export default Organizations;
