import { graphqlQuery } from "../axios";

export function getPlutoChannels() {
    return graphqlQuery({
        query: `query ecp_getPlutoChannels{
            PlutoTvChannels {
                    id
                    url
                    title
                    thumbnail
                    description
                    feedType
                }
            }`, variables: {}
    }).then(response => response?.data?.data?.PlutoTvChannels ?? []);
}