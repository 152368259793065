import 'react-app-polyfill/ie11'; // For IE 11 support
import * as Sentry from "@sentry/react";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import {unregister} from './registerServiceWorker';

import "./index.scss";
import App from './components/App';
import store from "./redux/store";
import { theme } from "./muiTheme";
import ErrorBoundary from './components/ErrorBoundary';
import packageInfo from '../package.json';
const { name, version } = packageInfo;

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    normalizeDepth: 8,
    enabled: !!+process.env.REACT_APP_SENTRY_ENABLED,
    environment: process.env.NODE_ENV,
    release: name + '@' + version,
})

ReactDOM.render(
    (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <ReduxProvider store={store}>
                    <App />
                </ReduxProvider>
            </ErrorBoundary>
        </ThemeProvider>
    ),
    document.getElementById('root')
);

//remove service worker, to clear caches
unregister();

