import {connect} from "react-redux";
import Breadcrumb from "./Breadcrumb";
import Organization from "../../redux/modules/entities/organizations/Organization";
import { withRouter } from "react-router-dom";
import { isFetchingUserInfo } from '../../redux/modules/session/selectors';

const mapStateToProps = (state) => {
    const currentOrganization = Organization.getCurrentOrganization(state);
    const currentOrgPathIds = Organization.getCurrentOrganizationPathIds(state);
    const rootOrganizations = Organization.getRootOrganizations(state, true);

    return {
        currentOrganization,
        currentOrgPathIds,
        isLoading: isFetchingUserInfo(state),
        errorLoading: Organization.errorFetchingRootOrganizations(state),
        isSwitchingOrganization: Organization.isSwitchingOrganization(state),
        errorSwitchingOrganization: Organization.errorSwitchingOrganization(state),
        rootOrganizations
    }
};

export default withRouter(connect(mapStateToProps)(Breadcrumb));