import Entity from "../Entity";
import Organization from "../organizations/Organization";
import { scheduleisActive } from "@upshow/sched-utils";
import * as actions from "./actions";

class TriviaSchedule extends Entity {

    constructor() {
        super('triviaSchedules', {
            organization: Organization,
        });
    }

    reducer(state = this.initialState, action) {

        switch (action.type) {

            case actions.DELETE_SCHEDULE: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: true,
                            errorDeleting: null,
                        }
                    },
                };
            }

            case actions.DELETE_SCHEDULE_SUCCESS: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: false,
                            errorDeleting: false,
                        }
                    },
                };
            }

            case actions.DELETE_SCHEDULE_FAILURE: {
                const {scheduleId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [scheduleId]: {
                            ...state.metadataById[scheduleId],
                            isDeleting: false,
                            errorDeleting: false,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES: {
                const {channelId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: true,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES_SUCCESS: {
                const {channelId} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: null,
                        }
                    },
                };
            }

            case actions.DELETE_ALL_SCHEDULES_FAILURE: {
                const {channelId, error} = action.payload;
                return {
                    ...state,
                    metadataById: {
                        ...state.metadataById,
                        [channelId]: {
                            ...state.metadataById[channelId],
                            isDeletingSchedule: false,
                            errorDeletingSchedule: error,
                        }
                    },
                };
            }

            default: {
                return super.reducer(state, action);
            }

        }
    }

    getTriviaSchedulesByOrganization(state, organizationId, denormalized = true) {
        const ids = Organization.getTriviaSchedulesIds(state, organizationId);
        return this.findByIds(state, ids, denormalized).map(sch => {
            sch.tz = Organization.getDefaultTimezoneName(state);
            return sch;
        });
    }

    getOnScreenTriviaSchedulesByOrganization(state, organizationId) {
        const schedules = this.getTriviaSchedulesByOrganization(state, organizationId);
        const currentIsoDate = (new Date()).toISOString();
        const timezone = Organization.getDefaultTimezoneName(state);
        return schedules.filter( schedule => {
            return scheduleisActive(currentIsoDate, schedule, timezone)
        });
    }

}

export default new TriviaSchedule();