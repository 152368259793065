import Entity from '../Entity';
import Organization from '../organizations/Organization';
import UpshowNowChannel from '../upshowNowChannels/UpshowNowChannel';

class UpshowNowSchedule extends Entity {

    constructor () {
        super('upshowNowSchedules', {
            organization: Organization,
            channel: UpshowNowChannel,
        });
    }

    reducer (state = this.initialState, action) {
        return super.reducer(state, action);
    }

    getUpshowNowSchedulesByOrganization (state, organizationId, denormalized = true) {
        const ids = Organization.getUpshowNowSchedulesIds(state, organizationId);
        return this.findByIds(state, ids, denormalized);
    }

    getUpshowNowSchedulesByOrganizationAndChannel (state, organizationId, channelId) {
        const schedules = this.getUpshowNowSchedulesByOrganization(state, organizationId);
        return schedules.filter(s => s.channel.id === channelId);
    }

    getActiveUpshowNowChannelsIdsByOrganization (state, organizationId) {
        const schedules = this.getUpshowNowSchedulesByOrganization(state, organizationId);

        const byChannel = schedules.reduce((acc, schedule) => {
            const channelId = schedule.channel.id;
            return {
                ...acc,
                [channelId]: Array.isArray(acc[channelId]) ? [...acc[channelId], schedule] : [schedule],
            };
        }, {});

        const activeChannelsIds = [];

        for (let channelId of Object.keys(byChannel)) {
            const lastSchedule = byChannel[channelId];
            if (lastSchedule.length > 0 && !!lastSchedule.find(sch => sch.type !== 'negative')) {
                activeChannelsIds.push(channelId);
            }
        }

        return activeChannelsIds;
    }

    getActiveUpshowNowChannelsByOrganization (state, organizationId, denormalized = false) {
        const ids = this.getActiveUpshowNowChannelsIdsByOrganization(state, organizationId);
        return UpshowNowChannel.findByIds(state, ids, denormalized);
    }

}

export default new UpshowNowSchedule();