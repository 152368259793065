import {all} from "redux-saga/effects";

import content from "./content/sagas";
import organizations from "./organizations/sagas";
import spotlights from "./spotlights/sagas";
import spotlightAssets from "./spotlightAssets/sagas";
import spotlightBackgrounds from "./spotlightBackgrounds/sagas";
import upshowNowChannels from "./upshowNowChannels/sagas";
import upshowNowSchedules from "./upshowNowSchedules/sagas";
import triviaSchedules from "./triviaSchedules/sagas";
import plutoChannels from "./plutoChannels/sagas";
import plutoSchedules from "./plutoSchedules/sagas";
import jukinFeeds from "./jukinFeeds/sagas";
import jukinSchedules from "./jukinSchedules/sagas";
import organizationsSettings from "./organizationsSettings/sagas";
import uiScripts from "./uiScripts/sagas";
import scriptSchedules from "./scriptSchedules/sagas";


export default function* rootSaga() {
    yield all([
        content(),
        organizations(),
        spotlights(),
        spotlightAssets(),
        spotlightBackgrounds(),
        upshowNowChannels(),
        upshowNowSchedules(),
        plutoChannels(),
        plutoSchedules(),
        jukinFeeds(),
        jukinSchedules(),
        organizationsSettings(),
        triviaSchedules(),
        uiScripts(),
        scriptSchedules(),
    ]);
}