import {all, call, put, takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";
import { uiScripts as apiUiScripts } from "../../../../api/modules";
import { redirectToRelativeUrl } from "../organizations/actions";
import { closeMixesSidePanel } from "../organizationsSettings/actions";
import * as actions from "./actions";
import UiScripts from "./UiScripts";


export function* getUiScripts(action) {
    const {organizationId} = action.payload;
    try {
        let response = yield call(apiUiScripts.getUiScripts, organizationId);

        if(!!response.error){
            throw response.error;
        }
        const {result, entities} = UiScripts.parseArray(response.data);
        yield put(actions.getUiScriptsSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t fetch playlists';
        yield call(toast.error, message);
        yield put(actions.getUiScriptsFailure(organizationId, {message, error}));
    }

}

export function* watchGetUiScripts() {
    yield takeEvery(actions.GET_UI_SCRIPTS, getUiScripts);
}


export function* getAccountsScript(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(apiUiScripts.getAccountsScript, organizationId);

        if(!!response.error){
            throw new Error(response);
        }

        const {result, entities} = UiScripts.parseArray(response.data);
        yield put(actions.getAccountsScriptSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t get account scripts';
        yield call(toast.error, message);
        yield put(actions.getAccountsScriptFailure(organizationId, {message, error}));
    }
}

export function* watchgetAccountsScript() {
    yield takeEvery(actions.GET_ACCOUNTS_SCRIPT, getAccountsScript);
}


export function* getDevicesScript(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(apiUiScripts.getDevicesScript, organizationId);

        if(!!response.error){
            throw new Error(response);
        }

        const {result, entities} = UiScripts.parseArray(response.data);
        yield put(actions.getDevicesScriptSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t get devices script';
        yield call(toast.error, message);
        yield put(actions.getDevicesScriptFailure(organizationId, {message, error}));
    }
}

export function* watchgetDevicesScript() {
    yield takeEvery(actions.GET_DEVICES_SCRIPT, getDevicesScript);
}


export function* getDefaultOrgScript(action) {
    const {organizationId} = action.payload;

    try {
        const response = yield call(apiUiScripts.getDefaultOrgScript, organizationId);

        if(!!response.error){
            throw new Error(response);
        }

        const {result, entities} = UiScripts.parse(response.data);
        yield put(actions.getDefaultOrgScriptSuccess(organizationId, result, entities));
    } catch (error) {
        const message = 'Couldn\'t get default organization script';
        yield call(toast.error, message);
        yield put(actions.getDefaultOrgScriptFailure(organizationId, {message, error}));
    }
}

export function* watchGetDefaultOrgScript() {
    yield takeEvery(actions.GET_DEFAULT_ORG_SCRIPT, getDefaultOrgScript);
}


export function* createScript(action) {
    const {name, sequence, active, isPublic, description, title, organizationId, exit, callback} = action.payload;

    try {
        const { data , error } = yield call(apiUiScripts.createUiScript, name, sequence, active, isPublic, description, title, organizationId);

        if(!!error || !data?.successful){
            throw new Error(data?.message ?? error);
        }

        const {result, entities} = UiScripts.parse(data?.script);
        yield call(toast.success, 'Playlist created!');

        if(exit){
            yield put(redirectToRelativeUrl(`/playlists`));
        }

        yield put(actions.createScriptSuccess(organizationId, result, entities));
        if (typeof callback === 'function'){
            yield call(callback, result);
        }
    } catch (error) {
        yield call(toast.error, "Couldn't create playlist");
        yield put(actions.createScriptFailure(organizationId, error));
    }
}

export function* watchCreateScript() {
    yield takeEvery(actions.CREATE_SCRIPT, createScript);
}


export function* updateScript(action) {
    const {id, title, sequence, description, active, organizationId, exit, callback} = action.payload;

    try {
        const { data , error } = yield call(apiUiScripts.updateUiScript, id, title, sequence, description, active);

        if(!!error || !data?.successful){
            throw new Error(data?.message ?? error);
        }

        const {result, entities} = UiScripts.parse(data?.script);
        yield put(actions.updateScriptSuccess(organizationId, result, entities));
        yield call(toast.success, 'Playlist updated!');

        if(exit){
            yield put(redirectToRelativeUrl(`/playlists`));
        }

        if (typeof callback === 'function'){
            yield call(callback, result);
        }
    } catch (error) {
        yield call(toast.error, "Couldn't update playlist");
        yield put(actions.updateScriptFailure(organizationId, error));
    }
}

export function* watchUpdateScript() {
    yield takeEvery(actions.UPDATE_SCRIPT, updateScript);
}


export function* deleteScript(action) {
    const {id, organizationId, callback} = action.payload;

    try {
        const { data , error } = yield call(apiUiScripts.deleteUiScript, id);

        if(!!error || !data?.successful){
            throw new Error(data?.message ?? error);
        }

        yield put(closeMixesSidePanel());
        yield put(actions.deleteScriptSuccess(organizationId));
        yield put(actions.getUiScripts(organizationId));
        if (!!callback) yield call(callback);
        yield put(redirectToRelativeUrl(`/playlists`));
    } catch (error) {
        yield call(toast.error, error?.message ?? "Couldn't delete playlist");
        yield put(actions.deleteScriptFailure(organizationId, error));
    }
}

export function* watchdDeleteScript() {
    yield takeEvery(actions.DELETE_SCRIPT, deleteScript);
}

export function* duplicateScript(action) {
    const {id, organizationId} = action.payload;

    try {
        const { data , error } = yield call(apiUiScripts.duplicateUiScript, id, organizationId);

        if(!!error || !data?.successful){
            throw new Error(data?.message ?? error);
        }

        const {result, entities} = UiScripts.parse(data?.script);
        yield put(actions.duplicateScriptSuccess(organizationId, result, entities));
        yield put(redirectToRelativeUrl(`/playlists/${result}`));
    } catch (error) {
        yield call(toast.error, error?.message ?? "Couldn't duplicate playlist");
        yield put(actions.duplicateScriptFailure(organizationId, error));
    }
}

export function* watchdDuplicateScript() {
    yield takeEvery(actions.DUPLICATE_SCRIPT, duplicateScript);
}

export default function* rootSaga() {
    yield all([
        watchGetUiScripts(),
        watchgetAccountsScript(),
        watchgetDevicesScript(),
        watchGetDefaultOrgScript(),
        watchdDeleteScript(),
        watchUpdateScript(),
        watchCreateScript(),
        watchdDuplicateScript(),
    ]);
};
